import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import { ContactInteractionChannelResourceUnion } from '@lgg/isomorphic/types/__generated__/graphql';
import {
  LggDropdownButtonWithoutOverlay,
  LggSelectableOptionsDropdownButtonWithCustomTrigger,
} from 'src/components/general/button/dropdown-button';
import {
  DrawerSelectableOption,
  SelectableOptionsDrawer,
} from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { FlexRow } from 'src/components/layout/flex-row';
import { ContactPhoneOption } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/shared';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';

const ResourceSelectorContainer = styled(FlexRow)`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  height: 30px;
  letter-spacing: -0.12px;
  line-height: 14px;
  padding: 0 20px;
`;

const ResourceSelectorDropdownButton = styled(LggDropdownButtonWithoutOverlay)`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  height: 22px;
  line-height: 14px;
  margin-left: 10px;
  padding: 4px 10px 4px 5px;

  .lgg-icon {
    margin-left: 5px;
  }
`;

type ResourceSelectorBarProps = {
  'data-lgg-id'?: string;
  label: string;
  options: DrawerSelectableOption[] | Record<string, DrawerSelectableOption[]>;
  selectedResource: ContactInteractionChannelResourceUnion | ContactPhoneOption;
};

export const ResourceSelectorBar = ({
  label,
  selectedResource,
  options,
  ...rest
}: ResourceSelectorBarProps) => {
  const { t } = useTranslation(['common']);
  const visibilityHandler = useVisible();
  const breakpointUpMd = useBreakpoint(up('md'));

  // Keep in mind that ContactInteractionChannelResourceUnion may change
  // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
  const selectedResourceLabel = match(selectedResource)
    .with({ __typename: 'PhoneNumber' }, (phone) => phone.national)
    .with(
      {
        __typename: P.union(
          'ContactInteractionChannelPhoneResource',
          'ContactInteractionChannelSmsResource',
        ),
      },
      (resource) => resource.phoneNumber.national,
    )
    .with(
      { __typename: 'ContactInteractionChannelGenericResource' },
      (resource) => resource.address,
    )
    .exhaustive();

  // Keep in mind that ContactInteractionChannelResourceUnion may change
  // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
  const selectedResourceValue = match(selectedResource)
    .with({ __typename: 'PhoneNumber' }, (phone) => phone.type)
    .with(
      {
        __typename: P.union(
          'ContactInteractionChannelPhoneResource',
          'ContactInteractionChannelSmsResource',
        ),
      },
      (resource) => resource.id,
    )
    .with(
      { __typename: 'ContactInteractionChannelGenericResource' },
      (resource) => resource.id,
    )
    .exhaustive();

  const dropdownButton = (
    <span data-lgg-id={rest['data-lgg-id']}>
      <ResourceSelectorDropdownButton
        onClick={visibilityHandler.show}
        isActive={visibilityHandler.visible}
        size="small"
        variant="default"
      >
        {selectedResourceLabel}
      </ResourceSelectorDropdownButton>
    </span>
  );

  return (
    <ResourceSelectorContainer>
      {`${label}:`}
      {breakpointUpMd ? (
        <LggSelectableOptionsDropdownButtonWithCustomTrigger
          options={options}
          selectedValue={selectedResourceValue}
          size="small"
          variant="default"
          visibilityHandler={visibilityHandler}
        >
          {dropdownButton}
        </LggSelectableOptionsDropdownButtonWithCustomTrigger>
      ) : (
        <>
          {dropdownButton}
          <SelectableOptionsDrawer
            visible={visibilityHandler.visible}
            title={t('common:selectNumber')}
            onClose={visibilityHandler.close}
            options={options}
            selectedValue={selectedResourceValue}
          />
        </>
      )}
    </ResourceSelectorContainer>
  );
};

import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled, { DefaultTheme, createGlobalStyle } from 'styled-components';
import { match } from 'ts-pattern';
import {
  ContactInteractionWhatsapp,
  ContactInteractionWhatsappTemplate,
  WhatsappTemplate,
  WhatsappTemplateButtonsComponentUnion,
  WhatsappTemplateComponentBody,
  WhatsappTemplateComponentFooter,
  WhatsappTemplateComponentHeader,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { LggButton } from 'src/components/general/button/lgg-button';
import { Icon } from 'src/components/general/icon';
import { TextFormatter } from 'src/components/general/text-formatter';
import {
  WhatsappTemplateParameterInput,
  WhatsappTemplateData,
  getWhatsappTemplateParamName,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/whatsapp/whatsapp-template/add-whatsapp-template-option';
import { WhatsappTemplateParameter } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/whatsapp/whatsapp-template/whatsapp-template-parameter';
import {
  BubbleDivider,
  BubbleMessageHeader,
  MessageBubbleWithDirection,
  FailedMessageIndicator,
  Content,
} from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import {
  ReactionsWrapper,
  getMessageComponentReaction,
} from 'src/components/pages/conversations/components/contact-interactions/items/whatsapp/reactions/reactions-wrapper';
import {
  resolveWhatsappComponentTextValue,
  sanitizeWhatsappMessage,
} from 'src/components/pages/conversations/components/contact-interactions/items/whatsapp/whatsapp-subitems/shared';

const TemplateHeaderText = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  display: block;
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 15px;
  margin-bottom: 10px;
  text-align: left;

  ${up('md')} {
    line-height: 17px;
    font-size: 14px;
  }
`;

const TemplateBodyText = styled(TemplateHeaderText)<{ marginBottom: number }>`
  font-family: ${({ theme }) => theme.font.regular};
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

const TemplateFooterText = styled(TemplateHeaderText)<{ $marginBottom: number }>`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  line-height: 15px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom}px;
`;

const StyledBubbleMessageHeader = styled(BubbleMessageHeader)`
  .interaction-icon {
    margin-right: 6px;
    svg {
      height: 14px;
      width: 14px;
      path {
        fill: ${({ theme }) => theme.colors.gogo};
      }
    }
  }
`;

const EditWhatsappParameterContextMenuArrowStyleOverride = createGlobalStyle<{
  theme: DefaultTheme;
}>`
.ant-dropdown.edit-whatsapp-parameter-dropdown {
    .ant-dropdown-arrow {
      width: 8px;
      border: 1px solid ${({ theme }) => theme.colors.koala};
      background: white;
      border-bottom: none;
      border-right: none;
    }
  }
`;

const TemplateComponentPreviewContainer = styled.span`
  white-space: pre-wrap;
`;

const TemplatePreviewBodyText = styled(TemplateBodyText)`
  line-height: 21px;
  font-size: 13px;

  ${up('md')} {
    font-size: 14px;
  }
`;

const StyledWhatsappTemplateButton = styled(LggButton)`
  margin-bottom: 5px;
  height: 30px;
  padding: 7px;

  &[disabled] {
    cursor: not-allowed;
  }

  &,
  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.75);
  }
`;

const WhatsAppTemplateButtonText = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 18px;
`;

const WhatsAppTemplateButtonIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const WhatsappTemplateButtonItem = ({
  icon,
  label,
  testId,
}: {
  icon: string;
  label: string;
  testId: string;
}) => {
  return (
    <StyledWhatsappTemplateButton variant="tertiary" disabled data-lgg-id={testId}>
      <WhatsAppTemplateButtonIcon lggTestId="whatsapp-template-button-icon" type={icon} />
      <WhatsAppTemplateButtonText data-lgg-id="whatsapp-template-button-label">
        {label}
      </WhatsAppTemplateButtonText>
    </StyledWhatsappTemplateButton>
  );
};

const getButtonConfiguration = (button: WhatsappTemplateButtonsComponentUnion) => {
  // Keep in mind that WhatsappTemplateButtonsComponentUnion may change
  // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
  return match(button)
    .with(
      {
        __typename: 'WhatsappTemplateButtonPhoneNumber',
      },
      ({ text }) => ({ icon: 'phone', label: text, type: 'phone-number' }),
    )
    .with(
      {
        __typename: 'WhatsappTemplateButtonQuickReply',
      },
      ({ text }) => ({ icon: 'reply', label: text, type: 'quick-reply' }),
    )
    .with(
      {
        __typename: 'WhatsappTemplateButtonUrl',
      },
      ({ text }) => ({ icon: 'externalLink', label: text, type: 'url' }),
    )
    .exhaustive();
};

const WhatsappTemplateButtons = ({
  buttons,
}: {
  buttons: WhatsappTemplateButtonsComponentUnion[];
}) => {
  const { t } = useTranslation(['conversations']);

  const buttonsConfigList: { icon: string; label: string; type: string }[] =
    useMemo(() => {
      const showViewMoreButton = buttons.length > 3;

      if (showViewMoreButton) {
        return [
          ...buttons.slice(0, 2).map(getButtonConfiguration),
          {
            icon: 'moreOptions',
            label: t(
              'conversations:contactInteractionBubble.whatsapp.templateButtons.seeAllOptions',
            ),
            type: 'more-options',
          },
        ];
      }

      return buttons.map(getButtonConfiguration);
    }, [buttons, t]);

  return (
    <>
      {buttonsConfigList.map(({ icon, label, type }, index) => {
        const testId = `whatsapp-template-button-${type}-${index}`;

        return (
          <WhatsappTemplateButtonItem
            key={testId}
            icon={icon}
            label={label}
            testId={testId}
          />
        );
      })}
    </>
  );
};

const whatsappTemplateParamRegex = /{{(?:\d+|\w+)}}/g;

const matchWhatsappParamByKey = (
  param: WhatsappTemplateParameterInput,
  targetKey: string,
) => param.key === targetKey;

type WhatsappTemplatePreviewItemProps = {
  occurredAt: string;
  template: WhatsappTemplate;
  parameters: WhatsappTemplateData;
  setParameters: ValueChanged<WhatsappTemplateData>;
};

export const WhatsappTemplatePreviewItem = memo<WhatsappTemplatePreviewItemProps>(
  ({ template, occurredAt, parameters, setParameters }) => {
    const hasFooterComponent = template.components.some(
      (component) => component.__typename === 'WhatsappTemplateComponentFooter',
    );
    const hasButtonsComponent = template.components.some(
      (component) => component.__typename === 'WhatsappTemplateComponentButtons',
    );
    const getTemplateParameterInputDefaultState = useCallback(
      (key: string) => ({
        key,
        hasError: false,
        value: undefined,
      }),
      [],
    );

    useEffect(() => {
      if (!parameters.rawBody) {
        const rawBody =
          (template.components.find(
            (template) => template.__typename === 'WhatsappTemplateComponentBody',
          ) as WhatsappTemplateComponentBody) || undefined;
        const bodyParams = [
          ...(rawBody?.text.match(whatsappTemplateParamRegex) || [])?.map((paramKey) =>
            getTemplateParameterInputDefaultState(paramKey),
          ),
        ];

        const rawHeader =
          (template.components.find(
            (template) => template.__typename === 'WhatsappTemplateComponentHeader',
          ) as WhatsappTemplateComponentHeader) || undefined;

        const headerParams = [
          ...(rawHeader?.text.match(whatsappTemplateParamRegex) || [])?.map((paramKey) =>
            getTemplateParameterInputDefaultState(paramKey),
          ),
        ];

        const rawFooter =
          (template.components.find(
            (template) => template.__typename === 'WhatsappTemplateComponentFooter',
          ) as WhatsappTemplateComponentFooter) || undefined;

        setParameters({
          ...parameters,
          rawBody: rawBody.text,
          rawHeader: rawHeader?.text,
          rawFooter: rawFooter?.text,
          body: bodyParams,
          header: headerParams,
          bodyParamsCount: bodyParams.length,
          headerParamsCount: headerParams.length,
        });
      }
    }, [
      parameters,
      setParameters,
      template.components,
      getTemplateParameterInputDefaultState,
    ]);

    const resolveComponentValue = (
      component: WhatsappTemplateComponentHeader | WhatsappTemplateComponentBody,
    ) => {
      const resolvedText = component.text;

      const getComponentKey = (key: string) => {
        // All cases handled for fixed values
        // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
        return match(component)
          .with(
            { __typename: 'WhatsappTemplateComponentHeader' },
            () => `header-param-${key}`,
          )
          .with(
            { __typename: 'WhatsappTemplateComponentBody' },
            () => `body-param-${key}`,
          )
          .exhaustive();
      };

      const textComponents = resolvedText
        .split(whatsappTemplateParamRegex)
        .map((textValue) => (
          <span key={getComponentKey(`text-${textValue}`)}>{textValue}</span>
        ));

      const getParamValue = (key: string) => {
        // All cases handled for fixed values
        // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
        return match(component)
          .with({ __typename: 'WhatsappTemplateComponentBody' }, () =>
            parameters.body.find((param) => matchWhatsappParamByKey(param, key)),
          )
          .with({ __typename: 'WhatsappTemplateComponentHeader' }, () =>
            parameters.header.find((param) => matchWhatsappParamByKey(param, key)),
          )
          .exhaustive();
      };

      const setParamValue = (paramKey: string, value: string | undefined) => {
        const getUpdatedParamValue = (value: string): WhatsappTemplateParameterInput => ({
          key: paramKey,
          hasError: false,
          value: {
            type: 'text',
            text: value,
          },
        });

        // All cases handled for fixed values
        // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
        return match(component)
          .with({ __typename: 'WhatsappTemplateComponentHeader' }, () => {
            const updatedParams = [...parameters.header];
            const paramIndex = updatedParams.findIndex((param) =>
              matchWhatsappParamByKey(param, paramKey),
            );

            if (!value) {
              updatedParams[paramIndex] = getTemplateParameterInputDefaultState(paramKey);
            } else {
              updatedParams[paramIndex] = getUpdatedParamValue(value);
            }

            setParameters({
              ...parameters,
              header: updatedParams,
            });
          })
          .with({ __typename: 'WhatsappTemplateComponentBody' }, () => {
            const updatedParams = [...parameters.body];
            const paramIndex = updatedParams.findIndex((param) =>
              matchWhatsappParamByKey(param, paramKey),
            );

            if (!value) {
              updatedParams[paramIndex] = getTemplateParameterInputDefaultState(paramKey);
            } else {
              updatedParams[paramIndex] = getUpdatedParamValue(value);
            }

            setParameters({
              ...parameters,
              body: updatedParams,
            });
          })
          .exhaustive();
      };

      const paramsComponents = [...resolvedText.matchAll(whatsappTemplateParamRegex)].map(
        ([paramKey]) => {
          const paramValue = getParamValue(paramKey);
          const componentKey = getComponentKey(getWhatsappTemplateParamName(paramKey));

          return (
            <WhatsappTemplateParameter
              testId={componentKey}
              key={componentKey}
              label={paramKey}
              value={paramValue?.value?.text}
              hasError={paramValue?.hasError}
              onChange={(value) => {
                setParamValue(paramKey, value);
              }}
            />
          );
        },
      );

      const templateComponents: JSX.Element[] = [];

      textComponents.forEach((component, index) => {
        templateComponents.push(component);
        templateComponents.push(paramsComponents[index]);
      });

      return (
        <TemplateComponentPreviewContainer>
          {templateComponents.map((component) => component)}
        </TemplateComponentPreviewContainer>
      );
    };

    return (
      <MessageBubbleWithDirection
        testId="contact-interaction-whatsapp-template"
        registrationType="AUTOMATIC"
        direction="OUTBOUND"
        deliveryStatus="DELIVERED"
        details={null}
        createdAt={occurredAt}
      >
        <>
          {template.components.map((component) => {
            // Keep in mind that WhatsappTemplateComponentUnion may change
            // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
            return match(component)
              .with({ __typename: 'WhatsappTemplateComponentHeader' }, (component) => (
                <TemplateHeaderText
                  key={component.__typename}
                  data-lgg-id="contact-interaction-whatsapp-template-component-header"
                >
                  {resolveComponentValue(component)}
                </TemplateHeaderText>
              ))
              .with({ __typename: 'WhatsappTemplateComponentBody' }, (component) => (
                <TemplatePreviewBodyText
                  key={component.__typename}
                  data-lgg-id="contact-interaction-whatsapp-template-component-body"
                  marginBottom={hasFooterComponent ? 8 : 15}
                >
                  {resolveComponentValue(component)}
                </TemplatePreviewBodyText>
              ))
              .with({ __typename: 'WhatsappTemplateComponentFooter' }, (component) => (
                <TemplateFooterText
                  $marginBottom={hasButtonsComponent ? 5 : 13}
                  key={component.__typename}
                  data-lgg-id="contact-interaction-whatsapp-template-component-footer"
                >
                  <TextFormatter>{component.text}</TextFormatter>
                </TemplateFooterText>
              ))
              .with({ __typename: 'WhatsappTemplateComponentButtons' }, (component) => (
                <WhatsappTemplateButtons
                  key={component.__typename}
                  buttons={component.buttons}
                />
              ))
              .exhaustive();
          })}
          <EditWhatsappParameterContextMenuArrowStyleOverride />
        </>
      </MessageBubbleWithDirection>
    );
  },
);

export const WhatsappTemplateItem = (props: {
  contactInteraction: ContactInteractionWhatsapp & {
    template: ContactInteractionWhatsappTemplate;
  };
  conversationId: string;
}) => {
  const { t } = useTranslation(['conversations']);
  const { contactInteraction, conversationId } = props;
  const {
    registrationType,
    id,
    description,
    occurredAt,
    source,
    campaign,
    department,
    template,
    isMasked,
  } = contactInteraction;

  const hasFooterComponent = template.components.some(
    (component) =>
      component.__typename === 'ContactInteractionWhatsappTemplateComponentFooter',
  );

  const hasButtonsComponent = template.components.some(
    (component) =>
      component.__typename === 'ContactInteractionWhatsappTemplateComponentButtons',
  );

  const messageReaction = getMessageComponentReaction(contactInteraction);

  const showFailedMessageError = template.status === 'FAILED';

  return (
    <>
      <ReactionsWrapper
        reactions={messageReaction ? [messageReaction] : messageReaction}
        direction={contactInteraction.direction}
      >
        <MessageBubbleWithDirection
          testId="contact-interaction-whatsapp-template"
          registrationType={registrationType}
          direction="OUTBOUND"
          deliveryStatus={template.status}
          details={{
            interactionId: id,
            conversationId,
            source,
            campaign,
            department,
            message: description,
          }}
          createdAt={occurredAt}
        >
          <>
            <StyledBubbleMessageHeader
              icon="whatsappTemplates"
              contactInteraction={contactInteraction}
              title={t('conversations:contactInteractionBubble.whatsapp.template')}
            />
            <BubbleDivider direction="OUTBOUND" />
            <Content isBlurred={isMasked}>
              {template.components.map((component) => {
                // Keep in mind that ContactInteractionWhatsappTemplateComponentUnion may change
                // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
                return match(component)
                  .with(
                    { __typename: 'ContactInteractionWhatsappTemplateComponentHeader' },
                    (component) => (
                      <TemplateHeaderText
                        key={component.__typename}
                        data-lgg-id="contact-interaction-whatsapp-template-component-header"
                      >
                        <TextFormatter>
                          {resolveWhatsappComponentTextValue(component)}
                        </TextFormatter>
                      </TemplateHeaderText>
                    ),
                  )
                  .with(
                    { __typename: 'ContactInteractionWhatsappTemplateComponentBody' },
                    (component) => (
                      <TemplateBodyText
                        key={component.__typename}
                        data-lgg-id="contact-interaction-whatsapp-template-component-body"
                        marginBottom={hasFooterComponent ? 5 : 15}
                      >
                        <TextFormatter>
                          {sanitizeWhatsappMessage(
                            resolveWhatsappComponentTextValue(component),
                          )}
                        </TextFormatter>
                      </TemplateBodyText>
                    ),
                  )
                  .with(
                    { __typename: 'ContactInteractionWhatsappTemplateComponentFooter' },
                    (component) => (
                      <TemplateFooterText
                        key={component.__typename}
                        data-lgg-id="contact-interaction-whatsapp-template-component-footer"
                        $marginBottom={hasButtonsComponent ? 5 : 13}
                      >
                        <TextFormatter>{component.text}</TextFormatter>
                      </TemplateFooterText>
                    ),
                  )
                  .with(
                    {
                      __typename: 'ContactInteractionWhatsappTemplateComponentButtons',
                    },
                    (component) => (
                      <WhatsappTemplateButtons
                        key={component.__typename}
                        buttons={component.buttons}
                      />
                    ),
                  )
                  .exhaustive();
              })}
            </Content>
          </>
        </MessageBubbleWithDirection>
      </ReactionsWrapper>
      {showFailedMessageError && <FailedMessageIndicator {...template} />}
    </>
  );
};

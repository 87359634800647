import React, { useCallback, useContext, useMemo } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Progress } from 'antd';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import * as zod from 'zod';
import { getDatesDifferenceInUnits } from '@lgg/isomorphic/i18n/date-utils';
import {
  Broadcast,
  BroadcastDeliveryTimeCustomDay,
  BroadcastStatus,
  MutationCreateBroadcastArgs,
  MutationUpdateBroadcastArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ButtonV2, LggButton } from 'src/components/general/button/lgg-button';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { BroadcastMessageContext } from 'src/components/pages/broadcast/components/broadcast-message-manager';
import { GeneralAndAudienceStep } from 'src/components/pages/broadcast/components/broadcast-wizard/steps/general-and-audience-step';
import { MessageComposeStep } from 'src/components/pages/broadcast/components/broadcast-wizard/steps/message-compose-step';
import { OverviewStep } from 'src/components/pages/broadcast/components/broadcast-wizard/steps/overview-step';
import {
  NONE_DELIVERY_TIME_VALUE,
  ScheduleDetailsStep,
} from 'src/components/pages/broadcast/components/broadcast-wizard/steps/schedule-details-step';
import {
  CREATE_BROADCAST_MUTATION,
  UPDATE_BROADCAST_MUTATION,
} from 'src/components/pages/broadcast/queries';
import { contactsFilterSchema } from 'src/components/pages/contacts/components/contact-filters';
import {
  useConvertFilterParamsToWhereInput,
  useConvertWhereInputToFilterParams,
} from 'src/components/pages/contacts/contact-queries';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useUrls } from 'src/hooks/use-urls';
import { useHandleUpdateBroadcastError } from '../shared';

export enum BroadcastWizardStep {
  AUDIENCE = 'AUDIENCE',
  MESSAGE_COMPOSE = 'MESSAGE_COMPOSE',
  SCHEDULE = 'SCHEDULE',
  OVERVIEW = 'OVERVIEW',
}

const broadcastWizardStepOrder: BroadcastWizardStep[] = [
  BroadcastWizardStep.AUDIENCE,
  BroadcastWizardStep.MESSAGE_COMPOSE,
  BroadcastWizardStep.SCHEDULE,
  BroadcastWizardStep.OVERVIEW,
];

const StyledProgress = styled(Progress)`
  line-height: 0;

  .ant-progress-bg {
    background: ${({ theme }) => theme.colors.monk};
  }

  .ant-progress-inner {
    background: ${({ theme }) => theme.colors.koala};
  }
`;

const StepHeaderTopContainer = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
`;

const StepTitleIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.cosmo};
    }
  }
`;

const CenteredRow = styled(FlexRow)`
  align-items: center;
`;

const StepProgressIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.colors.cosmo};
    }
  }
`;

const StepProgressText = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  line-height: 18px;
  text-align: right;
`;

const StepTitle = styled.p`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  margin: 0;
  text-align: left;
`;

const HeaderMainContainer = styled(FlexColumn)`
  margin-bottom: 24px;
  padding: 20px 20px 0;
`;

type BroadcastWizardHeaderConfiguration = {
  icon: string;
  title: string;
  progress: number;
};

type BroadcastWizardHeaderProps = {
  step: BroadcastWizardStep;
};

const BroadcastWizardHeader = ({ step }: BroadcastWizardHeaderProps) => {
  const { t } = useTranslation(['broadcast']);

  const configuration: BroadcastWizardHeaderConfiguration = useMemo(() => {
    // All cases handled for fixed values
    // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
    return match(step)
      .with(BroadcastWizardStep.AUDIENCE, () => {
        return {
          icon: 'contactSetting',
          title: t('broadcast:pages.broadcastWizard.steps.generalAndAudience.title'),
          progress: 0,
        };
      })
      .with(BroadcastWizardStep.MESSAGE_COMPOSE, () => {
        return {
          icon: 'sms',
          title: t('broadcast:pages.broadcastWizard.steps.smsCompose.title'),
          progress: 33,
        };
      })
      .with(BroadcastWizardStep.SCHEDULE, () => {
        return {
          icon: 'calendarNoPadding',
          title: t('broadcast:pages.broadcastWizard.steps.scheduleDetails.title'),
          progress: 66,
        };
      })
      .with(BroadcastWizardStep.OVERVIEW, () => {
        return {
          icon: 'broadcastSummary',
          title: t('broadcast:pages.broadcastWizard.steps.overview.title'),
          progress: 100,
        };
      })
      .exhaustive();
  }, [step, t]);

  const { icon, title, progress } = configuration;

  return (
    <HeaderMainContainer data-lgg-id="broadcast-wizard-header">
      <StepHeaderTopContainer>
        <CenteredRow>
          <StepTitleIcon type={icon} />
          <StepTitle>{title}</StepTitle>
        </CenteredRow>
        <CenteredRow>
          <StepProgressIcon type="circularCheck" />
          <StepProgressText data-lgg-id="broadcast-wizard-progress">
            {t('broadcast:pages.broadcastWizard.progress', { progress })}
          </StepProgressText>
        </CenteredRow>
      </StepHeaderTopContainer>
      <StyledProgress percent={progress} showInfo={false} strokeWidth={4} />
    </HeaderMainContainer>
  );
};

const BaseContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
`;

const WizardForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MainContainer = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-right: 30px;
  width: 100%;
`;

const StepContent = styled(FlexColumn)`
  height: 100%;
  padding: 0 20px;
`;

const StepFooter = styled(FlexRow)`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  height: 78px;
  justify-content: space-between;
  padding: 20px 30px;
`;

const SaveAsDraftIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 18px;
    width: 18px;
  }
`;

const SaveAsDraftButton = styled(LggButton)`
  width: 145px;
`;

const NextStepButton = styled(LggButton)`
  width: 62px;
`;

const BackButton = styled(LggButton)`
  margin-right: 15px;
  width: 64px;
`;

const wizardFormTimeWindowSchema = zod.object({
  start: zod.string(),
  end: zod.string(),
});

const wizardFormCustomTimeWindowSchema = zod.object({
  start: zod.string(),
  end: zod.string().nullable(),
});

const CUSTOM_DELIVERY_TIME_PRESET_VALUE = 'CUSTOM';

const deliveryTimeCustomSchema = zod
  .object({
    '0': wizardFormCustomTimeWindowSchema.nullable(),
    '1': wizardFormCustomTimeWindowSchema.nullable(),
    '2': wizardFormCustomTimeWindowSchema.nullable(),
    '3': wizardFormCustomTimeWindowSchema.nullable(),
    '4': wizardFormCustomTimeWindowSchema.nullable(),
    '5': wizardFormCustomTimeWindowSchema.nullable(),
    '6': wizardFormCustomTimeWindowSchema.nullable(),
  })
  .nullish();

const deliveryTimePresetSchema = zod
  .object({
    presetType: zod
      .union([
        zod.literal('EVERYDAY'),
        zod.literal('WEEKDAYS'),
        zod.literal('WEEKEND'),
        zod.literal(CUSTOM_DELIVERY_TIME_PRESET_VALUE),
      ])
      .nullish(),
    timeWindow: wizardFormTimeWindowSchema.nullable(),
  })
  .nullish();

const wizardFormSchema = zod
  .object({
    id: zod.string().nullish(),
    name: zod.string().nullish(),
    sender: zod.object({
      id: zod.string().nullish(),
      label: zod.string().nullish(),
    }),
    message: zod.string().nullish(),
    optOutMessage: zod.string().nullish(),
    optOutMessageLanguage: zod.union([zod.literal('EN'), zod.literal('ES')]).nullish(),
    scheduledStartAt: zod
      .object({
        date: zod.string().nullish(),
        timeInMinutes: zod.number().nullish(),
      })
      .nullish(),
    deliveryTimePreset: deliveryTimePresetSchema,
    deliveryTimeCustom: deliveryTimeCustomSchema,
    uniqueContacts: zod.number().nullish(),
    criteria: contactsFilterSchema.nullish(),
  })
  .required();

export type BroadcastWizardFormValues = zod.infer<typeof wizardFormSchema>;
export type BroadcastWizardCustom = zod.infer<typeof deliveryTimeCustomSchema>;
export type BroadcastWizardPreset = zod.infer<typeof deliveryTimePresetSchema>;

export type BroadcastWizardStepProps = {
  form: UseFormReturn<BroadcastWizardFormValues>;
};

export type BroadcastWizardProps = {
  step: BroadcastWizardStep;
  setWizardStep: ValueChanged<BroadcastWizardStep>;
  broadcast?: Broadcast;
};

export const splitTimeUnits = (time: string | null) => {
  const [hours = 0, minutes = 0, seconds = 0] = (time ?? '')
    .split(':')
    .map((value) => Number(value));

  return {
    hours,
    minutes,
    seconds,
  };
};

export const getTimeMinutesEquivalent = (time: string) => {
  const { hours, minutes } = splitTimeUnits(time);

  return hours * 60 + minutes;
};

const useBroadcastToFormValues = () => {
  const { parseISO, startOfDay } = useDateHelpers();
  const { convertWhereInputToFilterParams } = useConvertWhereInputToFilterParams();

  const broadcastToFormValues = useCallback(
    (broadcast: Broadcast): BroadcastWizardFormValues => {
      const { name, criteria } = broadcast;
      const broadcastChannelConfiguration = match(broadcast?.channelsConfiguration?.[0])
        .with(
          { __typename: 'BroadcastChannelSmsConfiguration' },
          ({ content, resource, optOutMessage }) => {
            const message = (content ?? [])
              .filter((value) => value.type === 'MESSAGE')
              .map((value) => value.message)
              .join('');

            const sender = resource;

            return {
              message,
              optOutMessage,
              sender,
            };
          },
        )
        .otherwise(() => {
          return {
            message: '',
            sender: null,
            optOutMessage: '',
          };
        });

      const deliveryTimePreset = match(broadcast.deliveryTime)
        .with({ __typename: 'BroadcastDeliveryTimePreset' }, (preset) => {
          return {
            deliveryTimePreset: {
              presetType: preset.presetType,
              timeWindow: {
                start: preset.timeWindow.startTime,
                end: preset.timeWindow.endTime,
              },
            },
            deliveryTimeCustom: null,
          };
        })
        .with({ __typename: 'BroadcastDeliveryTimeCustom' }, (customPreset) => {
          const defaultValue = {
            start: NONE_DELIVERY_TIME_VALUE,
            end: null,
          };
          const customDeliveryValues: BroadcastWizardCustom = {
            '0': defaultValue,
            '1': defaultValue,
            '2': defaultValue,
            '3': defaultValue,
            '4': defaultValue,
            '5': defaultValue,
            '6': defaultValue,
          };

          customPreset.daysOfWeek.forEach(({ dayOfWeek, timeWindow }) => {
            const isValidKey = dayOfWeek >= 0 && dayOfWeek <= 6;

            if (isValidKey) {
              customDeliveryValues[dayOfWeek] = {
                start: timeWindow.startTime as string,
                end: timeWindow.endTime as string,
              };
            }
          });

          const preset: BroadcastWizardPreset = {
            presetType: 'CUSTOM',
            timeWindow: null,
          };

          return {
            deliveryTimePreset: preset,
            deliveryTimeCustom: customDeliveryValues,
          };
        })
        .otherwise(() => ({ deliveryTimePreset: null, deliveryTimeCustom: null }));

      return {
        id: broadcast.id,
        name: name ?? '',
        sender: {
          id: broadcastChannelConfiguration.sender?.id ?? null,
          label: broadcastChannelConfiguration.sender?.phoneNumber?.national ?? null,
        },
        message: broadcastChannelConfiguration.message,
        optOutMessage: broadcastChannelConfiguration.optOutMessage ?? null,
        optOutMessageLanguage: 'EN' as const,
        scheduledStartAt: broadcast.scheduledAt
          ? {
              date: broadcast.scheduledAt,
              timeInMinutes: getDatesDifferenceInUnits(
                startOfDay(parseISO(broadcast.scheduledAt)),
                parseISO(broadcast.scheduledAt),
              ).minutes,
            }
          : null,
        uniqueContacts: broadcast.audience ?? null,
        criteria: convertWhereInputToFilterParams(criteria) ?? null,
        ...deliveryTimePreset,
      };
    },
    [convertWhereInputToFilterParams, parseISO, startOfDay],
  );

  return {
    broadcastToFormValues,
  };
};

export const BroadcastWizard = ({
  step,
  setWizardStep,
  broadcast,
}: BroadcastWizardProps) => {
  const { t } = useTranslation(['common', 'broadcast']);
  const { set, parseISO, startOfDay } = useDateHelpers();
  const showNotification = useShowNotification();
  const { id: institutionId } = useCurrentInstitution();
  const { getBroadcastPageUrl } = useUrls();
  const history = useHistory();
  const { showBroadcastMessage } = useContext(BroadcastMessageContext);
  const { broadcastToFormValues } = useBroadcastToFormValues();
  const convertFormValuesToWhereInput = useConvertFilterParamsToWhereInput();
  const handleUpdateBroadcastError = useHandleUpdateBroadcastError();

  const [createBroadcast, { loading: creatingBroadcast }] = useMutation<
    Broadcast,
    MutationCreateBroadcastArgs
  >(CREATE_BROADCAST_MUTATION);

  const [updateBroadcast, { loading: updatingBroadcast }] = useMutation<
    Broadcast,
    MutationUpdateBroadcastArgs
  >(UPDATE_BROADCAST_MUTATION);

  const formInitialValues = useMemo(() => {
    return broadcast
      ? broadcastToFormValues(broadcast)
      : {
          id: null,
          name: null,
          sender: { id: null, label: null },
          message: null,
          optOutMessage: null,
          optOutMessageLanguage: 'EN' as const,
          scheduledStartAt: null,
          deliveryTimePreset: null,
          deliveryTimeCustom: null,
          uniqueContacts: null,
          criteria: null,
        };
  }, [broadcast, broadcastToFormValues]);

  const wizardForm = useForm<BroadcastWizardFormValues>({
    defaultValues: {
      id: null,
      name: '',
      sender: {
        id: null,
        label: null,
      },
      deliveryTimeCustom: null,
      scheduledStartAt: null,
    },
    values: formInitialValues,
    shouldFocusError: true,
  });

  const wizardStepContent = useMemo(() => {
    // All cases handled; no fallback
    // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
    return match(step)
      .with(BroadcastWizardStep.AUDIENCE, () => (
        <GeneralAndAudienceStep form={wizardForm} />
      ))
      .with(BroadcastWizardStep.MESSAGE_COMPOSE, () => (
        <MessageComposeStep form={wizardForm} />
      ))
      .with(BroadcastWizardStep.SCHEDULE, () => <ScheduleDetailsStep form={wizardForm} />)
      .with(BroadcastWizardStep.OVERVIEW, () => <OverviewStep form={wizardForm} />)
      .exhaustive();
  }, [step, wizardForm]);

  const showBackButton = useMemo(() => step !== BroadcastWizardStep.AUDIENCE, [step]);

  const showSendButton = useMemo(() => step === BroadcastWizardStep.OVERVIEW, [step]);

  const getCreateOrUpdateBroadcastPayloadWithStatus = useCallback(
    (status: BroadcastStatus) => {
      const {
        name,
        scheduledStartAt,
        optOutMessage,
        sender,
        deliveryTimePreset,
        deliveryTimeCustom,
        criteria,
        message,
      } = wizardForm.getValues();
      const presetType = deliveryTimePreset?.presetType;
      const scheduledStartAtDate = scheduledStartAt?.date
        ? set(startOfDay(parseISO(scheduledStartAt?.date)), {
            minutes: Number(scheduledStartAt.timeInMinutes ?? 0),
          })
        : undefined;

      const deliveryTime = match({
        presetType,
        deliveryTimeCustom,
        deliveryTimePreset,
      })
        .with(
          {
            presetType: CUSTOM_DELIVERY_TIME_PRESET_VALUE,
            deliveryTimeCustom: P.not(P.nullish),
          },
          ({ deliveryTimeCustom }) => {
            const entries = Object.entries(deliveryTimeCustom ?? {}).map(
              ([key, value]) => {
                return value && value.start !== NONE_DELIVERY_TIME_VALUE
                  ? {
                      dayOfWeek: Number(key),
                      timeWindow: {
                        endTime: value.end,
                        startTime: value.start,
                      },
                    }
                  : null;
              },
            );

            return {
              deliveryTimeCustom: {
                daysOfWeek: entries.filter(
                  (value) => value !== null,
                ) as BroadcastDeliveryTimeCustomDay[],
              },
            };
          },
        )
        .with(
          {
            presetType: P.union('WEEKDAYS', 'WEEKEND', 'EVERYDAY'),
            deliveryTimePreset: {
              timeWindow: P.not(P.nullish),
            },
          },
          ({ deliveryTimePreset, presetType }) => {
            return {
              deliveryTimePreset: {
                presetType: presetType,
                timeWindow: {
                  startTime: deliveryTimePreset.timeWindow.start,
                  endTime: deliveryTimePreset.timeWindow.end,
                },
              },
            };
          },
        )
        .otherwise(() => ({}));

      return {
        input: {
          companyId: institutionId,
          name,
          status,
          scheduledAt: scheduledStartAtDate,
          criteria: criteria
            ? cloneDeep(convertFormValuesToWhereInput(criteria, true))
            : null,
          channelsConfigurationSms: sender?.id
            ? {
                optOutMessage: optOutMessage,
                resourceId: sender.id.toString(),
                messageContent: message,
              }
            : null,
          ...deliveryTime,
        },
      };
    },
    [convertFormValuesToWhereInput, institutionId, parseISO, set, startOfDay, wizardForm],
  );

  const handleBack = useCallback(() => {
    const currentStepIndex = broadcastWizardStepOrder.indexOf(step);

    if (currentStepIndex > 0) {
      setWizardStep(broadcastWizardStepOrder[currentStepIndex - 1]);
    }
  }, [setWizardStep, step]);

  const handleNext = useCallback(async () => {
    const currentStepIndex = broadcastWizardStepOrder.indexOf(step);

    if (currentStepIndex < broadcastWizardStepOrder.length - 1) {
      setWizardStep(broadcastWizardStepOrder[currentStepIndex + 1]);
    } else {
      const { scheduledStartAt, id: broadcastId } = wizardForm.getValues();
      const baseInput = getCreateOrUpdateBroadcastPayloadWithStatus('SCHEDULED');

      const onCompletedHandler = () => {
        showBroadcastMessage(!scheduledStartAt ? 'CREATED' : 'SCHEDULED');
        history.push(getBroadcastPageUrl());
      };

      if (broadcastId) {
        await updateBroadcast({
          variables: {
            input: {
              ...baseInput.input,
              id: broadcastId,
            },
          },
          onCompleted: onCompletedHandler,
          onError: handleUpdateBroadcastError,
        });
      } else {
        await createBroadcast({
          variables: {
            ...baseInput,
          },
          onCompleted: onCompletedHandler,
        });
      }
    }
  }, [
    step,
    setWizardStep,
    wizardForm,
    getCreateOrUpdateBroadcastPayloadWithStatus,
    showBroadcastMessage,
    history,
    getBroadcastPageUrl,
    updateBroadcast,
    createBroadcast,
    handleUpdateBroadcastError,
  ]);

  return (
    <MainContainer>
      <BroadcastWizardHeader step={step} />
      <FormProvider {...wizardForm}>
        <WizardForm
          onSubmit={wizardForm.handleSubmit(
            async () => {
              await handleNext();
            },
            () => {
              showNotification({
                type: 'error',
                title: t(
                  'broadcast:pages.broadcastWizard.errorMessages.invalidFormValues.title',
                ),
                message: t(
                  'broadcast:pages.broadcastWizard.errorMessages.invalidFormValues.message',
                ),
              });
            },
          )}
        >
          <StepContent>{wizardStepContent}</StepContent>
          <StepFooter>
            <SaveAsDraftButton
              variant="tertiary"
              data-lgg-id="broadcast-wizard-save-as-draft-button"
              type="button"
              loading={creatingBroadcast || updatingBroadcast}
              onClick={async () => {
                await wizardForm.trigger();

                if (!Object.keys(wizardForm.formState.errors).length) {
                  await createBroadcast({
                    variables: getCreateOrUpdateBroadcastPayloadWithStatus('DRAFT'),
                    onCompleted: () => {
                      history.push(getBroadcastPageUrl());
                    },
                  });
                }
              }}
            >
              <SaveAsDraftIcon type="draft" />
              {/* TODO: If is editing should say SAVE */}
              {t('common:saveAsDraft')}
            </SaveAsDraftButton>
            <FlexRow>
              {showBackButton && (
                <BackButton
                  variant="tertiary"
                  data-lgg-id="broadcast-wizard-back-button"
                  type="button"
                  onClick={handleBack}
                >
                  {t('common:back')}
                </BackButton>
              )}
              {showSendButton ? (
                <ButtonV2
                  variant="cta"
                  size="regular"
                  type="submit"
                  data-lgg-id="broadcast-wizard-send-button"
                  disabled={creatingBroadcast}
                >
                  {t(
                    wizardForm.getValues().scheduledStartAt !== null
                      ? 'broadcast:pages.broadcastWizard.scheduleBroadcast'
                      : 'broadcast:pages.broadcastWizard.sendBroadcast',
                  )}
                </ButtonV2>
              ) : (
                <NextStepButton
                  variant="primary"
                  type="submit"
                  data-lgg-id="broadcast-wizard-next-button"
                >
                  {t('common:next')}
                </NextStepButton>
              )}
            </FlexRow>
          </StepFooter>
        </WizardForm>
      </FormProvider>
    </MainContainer>
  );
};

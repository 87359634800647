import styled from 'styled-components';
import { match } from 'ts-pattern';
import { Schedule } from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  ScheduleEventDetails,
  TaskEventDetails,
} from 'src/components/pages/calendar/components/shared/event-details';
import { useEventDateFormatter } from 'src/components/pages/calendar/components/shared/hooks';
import { TaskEvent } from 'src/components/pages/calendar/components/shared/shared';

const EventPopoverContainer = styled(FlexColumn)`
  width: 340px;
`;

const EventPopoverHeaderContent = styled(FlexRow)`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  justify-content: space-between;
  padding: 13px 20px 12px 15px;
`;

const EventPopoverBodyContent = styled(FlexColumn)`
  padding: 10px 5px;
`;

const EventPopoverTitle = styled.span`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 1px;
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EventPopoverSubtitle = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  text-transform: capitalize;
  font-size: 12px;
  line-height: 14px;
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EventPopoverHeaderIcon = styled(Icon)`
  & + & {
    margin-left: 21px;
  }

  svg {
    cursor: pointer;
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

type EventPopoverHeaderProps = {
  title: string;
  subtitle: string;
  onEdit: VoidFunction;
  onDelete: VoidFunction;
  onClose: VoidFunction;
};

const EventPopoverHeader = ({
  title,
  subtitle,
  onEdit,
  onDelete,
  onClose,
}: EventPopoverHeaderProps) => {
  return (
    <EventPopoverHeaderContent>
      <FlexColumn>
        <EventPopoverTitle data-lgg-id="event-popover-title">{title}</EventPopoverTitle>
        <EventPopoverSubtitle data-lgg-id="event-popover-subtitle">
          {subtitle}
        </EventPopoverSubtitle>
      </FlexColumn>
      <FlexRow>
        <EventPopoverHeaderIcon
          type="edit"
          lggTestId="event-popover-edit-icon"
          onClick={onEdit}
        />
        <EventPopoverHeaderIcon
          type="deleteBin"
          lggTestId="event-popover-delete-icon"
          onClick={onDelete}
        />
        <EventPopoverHeaderIcon
          type="close"
          lggTestId="event-popover-close-icon"
          onClick={onClose}
        />
      </FlexRow>
    </EventPopoverHeaderContent>
  );
};

type EventPopoverContentProps = {
  event: TaskEvent | Schedule;
  onEdit: VoidFunction;
  onDelete: VoidFunction;
  onClose: VoidFunction;
};

export const EventPopoverContent = ({
  event,
  onEdit,
  onDelete,
  onClose,
}: EventPopoverContentProps) => {
  const eventDateFormatter = useEventDateFormatter();

  // All cases handled for fixed values
  // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
  return match(event)
    .with({ __typename: 'Task' }, (task) => {
      return (
        <EventPopoverContainer data-lgg-id={`event-popover-task-${task.id}`}>
          <EventPopoverHeader
            title={task.title}
            subtitle={eventDateFormatter.task(task)}
            onEdit={onEdit}
            onDelete={onDelete}
            onClose={onClose}
          />
          <EventPopoverBodyContent>
            <TaskEventDetails task={task} />
          </EventPopoverBodyContent>
        </EventPopoverContainer>
      );
    })
    .with({ __typename: 'Schedule' }, (schedule) => {
      return (
        <EventPopoverContainer data-lgg-id={`event-popover-appointment-${schedule.id}`}>
          <EventPopoverHeader
            title={schedule.title}
            subtitle={eventDateFormatter.appointment(schedule)}
            onEdit={onEdit}
            onDelete={onDelete}
            onClose={onClose}
          />
          <EventPopoverBodyContent>
            <ScheduleEventDetails schedule={schedule} />
          </EventPopoverBodyContent>
        </EventPopoverContainer>
      );
    })
    .exhaustive();
};

import React, { memo, useMemo } from 'react';
import { Controller, FormProvider, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AntDrawer from 'antd/lib/drawer';
import { isNumber } from 'lodash';
import { down, up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { Dayjs } from '@lgg/isomorphic/dayjs';
import { ButtonV2, StyledButtonV2 } from 'src/components/general/button/lgg-button';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { useBottomDrawerHeight } from 'src/components/general/drawer/bottom/bottom-drawer';
import { useFilterHelpers } from 'src/components/general/filter/use-filter-helpers';
import { Icon } from 'src/components/general/icon';
import { DatePicker } from 'src/components/general/inputs/date-picker';
import { Center } from 'src/components/layout/center';
import { Expand } from 'src/components/layout/expand';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { SafeAreaBottom } from 'src/components/providers/safe-area-insets-provider';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useDateHelpers } from 'src/hooks/use-date-helpers';

const sharedSectionStyles = css`
  ${up('md')} {
    background-color: ${({ theme }) => theme.colors.white};
    margin-left: auto;
    width: 330px;
  }
`;

const StyledHeader = styled.div`
  ${sharedSectionStyles}
  background-color: ${({ theme }) => theme.colors.porcelain};
  min-height: 70px;
  padding: 20px;
  width: 100%;

  ${up('md')} {
    align-items: unset;
    background-color: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
    min-height: unset;
    padding-top: 30px;
  }
`;

const HeaderRow = styled(FlexRow)`
  align-items: center;
  height: 100%;
  width: 100%;

  ${up('md')} {
    height: unset;
  }
`;

const FilterIcon = styled(Icon)`
  margin-right: 10px;

  path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const CloseIcon = styled(Icon)`
  path {
    fill: ${({ theme }) => theme.colors.flint};
  }

  ${up('md')} {
    align-self: flex-end;
    margin-bottom: 30px;
  }
`;

const ActiveFiltersBadge = styled(Center)`
  background-color: ${({ theme }) => theme.colors.monk};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 11px;
  height: 20px;
  line-height: 13px;
  margin-left: 10px;
  width: 20px;

  ${up('md')} {
    margin-left: 8px;
  }
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 20px;
  letter-spacing: -0.4px;
  line-height: 24px;

  ${up('md')} {
    font-size: 24px;
    line-height: 29px;
  }
`;

const SectionContainer = styled.div`
  ${sharedSectionStyles};
  height: 100%;
`;

const ChildrenWrapper = styled.div`
  ${up('md')} {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 20px;
  }
`;

type HeaderProps = {
  onReset: VoidFunction;
  filtersCount: number;
  onClose: VoidFunction;
};

const Header = memo<HeaderProps>(({ onReset, onClose, filtersCount }) => {
  const { t } = useTranslation(['common']);
  const breakpointUpMd = useBreakpoint(up('md'));
  const closeIcon = useMemo(
    () => <CloseIcon type="close" onClick={onClose} lggTestId="close-filters-icon" />,
    [onClose],
  );

  const headerContent = useMemo(() => {
    const hasActiveFilters = Boolean(filtersCount);

    return (
      <>
        <FilterIcon type="filters" />
        <Title>{t('common:filters.title')}</Title>
        {hasActiveFilters && <ActiveFiltersBadge>{filtersCount}</ActiveFiltersBadge>}
        <Expand />
      </>
    );
  }, [filtersCount, t]);

  return (
    <StyledHeader>
      {breakpointUpMd ? (
        <FlexColumn>
          {closeIcon}
          <HeaderRow>
            {headerContent}
            <ResetButton onClick={onReset} />
          </HeaderRow>
        </FlexColumn>
      ) : (
        <HeaderRow>
          {headerContent}
          {closeIcon}
        </HeaderRow>
      )}
    </StyledHeader>
  );
});

const StyledFooter = styled(FlexRow)`
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  padding: 15px 20px;
  position: relative;

  &::before {
    background: transparent
      linear-gradient(180deg, #ffffff00 0%, ${({ theme }) => theme.colors.white} 100%) 0 0
      no-repeat padding-box;
    content: '';
    display: block;
    height: 65px;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: -66px;
    width: 100%;
  }

  ${StyledButtonV2} {
    flex: 1;
  }

  ${StyledButtonV2} + ${StyledButtonV2} {
    margin-left: 20px;
  }

  ${sharedSectionStyles}

  ${up('md')} {
    padding: 20px;
  }
`;

type ResetButtonProps = {
  onClick: VoidFunction;
};

const ResetButton = memo<ResetButtonProps>(({ onClick }) => {
  const { t } = useTranslation(['common']);

  return (
    <ButtonV2
      size="regular"
      variant="default"
      onClick={onClick}
      data-lgg-id="reset-filters-button"
    >
      {t('common:filters.actionReset')}
    </ButtonV2>
  );
});

type FooterProps = {
  onSave: VoidFunction;
  onReset: VoidFunction;
};

const Footer = memo<FooterProps>(({ onReset, onSave }) => {
  const { t } = useTranslation(['common']);
  const breakpointDownSm = useBreakpoint(down('sm'));

  return (
    <StyledFooter>
      {breakpointDownSm && <ResetButton onClick={onReset} />}
      <ButtonV2
        size="regular"
        variant="primary"
        onClick={onSave}
        data-lgg-id="apply-filters-button"
      >
        {t(
          breakpointDownSm
            ? 'common:filters.actionsApply'
            : 'common:filters.actionsApplyFilters',
        )}
      </ButtonV2>
    </StyledFooter>
  );
});

const StyledScrollbar = styled(Scrollbar)`
  background-color: transparent;
  overflow: scroll;
  padding: 20px;

  ${up('md')} {
    padding: 0;
  }
`;

const StyledDrawer = styled(AntDrawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  &&& .ant-drawer-content {
    border-radius: 8px 8px 0 0;
  }

  &&& .ant-drawer-mask {
    background-color: #727d8eb3;
    display: block;
  }

  ${up('md')} {
    &&& .ant-drawer-mask {
      background: transparent;
      box-shadow: 0 14px 51px 0 rgba(0, 0, 0, 0.14);
      left: unset;
      opacity: 0;
      right: -330px;
      transition: opacity 0.1s;
      width: 330px;
    }

    &&&.ant-drawer-open .ant-drawer-mask {
      opacity: 1;
      right: 0;
    }

    &&& .ant-drawer-content {
      border-radius: 0;
    }

    &.ant-drawer-open .ant-drawer-content-wrapper {
      box-shadow: none;
    }

    .ant-drawer-content {
      background-color: transparent;
    }
  }
`;

export const getFiltersCount = (filters: { [key: string]: any } | undefined) => {
  return filters
    ? Object.values(filters).filter((filter) => {
        if (filter) {
          if (isNumber(filter)) {
            return true;
          }

          if (Array.isArray(filter)) {
            return Boolean(filter.length);
          }

          if (Object.keys(filter)) {
            return Boolean(Object.values(filter).filter(Boolean).length);
          }

          return false;
        }

        return false;
      }).length
    : 0;
};

export const ReactFilters = memo<{
  onSave: VoidFunction;
  onReset: VoidFunction;
  form: UseFormReturn;
  filters: { [key: string]: any } | undefined;
  visible: boolean;
  onClose: VoidFunction;
  children: React.ReactNode;
  testId?: string;
}>(({ filters, onSave, onReset, children, visible, form, onClose, testId = '' }) => {
  const height = useBottomDrawerHeight({ fullHeight: true });
  const breakpointUpMd = useBreakpoint(up('md'));
  const filtersCount = getFiltersCount(filters);

  const handleSubmitKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code !== 'Enter') {
      return;
    }

    const target = event.target as HTMLElement;
    const classNames = target.classList;
    const isTextField = () => classNames.contains('lgg-text-input');
    const isClosedMultiSelect = () => {
      return (
        classNames.contains('lgg-select__input') &&
        target.getAttribute('aria-expanded') === 'false'
      );
    };

    const isClosedSingleSelect = () => {
      return (
        !classNames.contains('lgg-select__input') &&
        target.getAttribute('role') === 'combobox' &&
        target.getAttribute('aria-expanded') === 'false'
      );
    };

    const isClosedDatePicker = () => {
      const possibleDatePicker = target.parentElement?.parentElement;

      return (
        possibleDatePicker?.classList.contains('lgg-date-picker') &&
        Boolean(possibleDatePicker?.querySelector('.ant-picker-dropdown-hidden'))
      );
    };

    if (
      isTextField() ||
      isClosedMultiSelect() ||
      isClosedSingleSelect() ||
      isClosedDatePicker()
    ) {
      onSave();
    }
  };

  return (
    <StyledDrawer
      className="filters-drawer"
      placement={breakpointUpMd ? 'right' : 'bottom'}
      width={430}
      closable={false}
      visible={visible}
      height={breakpointUpMd ? '100%' : height}
      onClose={onClose}
      data-lgg-id={testId}
      maskClosable={false}
      push={false}
    >
      <FormProvider {...form}>
        <Header filtersCount={filtersCount} onReset={onReset} onClose={onClose} />
        <StyledScrollbar>
          <SectionContainer onKeyDown={handleSubmitKey}>
            <ChildrenWrapper>{children}</ChildrenWrapper>
          </SectionContainer>
        </StyledScrollbar>
        <Footer onSave={onSave} onReset={onReset} />
        <SafeAreaBottom />
      </FormProvider>
    </StyledDrawer>
  );
});

type DateFilterProps = {
  name: string;
  'data-lgg-id': string;
  label: string;
  initialValue?: Date;
  required?: boolean;
  format?: string;
  placeholder?: string;
  disabledDate?: (date: Dayjs) => boolean;
  updateOnPageChange?: boolean;
};

export const StartDateFilter = memo<DateFilterProps>((props) => {
  const { control, setValue } = useFormContext();
  const { name, label, placeholder, disabledDate, updateOnPageChange } = props;
  const { decodeFilterDate, encodeStartFilterDate } = useFilterHelpers();
  const { startOfToday } = useDateHelpers();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <DatePicker
          data-lgg-id={props['data-lgg-id']}
          value={field.value ? decodeFilterDate(field.value) : undefined}
          format="YYYY-MM-DD hh:mm A"
          baseValue={startOfToday()}
          label={label}
          disabledDate={disabledDate}
          hasError={Boolean(fieldState.error)}
          placeHolder={placeholder}
          onChange={(date) => {
            setValue(name, encodeStartFilterDate(date));
          }}
          updateOnPageChange={updateOnPageChange}
        />
      )}
    />
  );
});

export const EndDateFilter = memo<DateFilterProps>((props) => {
  const { control, setValue } = useFormContext();
  const { name, label, initialValue, placeholder, updateOnPageChange } = props;
  const { decodeFilterDate, encodeFilterEndDate } = useFilterHelpers();
  const { endOfToday } = useDateHelpers();
  const computeValue = (value?: string, initialValue?: Date) => {
    if (value) {
      return decodeFilterDate(value);
    }

    if (initialValue) {
      return initialValue;
    }

    return undefined;
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <DatePicker
          data-lgg-id={props['data-lgg-id']}
          value={computeValue(field.value, initialValue)}
          format="YYYY-MM-DD [11:59 PM]"
          baseValue={endOfToday()}
          label={label}
          hasError={Boolean(fieldState.error)}
          placeHolder={placeholder}
          updateOnPageChange={updateOnPageChange}
          onChange={(date) => {
            setValue(name, encodeFilterEndDate(date));
          }}
        />
      )}
    />
  );
});

export const DateFilter = memo<DateFilterProps>((props) => {
  const { control, setValue } = useFormContext();
  const {
    name,
    label,
    initialValue,
    required,
    format,
    placeholder,
    updateOnPageChange,
    ...rest
  } = props;

  const computeValue = (value?: string, initialValue?: Date) => {
    if (value) {
      return new Date(value);
    }

    if (initialValue) {
      return initialValue;
    }

    return undefined;
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required,
      }}
      render={({ field, fieldState }) => (
        <DatePicker
          value={computeValue(field.value, initialValue)}
          label={label}
          format={format}
          placeHolder={placeholder}
          hasError={Boolean(fieldState.error)}
          updateOnPageChange={updateOnPageChange}
          onChange={(date) => {
            setValue(name, date.toISOString());
          }}
          {...rest}
        />
      )}
    />
  );
});

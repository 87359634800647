import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { Icon } from 'src/components/general/icon';
import { Center } from 'src/components/layout/center';
import { FlexColumn } from 'src/components/layout/flex-column';

const EmptyListContainer = styled(FlexColumn)`
  align-items: center;
  margin-top: 60px;
`;

const EmptyListIcon = styled.img`
  height: 104px;
  margin-bottom: 30px;
  width: 104px;

  ${up('md')} {
    margin-bottom: 20px;
  }
`;

export const EmptyListTitle = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.36px;
  line-height: 22px;
  margin: 0 0 10px;
  text-align: center;

  ${up('md')} {
    font-size: 26px;
    letter-spacing: -0.52px;
    line-height: 32px;
  }
`;

const IconContainer = styled(Center)`
  background-color: ${({ theme }) => theme.colors.porcelain};
  border-radius: 50%;
  height: 90px;
  margin-bottom: 20px;
  width: 90px;

  svg {
    height: 30px;
    width: 30px;

    path {
      fill: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

export const EmptyListMessage = styled.p`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 20px;
  margin: 0;
  max-width: 263px;
  text-align: center;

  ${up('md')} {
    line-height: 17px;
    max-width: unset;
  }
`;

type EmptyListPlaceholderProps = {
  title: string;
  message: string;
  iconConfig:
    | {
        type: 'inline';
        icon: string;
      }
    | {
        type: 'embedded';
        icon: string;
      };
};

export const EmptyListPlaceholder = memo<EmptyListPlaceholderProps>(
  ({ title, message, iconConfig, ...rest }) => {
    return (
      <EmptyListContainer {...rest}>
        {
          // All cases handled for fixed values
          // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
          match(iconConfig)
            .with({ type: 'inline' }, ({ icon }) => <EmptyListIcon src={icon} />)
            .with({ type: 'embedded' }, ({ icon }) => (
              <IconContainer>
                <Icon type={icon} />
              </IconContainer>
            ))
            .exhaustive()
        }
        <EmptyListTitle data-lgg-id="empty-list-title">{title}</EmptyListTitle>
        <EmptyListMessage data-lgg-id="empty-list-message">{message}</EmptyListMessage>
      </EmptyListContainer>
    );
  },
);

import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';

export const ModalContent = styled(FlexColumn)`
  align-items: center;
  padding-top: 30px;
`;

export const ModalTitle = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.44px;
  line-height: 27px;
  margin: 20px 0 10px;
  text-align: left;
`;

export const ModalMessage = styled.span`
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 19px;
  padding: 0 40px 20px;
  text-align: center;
`;

export const ModalCloseIcon = styled(Icon)`
  cursor: pointer;

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CompanyUsersSelect } from 'src/components/domain/users/components/company-users-select';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { BroadcastSummary } from 'src/components/pages/broadcast/components/broadcast-summary/broadcast-summary';
import { BroadcastWizardStepProps } from 'src/components/pages/broadcast/components/broadcast-wizard/broadcast-wizard';
import {
  WizardStepSectionDivider,
  WizardStepSectionTitle,
} from 'src/components/pages/broadcast/components/broadcast-wizard/shared';
import { useDateHelpers } from 'src/hooks/use-date-helpers';

const OverviewMessage = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
  text-align: left;
`;

const TestYourBroadcastContainer = styled(FlexColumn)`
  margin-top: 24px;
`;

const SummaryContainer = styled(FlexColumn)`
  margin-bottom: 25px;
  max-width: 544px;
`;

const UserSelectContainer = styled.div`
  margin-right: 10px;
  max-width: 300px;
  width: 100%;
`;

const TestBroadcastFormContainer = styled(FlexRow)`
  align-items: flex-end;
  margin-top: 16px;
`;

export const OverviewStep = ({ form }: BroadcastWizardStepProps) => {
  const { t } = useTranslation(['broadcast', 'common']);
  const [testUserId, setTestUserId] = useState<number | undefined>(undefined);
  const { parseISO, set, startOfDay } = useDateHelpers();
  const { scheduledStartAt, uniqueContacts, sender } = form.getValues();

  const scheduledStartAtDate: Date | null = useMemo(() => {
    if (scheduledStartAt?.date) {
      const computedDate = set(startOfDay(parseISO(scheduledStartAt.date)), {
        minutes: Number(scheduledStartAt.timeInMinutes ?? 0),
      });

      return computedDate;
    }

    return null;
  }, [
    parseISO,
    startOfDay,
    scheduledStartAt?.date,
    scheduledStartAt?.timeInMinutes,
    set,
  ]);

  return (
    <FlexColumn data-lgg-id="broadcast-wizard-step-overview">
      <OverviewMessage>
        {t('broadcast:pages.broadcastWizard.steps.overview.summary.title')}
      </OverviewMessage>
      <SummaryContainer>
        <BroadcastSummary
          uniqueContacts={uniqueContacts}
          sender={sender.label}
          messages={null}
          date={scheduledStartAtDate}
          status="DRAFT"
          variant="BROADCAST-WIZARD"
        />
      </SummaryContainer>
      <WizardStepSectionDivider />
      <TestYourBroadcastContainer>
        <WizardStepSectionTitle>
          {t('broadcast:pages.broadcastWizard.steps.overview.testBroadcast.title')}
        </WizardStepSectionTitle>
        <TestBroadcastFormContainer>
          <UserSelectContainer>
            <CompanyUsersSelect
              selectedUsersIds={testUserId ? [testUserId] : []}
              isMulti={false}
              onChange={(option) => {
                setTestUserId(option?.value);
              }}
              isClearable
              forceCaret
              name="recipient"
              label={t(
                'broadcast:pages.broadcastWizard.steps.overview.testBroadcast.form.selectRecipient',
              )}
            />
          </UserSelectContainer>
          <ButtonV2 variant="ctaGhost" size="regular">
            {t(
              'broadcast:pages.broadcastWizard.steps.overview.testBroadcast.form.sendMessage',
            )}
          </ButtonV2>
        </TestBroadcastFormContainer>
      </TestYourBroadcastContainer>
    </FlexColumn>
  );
};

import React, { memo, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import * as zod from 'zod';
import { BroadcastStatus } from '@lgg/isomorphic/types/__generated__/graphql';
import { useSetFormFieldValue } from 'src/components/filters/helpers';
import { ReactFilters } from 'src/components/filters/react-filters';
import { Select, SelectOption } from 'src/components/general/inputs/select/select';
import { TextInput } from 'src/components/general/inputs/text-input';
import {
  StyledTableFiltersForm,
  TableLayoutFilterProps,
  useSimpleTableSubmitHandler,
} from 'src/components/general/table-helpers';

export const broadcastFilterSchema = zod.object({
  keywords: zod.string().nullish(),
  status: zod
    .union([
      zod.literal('CANCELED'),
      zod.literal('DRAFT'),
      zod.literal('IN_PROGRESS'),
      zod.literal('SCHEDULED'),
      zod.literal('SENT'),
    ])
    .nullish(),
  archived: zod.string().nullish(),
});

type BroadcastStatusFilterValue =
  | Exclude<BroadcastStatus, 'IN_PROGRESS_PREPARING' | 'IN_PROGRESS_SENDING'>
  | 'IN_PROGRESS';

export type BroadcastFiltersFormValues = zod.infer<typeof broadcastFilterSchema>;

export const defaultBroadcastFilters: BroadcastFiltersFormValues = {
  keywords: null,
  status: null,
  archived: null,
};

export const BroadcastFilters = memo<TableLayoutFilterProps<BroadcastFiltersFormValues>>(
  ({ visible, filters, onClose, onReset }) => {
    const { t } = useTranslation(['common', 'broadcast']);
    const submitHandler = useSimpleTableSubmitHandler<BroadcastFiltersFormValues>({
      onClose,
    });
    const history = useHistory();
    const location = useLocation();

    const form = useForm<BroadcastFiltersFormValues>({
      resolver: zodResolver(broadcastFilterSchema),
      values: filters,
      defaultValues: defaultBroadcastFilters,
    });

    const { control, handleSubmit, setValue, reset } = form;

    const { setFormFieldValue } = useSetFormFieldValue<BroadcastFiltersFormValues>({
      defaultValues: defaultBroadcastFilters,
      setValue,
    });

    useEffect(() => {
      if (filters) {
        reset(filters);
      }
    }, [filters, reset]);

    const broadcastStatusOptions: SelectOption<BroadcastStatusFilterValue>[] = useMemo(
      () => [
        {
          label: t('broadcast:pages.broadcast.filters.fields.status.options.cancelled'),
          value: 'CANCELED',
        },
        {
          label: t('broadcast:pages.broadcast.filters.fields.status.options.draft'),
          value: 'DRAFT',
        },
        {
          label: t('broadcast:pages.broadcast.filters.fields.status.options.inProgress'),
          value: 'IN_PROGRESS',
        },
        {
          label: t('broadcast:pages.broadcast.filters.fields.status.options.scheduled'),

          value: 'SCHEDULED',
        },
        {
          label: t('broadcast:pages.broadcast.filters.fields.status.options.sent'),

          value: 'SENT',
        },
      ],
      [t],
    );

    return (
      <ReactFilters
        form={form}
        onSave={() => {
          void handleSubmit(submitHandler)();
        }}
        onReset={() => {
          onClose();
          reset(defaultBroadcastFilters);
          onReset?.();
          history.push(location.pathname);
        }}
        filters={filters}
        visible={visible}
        onClose={onClose}
        testId="broadcast-filters"
      >
        <StyledTableFiltersForm>
          <Controller
            control={control}
            name="keywords"
            render={({ field }) => {
              return (
                <TextInput
                  data-lgg-id="broadcast-filters-field-keywords"
                  autoCapitalize="off"
                  label={t('broadcast:pages.broadcast.filters.fields.keywords.title')}
                  reserveErrorArea={false}
                  {...field}
                  value={field.value ?? undefined}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="archived"
            render={({ field }) => {
              const options: SelectOption<string>[] = [
                {
                  label: t(
                    'broadcast:pages.broadcast.filters.fields.archived.options.archived',
                  ),
                  value: '1',
                },
                {
                  label: t(
                    'broadcast:pages.broadcast.filters.fields.archived.options.notArchived',
                  ),
                  value: '0',
                },
              ];

              return (
                <Select
                  data-lgg-id="broadcast-filters-field-archived"
                  name="broadcast-filters-field-archived-not-archived"
                  value={
                    field.value
                      ? options.find(({ value }) => value === field.value)
                      : null
                  }
                  options={options}
                  placeholder={t('common:selectOne')}
                  isSearchable={false}
                  label={t('broadcast:pages.broadcast.filters.fields.archived.title')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="status"
            render={({ field }) => {
              return (
                <Select
                  data-lgg-id="broadcast-filters-field-status"
                  name="broadcast-filters-field-status"
                  value={
                    field.value
                      ? broadcastStatusOptions.find(({ value }) => value === field.value)
                      : null
                  }
                  options={broadcastStatusOptions}
                  placeholder={t('common:selectOne')}
                  isSearchable={false}
                  label={t('broadcast:pages.broadcast.filters.fields.status.title')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
        </StyledTableFiltersForm>
      </ReactFilters>
    );
  },
);

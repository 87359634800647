import React, { useMemo } from 'react';
import c from 'classnames';
import styled from 'styled-components';
import { P, match } from 'ts-pattern';
import { getDatesDifferenceInUnits } from '@lgg/isomorphic/i18n/date-utils';
import { Schedule } from '@lgg/isomorphic/types/__generated__/graphql';
import { PopoverV2 } from 'src/components/general/display/popover';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { FullCalendarViewType } from 'src/components/pages/calendar/components/desktop-calendar-view';
import { EventPopoverContent } from 'src/components/pages/calendar/components/event-popover-content';
import {
  useHandleScheduleActions,
  useHandleTaskActions,
} from 'src/components/pages/calendar/components/shared/hooks';
import { TaskEvent } from 'src/components/pages/calendar/components/shared/shared';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useVisible } from 'src/hooks/use-visible';

const CalendarEventTime = styled.span`
  margin-right: 4px;
  opacity: 0.75;
  font-family: ${({ theme }) => theme.font.regular};
`;

const CalendarEventTitle = styled.span`
  font-family: ${({ theme }) => theme.font.medium};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CalendarEventContactIcon = styled(Icon)`
  margin-left: 4px;

  svg {
    height: 10px;
    width: 10px;
  }
`;

const CalendarEventContainer = styled.div<{ $lineClamp: number }>`
  border-left: 2px solid;
  font-size: 11px;
  height: 100%;
  line-height: 13px;
  margin-bottom: 2px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  overflow: hidden;

  &.dayGridMonth {
    padding: 5px 5px 4px 3px;
  }

  &.timeGridWeek,
  &.timeGridDay {
    padding: 7px 5px 0px 3px;

    &.all-day {
      padding: 5px 3px 4px;
    }

    &.short-event {
      align-items: center;
      padding: 4px 5px 4px 3px;
    }

    // This code has to be dynamic since css doesn't allow multiline ellipsis
    // We add a line clap number based on the event duration and the space it
    // has in the calendar.
    // Not supported on internet explorer: https://css-tricks.com/almanac/properties/l/line-clamp/#aa-browser-support
    ${CalendarEventTitle} {
      white-space: break-spaces;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${({ $lineClamp }) => $lineClamp};
      -webkit-box-orient: vertical;
    }

    ${CalendarEventTime} {
      white-space: nowrap;
    }
  }

  &.appointment {
    background: #eae9f8;
    border-color: ${({ theme }) => theme.colors.secondaryPeriwinkle};

    &.all-day {
      background: ${({ theme }) => theme.colors.secondaryPeriwinkle30};
      border-color: ${({ theme }) => theme.colors.secondaryPeriwinkleDark};
      color: ${({ theme }) => theme.colors.secondaryPeriwinkleDark};
    }

    ${CalendarEventTime} {
      color: ${({ theme }) => theme.colors.secondaryPeriwinkleDark};
    }

    ${CalendarEventTitle} {
      color: ${({ theme }) => theme.colors.secondaryPeriwinkleDark};
    }

    ${CalendarEventContactIcon} {
      svg path {
        fill: ${({ theme }) => theme.colors.secondaryPeriwinkleDark};
      }
    }
  }

  &.task {
    background: #e1f8ec;
    border-color: ${({ theme }) => theme.colors.secondaryMint};

    &.all-day {
      background: ${({ theme }) => theme.colors.secondaryMint30};
      border-color: ${({ theme }) => theme.colors.secondaryMintDark};
      color: ${({ theme }) => theme.colors.secondaryMintDark};
    }

    ${CalendarEventTime} {
      color: ${({ theme }) => theme.colors.secondaryMintDark};
    }

    ${CalendarEventTitle} {
      color: ${({ theme }) => theme.colors.secondaryMintDark};
    }

    ${CalendarEventContactIcon} {
      svg path {
        fill: ${({ theme }) => theme.colors.secondaryMintDark};
      }
    }
  }
`;

const EventWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const CalendarMonthVariantEventWrapper = styled(FlexRow)`
  width: 100%;
  overflow: hidden;
`;

const CalendarDayVariantEventWrapper = styled(FlexColumn)`
  width: 100%;
`;

const getPopupContainerElement = (view: FullCalendarViewType) => {
  // This method determines where the popover should render.
  // If we are in the month view and the "more events" popover is open we use it as popover container
  // otherwise we use the custom `#calendar-container` to maintain the popover inside the calendar.
  // The reason behind this is because the "more events" popover closes when interacting the "event details" popover
  // because by default it rendered outside of the "more events" popover and clicking on it was "clicking outside" the modal.

  return (
    (view === 'dayGridMonth'
      ? (document.getElementsByClassName('fc-popover-body')?.[0] as HTMLElement)
      : undefined) ??
    document.getElementById('calendar-container') ??
    document.body
  );
};

type CalendarEventProps = {
  event: Schedule | TaskEvent;
  view: FullCalendarViewType;
};

const TaskItem = ({ task, view }: { task: TaskEvent; view: FullCalendarViewType }) => {
  const visibilityHandler = useVisible();
  const { handleEdit, handleDelete } = useHandleTaskActions();

  return (
    <PopoverV2
      visible={visibilityHandler.visible}
      getPopupContainer={() => getPopupContainerElement(view)}
      onVisibleChange={visibilityHandler.setVisible}
      align={{
        offset: [0, 5],
      }}
      overlayClassName="calendar-event-popover"
      content={
        <EventPopoverContent
          event={task}
          onEdit={() => handleEdit(task)}
          onDelete={() => handleDelete(task)}
          onClose={visibilityHandler.close}
        />
      }
      trigger={['click']}
      placement="topLeft"
    >
      <EventWrapper>
        <CalendarEventItem
          eventId={task.id}
          startDate={task.dueAt}
          title={task.title}
          type="task"
          view={view}
          hasContact={Boolean(task.contact)}
        />
      </EventWrapper>
    </PopoverV2>
  );
};

const ScheduleItem = ({
  schedule,
  view,
}: {
  schedule: Schedule;
  view: FullCalendarViewType;
}) => {
  const isAllDay = schedule.isAllDay;
  const visibilityHandler = useVisible();
  const { handleEdit, handleDelete } = useHandleScheduleActions();

  return (
    <PopoverV2
      visible={visibilityHandler.visible}
      getPopupContainer={() => getPopupContainerElement(view)}
      onVisibleChange={visibilityHandler.setVisible}
      overlayClassName="calendar-event-popover"
      align={{
        offset: [0, 5],
      }}
      content={
        <EventPopoverContent
          event={schedule}
          onEdit={() => handleEdit(schedule)}
          onDelete={() => handleDelete(schedule)}
          onClose={visibilityHandler.close}
        />
      }
      trigger={['click']}
      placement="topLeft"
    >
      <EventWrapper>
        <CalendarEventItem
          eventId={schedule.id}
          startDate={schedule.startAt}
          endDate={schedule.endAt}
          title={schedule.title}
          type="appointment"
          view={view}
          hasContact={Boolean(schedule.contact)}
          isAllDay={isAllDay}
        />
      </EventWrapper>
    </PopoverV2>
  );
};

type CalendarEventItemProps = {
  eventId: number;
  startDate: Date;
  endDate?: Date;
  title: string;
  hasContact?: boolean;
  type: 'appointment' | 'task';
  isAllDay?: boolean;
  view: FullCalendarViewType;
};

const CalendarEventItem = ({
  eventId,
  startDate,
  endDate,
  title,
  hasContact = false,
  isAllDay = false,
  type,
  view,
}: CalendarEventItemProps) => {
  const { formatSimpleTime, formatSimpleTimeRange } = useFormatDate();
  const eventDurationInMinutes =
    startDate && endDate
      ? getDatesDifferenceInUnits(startDate, endDate).minutes
      : undefined;
  // If the event does not have start or end date we will threat it as a short event
  // Tasks right now don't have end date but they might in the future
  const isShortEvent = eventDurationInMinutes ? eventDurationInMinutes <= 30 : true;
  const lineClampValue =
    eventDurationInMinutes && eventDurationInMinutes > 45
      ? Math.floor(eventDurationInMinutes / 15) - 2
      : 1;

  const eventTime = useMemo(
    () =>
      view === 'timeGridDay' && !isShortEvent
        ? formatSimpleTimeRange(startDate, endDate)
        : formatSimpleTime(startDate),
    [endDate, formatSimpleTime, formatSimpleTimeRange, isShortEvent, startDate, view],
  );

  const eventContent = match({
    view,
    isAllDay,
    isShortEvent,
  })
    .with(
      P.union(
        {
          view: 'dayGridMonth',
        },
        {
          isAllDay: true,
        },
        {
          isShortEvent: true,
        },
      ),
      ({ isAllDay }) => {
        return (
          <>
            <CalendarMonthVariantEventWrapper>
              {!isAllDay && (
                <CalendarEventTime data-lgg-id="calendar-event-time">
                  {eventTime}
                </CalendarEventTime>
              )}
              <CalendarEventTitle data-lgg-id="calendar-event-title">
                {title}
              </CalendarEventTitle>
            </CalendarMonthVariantEventWrapper>
            {hasContact && (
              <CalendarEventContactIcon
                type="contact"
                lggTestId="calendar-event-contact-icon"
              />
            )}
          </>
        );
      },
    )
    .otherwise(() => {
      return (
        <CalendarDayVariantEventWrapper>
          <FlexRow>
            <CalendarEventTime data-lgg-id="calendar-event-time">
              {eventTime}
            </CalendarEventTime>
            {hasContact && (
              <CalendarEventContactIcon
                type="contact"
                lggTestId="calendar-event-contact-icon"
              />
            )}
          </FlexRow>
          <CalendarEventTitle data-lgg-id="calendar-event-title">
            {title}
          </CalendarEventTitle>
        </CalendarDayVariantEventWrapper>
      );
    });

  return (
    <CalendarEventContainer
      className={c({
        [type]: true,
        [view]: true,
        'short-event': isShortEvent,
        'all-day': isAllDay,
      })}
      $lineClamp={lineClampValue}
      data-lgg-id={`calendar-event-${type}-${eventId}`}
    >
      {eventContent}
    </CalendarEventContainer>
  );
};

export const CalendarEvent = ({ event, view }: CalendarEventProps) => {
  // All cases handled for fixed values
  // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
  return match(event)
    .with({ __typename: 'Schedule' }, (schedule) => (
      <ScheduleItem schedule={schedule} view={view} />
    ))
    .with({ __typename: 'Task' }, (task) => <TaskItem task={task} view={view} />)
    .exhaustive();
};

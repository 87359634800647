import { useCallback } from 'react';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';

// https://day.js.org/docs/en/parse/string-format#list-of-all-recognized-separator-characters
// Need to use _ instead of "+" since its not a
// valid separator for parsing.
const FILTER_DATE_FORMAT = 'YYYY-MM-DD_hh:mm_A';

export const START_FILTER_DATE_FORMAT = 'YYYY-MM-DD[+12:00+AM]';

export const END_FILTER_DATE_FORMAT = 'YYYY-MM-DD[+11:59+PM]';

export const useFilterHelpers = () => {
  const { parseDate } = useDateHelpers();
  const { formatDate } = useFormatDate();

  // We need timezone context when encoding a date that we know has
  // timezone information. Ex: When converting contact where input to form values.
  // We DON'T need timezone context when encoding a date that we need its
  // information as is. Ex: When converting a date that comes from an URL in legacy format.
  const encodeStartFilterDate = useCallback(
    (date: Date, useTimezoneContext: boolean = false) =>
      formatDate(date, START_FILTER_DATE_FORMAT, useTimezoneContext),
    [formatDate],
  );

  const encodeFilterEndDate = useCallback(
    (date: Date, useTimezoneContext: boolean = false) =>
      formatDate(date, END_FILTER_DATE_FORMAT, useTimezoneContext),
    [formatDate],
  );

  const decodeFilterDate = useCallback(
    (encodedDate?: string) => {
      return encodedDate
        ? parseDate(encodedDate?.replaceAll(' ', '+'), FILTER_DATE_FORMAT)
        : new Date();
    },
    [parseDate],
  );

  return {
    decodeFilterDate,
    encodeFilterEndDate,
    encodeStartFilterDate,
  };
};

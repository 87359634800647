import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';
import { ResourceWhatsapp } from '@lgg/isomorphic/types/__generated__/graphql';
import { Table, TableColumns, TableProps } from 'src/components/general/display/table';
import { TableSortData, getDefaultSortOrder } from 'src/components/general/table-helpers';
import { FlexColumn } from 'src/components/layout/flex-column';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useUrls } from 'src/hooks/use-urls';

type WhatsAppResourcesTableProps = {
  data: ResourceWhatsapp[];
  sortData: TableSortData;
  onChange: TableProps<ResourceWhatsapp>['onChange'];
};

const sharedTextStyle = css`
  font-size: 13px;
  font-family: ${({ theme }) => theme.font.medium};
  color: ${({ theme }) => theme.colors.flint};
  line-height: 14px;
  margin: 0;
`;

const StyledText = styled.p`
  ${sharedTextStyle}
`;

const PhoneNumber = styled(StyledText)`
  margin-top: 4px;
`;

const PhoneNumberNameText = styled(StyledText)`
  color: ${({ theme }) => theme.colors.smalt};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const PhoneNumberNameLink = styled(Link)`
  ${sharedTextStyle}
  color: ${({ theme }) => theme.colors.smalt};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const DateCreatedText = styled(StyledText)`
  font-family: ${({ theme }) => theme.font.regular};
`;

// TODO: Change this once the valid status are available
const getTagTheme = (value: 'PENDING' | 'DONE' | 'DRAFT') => {
  // All cases handled for fixed values
  // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
  return match(value)
    .with('PENDING', () => ({
      color: 'secondaryGoldDark',
      border: 'secondaryGold60',
      background: 'secondaryGold10',
    }))
    .with('DONE', () => ({
      color: 'secondaryMintDark',
      border: 'secondaryMint60',
      background: 'secondaryMint10',
    }))
    .with('DRAFT', () => ({ color: 'raven', border: 'casper', background: 'koala' }))
    .exhaustive();
};

const NumberStatusTag = styled(({ tagTheme, ...props }) => <Tag {...props} />)`
  border-radius: 4px;
  color: ${({ theme, tagTheme }) => theme.colors[tagTheme.color]};
  border-color: ${({ theme, tagTheme }) => theme.colors[tagTheme.border]};
  background: ${({ theme, tagTheme }) => theme.colors[tagTheme.background]};
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 10px;
  height: 22px;
  letter-spacing: -0.14px;
  line-height: 12px;
  padding: 4px 10px;
  text-transform: uppercase;
`;

const WhatsappResourceDescription = styled(FlexColumn)`
  width: max-content;
`;

export const WhatsAppResourcesTable = memo<WhatsAppResourcesTableProps>(
  ({ data, sortData = { key: 'status', direction: 'DESC' }, onChange }) => {
    const { t } = useTranslation(['whatsappChannel', 'common']);
    const { getFeatureFlag } = useAuthorization();
    const { getWhatsappResourceUrl } = useUrls();
    const { formatSimpleRelativeDate } = useFormatDate();
    const hasMetaSignupFlag = getFeatureFlag(
      'temp_lggdev-1944_embed-meta-signup-script-on-whatsapp-resource-adding-workflow',
    );

    const { key: sortKey, direction: sortDirection } = sortData;

    const columns: TableColumns<ResourceWhatsapp>[] = [
      {
        title: (
          <span data-lgg-id="whatsapp-numbers-table-column-header-phone">
            {t('whatsappChannel:pages.channel.tableTitles.phone')}
          </span>
        ),
        key: 'phone',
        wrapContent: true,
        showSorterTooltip: false,
        sorter: false,
        render: (_, resourceWhatsapp) => {
          const { alias, label, phoneNumber, id } = resourceWhatsapp.resource;
          const textDescription = label ?? alias;
          const phoneDescription = phoneNumber.international;

          return (
            <WhatsappResourceDescription data-lgg-id="whatsapp-resource-description">
              {hasMetaSignupFlag ? (
                <PhoneNumberNameText data-lgg-id="whatsapp-resource-detail-link">
                  {textDescription ?? phoneDescription}
                </PhoneNumberNameText>
              ) : (
                <PhoneNumberNameLink
                  to={getWhatsappResourceUrl(id)}
                  data-lgg-id="whatsapp-resource-detail-link"
                >
                  {textDescription ?? phoneDescription}
                </PhoneNumberNameLink>
              )}
              {textDescription && <PhoneNumber>{phoneNumber.international}</PhoneNumber>}
            </WhatsappResourceDescription>
          );
        },
      },
      {
        title: (
          <span data-lgg-id="whatsapp-numbers-table-column-header-status">
            {t('whatsappChannel:pages.channel.tableTitles.status')}
          </span>
        ),
        key: 'status',
        dataIndex: 'status',
        width: '13%',
        showSorterTooltip: false,
        // TODO: Enable sorters when sort API available
        sorter: false,
        defaultSortOrder: getDefaultSortOrder(sortKey === 'status', sortDirection),
        render: (_, resource) => (
          <span data-lgg-id="whatsapp-resource-status">
            <NumberStatusTag
              tagTheme={getTagTheme('DONE')}
              data-lgg-id="whatsapp-numbers-table-cell-status"
            >
              {/* TODO: Add proper status */}
              ACTIVE
            </NumberStatusTag>
          </span>
        ),
      },
      {
        title: (
          <span data-lgg-id="whatsapp-numbers-table-column-header-date">
            {t('whatsappChannel:pages.channel.tableTitles.dateCreated')}
          </span>
        ),
        key: 'createdAt',
        dataIndex: 'createdAt',
        width: '155px',
        showSorterTooltip: false,
        sorter: false,
        defaultSortOrder: getDefaultSortOrder(sortKey === 'createdAt', sortDirection),
        render: (createdAt: string) => (
          <DateCreatedText data-lgg-id="whatsapp-resource-creation-date">
            {formatSimpleRelativeDate(createdAt)}
          </DateCreatedText>
        ),
      },
    ];

    return (
      <Table
        rowKey={({ resource }) => resource.id}
        data-lgg-id="whatsapp-numbers-table"
        columns={columns}
        tableLayout="fixed"
        loading={false}
        dataSource={data}
        pagination={false}
        showHeader={true}
        scroll={{ x: 1069 }}
        onChange={onChange}
        rowClassName={(record, index) =>
          `whatsapp-numbers-table-row whatsapp-numbers-row-${
            record.resource.id
          } whatsapp-numbers-row-position-${index + 1}`
        }
      />
    );
  },
);

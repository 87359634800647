import React, { memo, useMemo } from 'react';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import {
  ContactInteractionComponent,
  ContactInteractionComponentReaction,
  ContactInteractionDirection,
  ContactInteractionUnion,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { PopoverV2 } from 'src/components/general/display/popover';
import { ReactionPreviewRow } from 'src/components/pages/conversations/components/contact-interactions/items/whatsapp/reactions/reaction-preview';
import { BottomDrawer } from 'src/components/pages/conversations/components/drawer/bottom-drawer';
import { useVisible } from 'src/hooks/use-visible';

const StyledReactionWrapper = styled.div`
  position: relative;
`;

const Reaction = styled.div<{ direction: ContactInteractionDirection }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 13px;
  border: solid 1px ${({ theme }) => theme.colors.koala};
  bottom: -20px;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  height: 26px;
  justify-content: center;
  position: absolute;
  right: 15px;
  width: 30px;
  z-index: 2;

  ${({ direction }) => (direction === 'INBOUND' ? 'left: 15px' : 'right: 15px')};
`;

const ReactionWhiteSpace = styled.div`
  height: 22px;
  min-height: 22px;
`;

export const getFirstReactionFromContactInteractionComponent = (
  contactInteractionComponent: ContactInteractionComponent,
) => {
  return contactInteractionComponent.reactions?.[0] ?? null;
};

export const getMessageComponentReaction = (
  contactInteraction: ContactInteractionUnion,
) => {
  return match(contactInteraction)
    .with(
      P.union(
        { __typename: 'ContactInteractionWhatsapp', message: P.not(P.nullish) },
        { __typename: 'ContactInteractionInstagram', message: P.not(P.nullish) },
        { __typename: 'ContactInteractionFacebookMessenger', message: P.not(P.nullish) },
      ),
      (contactInteraction) =>
        getFirstReactionFromContactInteractionComponent(contactInteraction.message),
    )
    .with(
      { __typename: 'ContactInteractionWhatsapp', template: P.not(P.nullish) },
      (contactInteraction) =>
        getFirstReactionFromContactInteractionComponent(contactInteraction.template),
    )
    .otherwise(() => null);
};

type ReactionsWrapperProps = {
  children: React.ReactElement;
  reactions: ContactInteractionComponentReaction[] | null;
  direction: ContactInteractionDirection;
};

export const ReactionsWrapper = memo<ReactionsWrapperProps>(
  ({ children, reactions, direction }) => {
    const breakpointUpMd = useBreakpoint(up('md'));
    const { show, close, visible } = useVisible();

    const reactionElement = useMemo(() => {
      if (!reactions) {
        return null;
      }

      const reaction = reactions[0];

      if (!reaction || !reaction.contact) {
        return null;
      }

      const { status, stage, label } = reaction.contact;

      const reactionPreview = (
        <ReactionPreviewRow
          contactLabel={label}
          stageSlug={stage.slug}
          statusName={status.name}
          creationDate={reaction.createdAt}
          emoji={reaction.emoji}
        />
      );

      return match(breakpointUpMd)
        .with(true, () => (
          <PopoverV2
            hideArrow={true}
            placement="bottomRight"
            align={{
              offset: [0, -7],
            }}
            getPopupContainer={(element) => element.parentElement ?? document.body}
            destroyTooltipOnHide
            content={reactionPreview}
            trigger="click"
          >
            <Reaction direction={direction} data-lgg-id="message-reactions">
              {reaction.emoji}
            </Reaction>
          </PopoverV2>
        ))
        .otherwise(() => (
          <>
            <Reaction
              direction={direction}
              onClick={show}
              data-lgg-id="message-reactions"
            >
              {reaction.emoji}
            </Reaction>
            {/* TODO: ADD TRANSLATION */}
            <BottomDrawer onClose={close} visible={visible} title="Reactions">
              {reactionPreview}
            </BottomDrawer>
          </>
        ));
    }, [breakpointUpMd, close, direction, reactions, show, visible]);

    if (!reactionElement) {
      return children;
    }

    return (
      <>
        <StyledReactionWrapper>
          {children}
          {reactionElement}
        </StyledReactionWrapper>
        <ReactionWhiteSpace />
      </>
    );
  },
);

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Tag } from 'antd';
import styled, { css } from 'styled-components';
import { match, P } from 'ts-pattern';
import { BroadcastStatus } from '@lgg/isomorphic/types/__generated__/graphql';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { useShowConfirmationModal } from 'src/components/general/feedback/hooks/use-show-confirmation-modal';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { isBroadcastInProgress } from 'src/components/pages/broadcast/helpers';
import { resolvePreviewWithContactPlaceholders } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/insert-contact-placeholder-plugin';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';

const baseTagStyles = css`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 8px;
  height: 12px;
  letter-spacing: -0.16px;
  line-height: 10px;
  margin: 3px 0 0;
  padding: 0 4px;
  text-transform: uppercase;
  width: max-content;
`;

const StyledBroadcastStatusTag = styled(({ tagTheme, ...props }) => <Tag {...props} />)`
  ${baseTagStyles};
  background: ${({ theme, tagTheme }) => theme.colors[tagTheme.background]};
  border-color: ${({ theme, tagTheme }) => theme.colors[tagTheme.border]};
  border-radius: 2px;
  color: ${({ theme, tagTheme }) => theme.colors[tagTheme.color]};
`;

const BroadcastArchivedTag = styled(Tag)`
  ${baseTagStyles};
  background: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.geyser};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.flint};
  margin-left: 3px;
`;

export const BroadcastStatusTag = ({ status }: { status: BroadcastStatus }) => {
  const { t } = useTranslation(['common', 'broadcast']);

  const { label, ...tagTheme } = useMemo(() => {
    // All cases handled for fixed values
    // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
    return match(status)
      .with('SCHEDULED', () => ({
        color: 'secondaryGoldDark',
        border: 'secondaryGold60',
        background: 'secondaryGold10',
        label: t(
          'broadcast:pages.broadcast.broadcastTable.broadcastStatusBadge.scheduled',
        ),
      }))
      .with('SENT', () => ({
        color: 'secondaryMintDark',
        border: 'secondaryMint60',
        background: 'secondaryMint10',
        label: t('broadcast:pages.broadcast.broadcastTable.broadcastStatusBadge.sent'),
      }))
      .with('CANCELED', () => ({
        color: 'secondaryCoralDark',
        border: 'secondaryCoral60',
        background: 'secondaryCoral10',
        label: t(
          'broadcast:pages.broadcast.broadcastTable.broadcastStatusBadge.cancelled',
        ),
      }))
      .with(P.union('IN_PROGRESS_PREPARING', 'IN_PROGRESS_SENDING'), () => ({
        color: 'secondaryTopazDark',
        border: 'secondaryTopaz60',
        background: 'secondaryTopaz10',
        label: t(
          'broadcast:pages.broadcast.broadcastTable.broadcastStatusBadge.inProgress',
        ),
      }))
      .with('DRAFT', () => ({
        color: 'raven',
        border: 'casper',
        background: 'koala',
        label: t('broadcast:pages.broadcast.broadcastTable.broadcastStatusBadge.draft'),
      }))
      .exhaustive();
  }, [status, t]);

  return (
    <StyledBroadcastStatusTag tagTheme={tagTheme} data-lgg-id="broadcast-status-badge">
      {label}
    </StyledBroadcastStatusTag>
  );
};

export const BroadcastArchivedBadge = () => {
  const { t } = useTranslation(['common']);

  return (
    <BroadcastArchivedTag data-lgg-id="broadcast-archived-badge">
      {t('common:archived')}
    </BroadcastArchivedTag>
  );
};

export const useBroadcastMoreOptions = () => {
  const showConfirmationModal = useShowConfirmationModal();
  const { t } = useTranslation(['common', 'broadcast']);

  const archiveBroadcast = useCallback(
    (isInProgress: boolean) => {
      if (isInProgress) {
        showConfirmationModal({
          title: t(
            'broadcast:pages.broadcast.broadcastTable.archiveConfirmationModal.inProgress.title',
          ),
          message: t(
            'broadcast:pages.broadcast.broadcastTable.archiveConfirmationModal.inProgress.description',
          ),
          confirmButtonText: t(
            'broadcast:pages.broadcast.broadcastTable.archiveConfirmationModal.inProgress.confirmText',
          ),
          confirmButtonType: 'delete',
          testId: 'archive-in-progress-broadcast-confirm-message',
          onConfirm: () => {
            // TODO: IMPLEMENT THIS LOGIC
          },
        });
      } else {
        // Just archive it
      }
    },
    [showConfirmationModal, t],
  );

  const getBroadcastMoreOptions = useCallback(
    ({
      status,
      id,
      isArchived,
    }: {
      status: BroadcastStatus;
      id: string;
      isArchived: boolean;
    }) => {
      const isInProgress = isBroadcastInProgress(status);
      const showCancelOption = !isArchived && (isInProgress || status === 'SCHEDULED');

      return [
        ...(showCancelOption
          ? [
              {
                label: t('common:cancel'),
                icon: 'close',
                'data-lgg-id': 'broadcast-option-cancel',
                // TODO: ADD PROPER ACTION
                to: '/',
              },
            ]
          : []),
        // TODO: Get conditions to archive
        ...[
          isArchived
            ? {
                label: t('common:unarchive'),
                // TODO: ADD unarchive icon
                icon: 'archive',
                'data-lgg-id': 'broadcast-option-unarchive',
                // TODO: ADD PROPER ACTION
                onClick: () => {},
              }
            : {
                label: t('common:archive'),
                // TODO: ADD unarchive icon
                icon: 'archive',
                'data-lgg-id': 'broadcast-option-archive',
                // TODO: ADD PROPER ACTION
                onClick: () => {
                  archiveBroadcast(isInProgress);
                },
              },
        ],
      ];
    },
    [archiveBroadcast, t],
  );

  return {
    getBroadcastMoreOptions,
  };
};

const getBroadcastMessagePreview = (
  message?: string | null,
  optOutMessage?: string | null,
) => {
  return message || optOutMessage ? (
    <>
      {message ? resolvePreviewWithContactPlaceholders(message) : null}
      {message && optOutMessage ? (
        <>
          <br />
          <br />
        </>
      ) : null}
      {optOutMessage ? optOutMessage : null}
    </>
  ) : undefined;
};

const MessagePreview = styled.div`
  background: ${({ theme }) => theme.colors.porcelain};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  max-height: 300px;
  padding: 15px;
  white-space: pre-wrap;
`;

type BroadcastMessagePreviewProps = {
  message?: string | null;
  optOutMessage?: string | null;
};

export const BroadcastMessagePreview = ({
  message,
  optOutMessage,
}: BroadcastMessagePreviewProps) => {
  const messagePreview = getBroadcastMessagePreview(message, optOutMessage);

  return (
    <MessagePreview data-lgg-id="broadcast-message-preview">
      <Scrollbar>{messagePreview}</Scrollbar>
    </MessagePreview>
  );
};

export const useHandleUpdateBroadcastError = () => {
  const { t } = useTranslation(['broadcast']);
  const showNotification = useShowNotification();
  const handleGraphQLError = useHandleGraphQLError();

  const handleUpdateBroadcastError = (error: ApolloError) => {
    const [graphQlError] = error.graphQLErrors;

    match(graphQlError?.extensions?.code)
      .with('BUSINESS_RULE:UPDATING_IN_PROGRESS_BROADCAST', () => {
        showNotification({
          type: 'error',
          title: t(
            'broadcast:pages.broadcastWizard.errorMessages.updatingInProgressBroadcast.title',
          ),
          message: t(
            'broadcast:pages.broadcastWizard.errorMessages.updatingInProgressBroadcast.message',
          ),
        });
      })
      .otherwise(() => {
        handleGraphQLError(error);
      });
  };

  return handleUpdateBroadcastError;
};

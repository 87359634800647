import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import {
  ContactInteractionFacebookMessenger,
  ContactInteractionInstagram,
  ContactInteractionPhoneCall,
  ContactInteractionSms,
  ContactInteractionUnion,
  ContactInteractionWhatsapp,
  Maybe,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { matchContactInteractionExhaustive } from '@lgg/isomorphic/utils/match-contact-interaction';
import { matchConversationAttachmentExhaustive } from '@lgg/isomorphic/utils/match-conversation-attachment';
import { Icon } from 'src/components/general/icon';
import { TextFormatter } from 'src/components/general/text-formatter';
import { FlexRow } from 'src/components/layout/flex-row';
import { Content } from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import {
  getFirstReactionFromContactInteractionComponent,
  getMessageComponentReaction,
} from 'src/components/pages/conversations/components/contact-interactions/items/whatsapp/reactions/reactions-wrapper';

const PreviewIcon = styled(Icon)`
  margin-right: 4px;

  svg {
    height: 12px;
    width: 12px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const LastMessage = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 15px;
`;

const LastInteractionLabel = styled.p`
  margin: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    color: ${({ theme }) => theme.colors.flint};
    pointer-events: none;
  }
`;

const StyledTextFormatter = styled(TextFormatter)`
  line-height: 14px;
  white-space: nowrap;
`;

const ReactionText = styled.span`
  margin-right: 3px;
  white-space: nowrap;
`;

const AlertText = styled.span`
  color: ${({ theme }) => theme.colors.secondaryCoral};
`;

const CallInProgressLabel = styled.span`
  margin-left: 3px;
  color: ${({ theme }) => theme.colors.cosmo};
`;

type ConversationContactInteractionPreviewProps = {
  contactInteraction?: Maybe<ContactInteractionUnion>;
};

type ContactInteractionPreviewType = {
  label: string | JSX.Element;
  icon?: string;
  isMasked?: boolean;
};

export const ConversationContactInteractionPreview =
  memo<ConversationContactInteractionPreviewProps>(({ contactInteraction }) => {
    const { t } = useTranslation(['conversations', 'common']);

    if (!contactInteraction) {
      return null;
    }

    const { registrationType } = contactInteraction;
    const getContactInteractionPreview = (): ContactInteractionPreviewType | null => {
      const handleChatMessageContactInteraction = (
        contactInteraction:
          | ContactInteractionSms
          | ContactInteractionFacebookMessenger
          | ContactInteractionWhatsapp
          | ContactInteractionInstagram,
      ) => {
        const { attachments } = contactInteraction;

        if (registrationType === 'MANUAL') {
          // All cases handled for fixed values
          // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
          return match(contactInteraction.__typename)
            .with('ContactInteractionFacebookMessenger', () => ({
              icon: 'facebook',
              label: t(
                'conversations:contactInteractionPreview.facebookInteractionLogged',
              ),
            }))
            .with('ContactInteractionSms', () => ({
              icon: 'sms',
              label: t('conversations:contactInteractionPreview.smsLogged'),
            }))
            .with('ContactInteractionWhatsapp', () => ({
              icon: 'whatsapp',
              label: t(
                'conversations:contactInteractionPreview.whatsappInteractionLogged',
              ),
            }))
            .with('ContactInteractionInstagram', () => ({
              icon: 'instagramAlt',
              label: t(
                'conversations:contactInteractionPreview.instagramInteractionLogged',
              ),
            }))
            .exhaustive();
        } else if (attachments && attachments.length) {
          const attachment = attachments[0];

          const imageHandler = () => ({
            icon: 'mediatypeImage',
            label: t('conversations:contactInteractionPreview.image'),
          });

          return matchConversationAttachmentExhaustive(attachment, {
            image: imageHandler,
            sticker: imageHandler,
            video: () => ({
              icon: 'mediatypeVideo',
              label: t('conversations:contactInteractionPreview.video'),
            }),
            audio: () => ({
              icon: 'attachedAudio',
              label: t('conversations:contactInteractionPreview.audio'),
            }),
            document: () => ({
              icon: 'attach',
              label: t('conversations:contactInteractionPreview.file'),
            }),
            location: () => ({
              icon: 'location',
              label: t('conversations:contactInteractionPreview.location'),
            }),
            contacts: () => ({
              icon: 'contact',
              label: t('conversations:contactInteractionPreview.contacts'),
            }),
          });
        } else if (contactInteraction.message) {
          return {
            label: contactInteraction.message.content,
            isMasked: contactInteraction.isMasked,
          };
        } else if (
          contactInteraction.__typename === 'ContactInteractionWhatsapp' &&
          contactInteraction.template
        ) {
          return {
            label: contactInteraction.template.text,
            isMasked: contactInteraction.isMasked,
          };
        } else {
          return null;
        }
      };

      return matchContactInteractionExhaustive<
        | {
            icon: string;
            label: string | JSX.Element;
          }
        | {
            label: string;
          }
        | null
      >(contactInteraction, {
        phone: (contactInteraction) => {
          // No fallback
          // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
          const { label, icon }: ContactInteractionPreviewType = match(
            contactInteraction as ContactInteractionPhoneCall,
          )
            .with({ registrationType: 'MANUAL' }, () => ({
              label: t('conversations:contactInteractionPreview.callLogged'),
            }))
            .with(
              {
                callDetail: {
                  isInProgress: true,
                },
              },
              ({ direction }) => ({
                label: (
                  <CallInProgressLabel>{t('common:callInProgress')}</CallInProgressLabel>
                ),
                icon: direction === 'INBOUND' ? 'callIncoming' : 'callOutgoing',
              }),
            )
            .with({ direction: 'INBOUND', dialStatus: 'UNANSWERED' }, () => ({
              label: (
                <AlertText>
                  {t('conversations:contactInteractionPreview.missedCall')}
                </AlertText>
              ),
              icon: 'missedCall',
            }))
            .with({ direction: 'INBOUND' }, () => ({
              label: t('conversations:contactInteractionPreview.receivedACall'),
            }))
            .with({ direction: 'OUTBOUND' }, () => ({
              label: t('conversations:contactInteractionPreview.madeACall'),
            }))
            .exhaustive();

          return {
            icon: icon || 'phone',
            label,
          };
        },
        email: () => ({
          icon: 'email',
          label:
            registrationType === 'AUTOMATIC'
              ? t('conversations:contactInteractionPreview.email')
              : t('conversations:contactInteractionPreview.mailLogged'),
        }),
        facebookAd: () => ({
          icon: 'facebook',
          label: t('conversations:contactInteractionPreview.facebookForm'),
        }),
        inPerson: () => ({
          label: t('conversations:contactInteractionPreview.inPersonInteractionLogged'),
          icon: 'inPerson',
        }),
        facebookMessenger: (contactInteraction) =>
          handleChatMessageContactInteraction(contactInteraction),
        sms: (contactInteraction) =>
          handleChatMessageContactInteraction(contactInteraction),
        whatsapp: (contactInteraction) =>
          handleChatMessageContactInteraction(contactInteraction),
        instagram: (contactInteraction) =>
          handleChatMessageContactInteraction(contactInteraction),
        unknown: () => ({
          label: t('conversations:contactInteractionPreview.unknown'),
          icon: 'interaction',
        }),
      });
    };

    const contactInteractionPreview = getContactInteractionPreview();

    if (!contactInteractionPreview) {
      return null;
    }

    const getPreviewReaction = () => {
      const messageReaction = getMessageComponentReaction(contactInteraction);

      if (messageReaction) {
        return messageReaction;
      }

      if (
        contactInteraction.__typename === 'ContactInteractionWhatsapp' &&
        contactInteraction.attachments.length
      ) {
        return getFirstReactionFromContactInteractionComponent(
          contactInteraction.attachments[0],
        );
      }
    };

    const { icon, label, isMasked } = contactInteractionPreview;

    const previewReaction = getPreviewReaction();

    return (
      <Content isBlurred={isMasked}>
        <LastMessage data-lgg-id="last-contact-interaction-preview">
          <FlexRow style={{ alignItems: 'center' }}>
            {previewReaction && (
              <ReactionText>
                {t('conversations:contactInteractionPreview.reactedWithTo', {
                  emoji: previewReaction.emoji,
                })}
              </ReactionText>
            )}
            {icon && (
              <PreviewIcon type={icon} lggTestId="last-contact-interaction-icon" />
            )}
            <LastInteractionLabel data-lgg-id="last-contact-interaction-text">
              {!icon && typeof label === 'string' ? (
                <StyledTextFormatter>{label}</StyledTextFormatter>
              ) : (
                label
              )}
            </LastInteractionLabel>
          </FlexRow>
        </LastMessage>
      </Content>
    );
  });

import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { LexicalEditor } from 'lexical';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { ColorPaletteItem } from '@lgg/isomorphic';
import {
  Query,
  QueryContactInteractionChannelAvailabilityForContactArgs,
  ContactInteractionChannelAvailabilityForContact,
  ConversationItemsWhereInput,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { BlockedContactBanner } from 'src/components/pages/contacts/components/contact-block';
import { ContactInteractionInputManagerContext } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interaction-input-manager';
import { ContactInteractionsReplyModeInfoMessage } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interactions-reply-mode-message';
import { FacebookMessengerEditor } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/facebook-messenger-editor';
import { InstagramEditor } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/instagram-editor';
import { InternalNoteEditor } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/internal-note-editor';
import { SmsEditor } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/sms-editor';
import { WhatsappEditor } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/whatsapp-editor';
import { CONTACT_INTERACTION_CHANNEL_AVAILABILITY_FOR_CONTACT } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/graphql-operations';
import { ContactInteractionInputAreaHeader } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/message-type-selector';
import { ReplyChannelSelector } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/reply-channel-selector';
import {
  InputAreaContainer,
  InputAreaContent,
  ContactInteractionInputAreaMode,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/shared';
import { RESET_EDITOR_COMMAND } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/custom-clear-editor-plugin';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useUrls } from 'src/hooks/use-urls';
import { publishEvent } from 'src/utils/events/pub-sub';

const InternalNoteInfo = styled.p`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.flint};
  margin: 0;
  user-select: none;
`;

const EditContactDetailDescription = styled.span`
  color: ${({ theme }) => theme.colors.secondaryTopaz};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
`;

const EnableInternalNoteModeText = styled.span`
  font-family: ${({ theme }) => theme.font.medium};
  color: ${({ theme }) => theme.colors.gogo};
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  cursor: pointer;
`;

const CloseIcon = styled(Icon)`
  padding: 5px;

  svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const InternalNoteHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  ${up('md')} {
    align-items: center;
    flex-direction: row;
  }
`;

const InternalNoteHeaderTitle = styled.span`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  color: ${({ theme }) => theme.colors.secondaryGoldDark};
  margin-right: 5px;
`;

const InternalNoteHeaderIcon = styled(Icon)`
  display: none;
  margin-right: 10px;

  svg {
    width: 16px;
    height: 16px;
    path {
      fill: ${({ theme }) => theme.colors.secondaryGoldDark};
    }
  }

  ${up('md')} {
    display: block;
  }
`;

const UnavailableChannelsInfoMessage = ({ contactId }: { contactId: number }) => {
  const { t } = useTranslation(['conversations']);
  const { getLegacyContactEditUrl } = useUrls();

  return (
    <ContactInteractionsReplyModeInfoMessage
      lggId="contact-interaction-unavailable-channels-info-message"
      title={t(
        'conversations:messageInput.modes.reply.messages.general.unavailableChannels.title',
      )}
      body={
        <FlexColumn>
          {t(
            'conversations:messageInput.modes.reply.messages.general.unavailableChannels.body',
          )}
          <EditContactDetailDescription
            data-lgg-id="unavailable-channels-info-message-edit-contact-option"
            onClick={() => {
              openRemoteModalWrapper(getLegacyContactEditUrl(contactId));
            }}
          >
            {t(
              'conversations:messageInput.modes.reply.messages.general.unavailableChannels.editContactDetails',
            )}
          </EditContactDetailDescription>
        </FlexColumn>
      }
    />
  );
};

export const InternalNotePrivacyMessage = () => {
  const { t } = useTranslation(['conversations', 'smsChannel']);

  return (
    <InternalNoteHeaderContainer>
      <InternalNoteHeaderIcon type="note" />
      <InternalNoteHeaderTitle>
        {t('conversations:messageInput.modes.internalNote.title')}
      </InternalNoteHeaderTitle>
      <InternalNoteInfo>{`(${t(
        'conversations:messageInput.modes.internalNote.info',
      )})`}</InternalNoteInfo>
    </InternalNoteHeaderContainer>
  );
};

type ContactInteractionsInputAreaProps = {
  handleFetchCreatedItem: (where: ConversationItemsWhereInput) => void;
  setIsRendered: ValueChanged<boolean>;
  visible: boolean;
};

export const ContactInteractionsInputArea = memo<ContactInteractionsInputAreaProps>(
  ({ handleFetchCreatedItem, setIsRendered, visible }) => {
    const {
      contact,
      conversationId = '',
      setHasFocus,
      clearMessageInputStates,
      setMessageValues,
      inputAreaMode,
      setInputAreaMode,
      fetchingContactPhoneNumbers,
      selectedChannelSlug,
      setSelectedChannelSlug,
    } = useContext(ContactInteractionInputManagerContext);

    const [channelOptions, setChannelOptions] = useState<
      ContactInteractionChannelAvailabilityForContact[] | undefined
    >(undefined);

    const { t } = useTranslation(['errors', 'conversations', 'common', 'smsChannel']);
    const handleGraphQLError = useHandleGraphQLError();
    const replyWindowExpirationRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
      if (conversationId) {
        setMessageValues({
          message: '',
          rawMessage: '',
        });
      }
    }, [conversationId, setMessageValues]);

    const { loading: fetchingReplyChannels } = useQuery<
      Pick<Query, 'contactInteractionChannelAvailabilityForContact'>,
      QueryContactInteractionChannelAvailabilityForContactArgs
    >(CONTACT_INTERACTION_CHANNEL_AVAILABILITY_FOR_CONTACT, {
      variables: {
        contactId: contact.id,
      },
      fetchPolicy: 'network-only',
      skip: contact.id === null,
      onCompleted: ({ contactInteractionChannelAvailabilityForContact: data }) => {
        const sortedChannels = [...data].sort(
          (interactionChannel, nextInteractionChannel) =>
            Number(nextInteractionChannel.isAvailable) -
            Number(interactionChannel.isAvailable),
        );

        const defaultSelectedChannel = [...data]
          .filter((channel) => channel.isAvailable)
          .sort(
            (interactionChannel, nextInteractionChannel) =>
              new Date(nextInteractionChannel.lastInteractionDate).getTime() -
              new Date(interactionChannel.lastInteractionDate).getTime(),
          )?.[0];

        setSelectedChannelSlug(defaultSelectedChannel?.channel.slug ?? null);
        setChannelOptions(sortedChannels);
      },
      onError: handleGraphQLError,
    });

    useEffect(() => {
      if (!fetchingReplyChannels && !fetchingContactPhoneNumbers) {
        setIsRendered(true);
      }
    }, [fetchingContactPhoneNumbers, fetchingReplyChannels, setIsRendered]);

    const getBackgroundColorBySelectedInputAreaMode = (
      selectedMode: ContactInteractionInputAreaMode,
    ): ColorPaletteItem => {
      switch (selectedMode) {
        case ContactInteractionInputAreaMode.InternalNote: {
          return 'secondaryGold10';
        }
        default:
          return 'white';
      }
    };

    const isReplyModeEnabled = inputAreaMode === ContactInteractionInputAreaMode.Reply;
    const hideContent = fetchingReplyChannels || !channelOptions;
    const hasChannelsAvailability = useMemo(
      () =>
        isReplyModeEnabled
          ? channelOptions && channelOptions.some((channel) => channel.isAvailable)
          : true,
      [channelOptions, isReplyModeEnabled],
    );

    const headerLeadingContent = useMemo(() => {
      // All cases handled for fixed values
      // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
      return match(inputAreaMode)
        .with(ContactInteractionInputAreaMode.InternalNote, () => {
          return <InternalNotePrivacyMessage />;
        })
        .with(ContactInteractionInputAreaMode.Reply, () => {
          if (channelOptions) {
            return (
              <ReplyChannelSelector
                selectedChannel={selectedChannelSlug}
                setSelectedChannel={setSelectedChannelSlug}
                replyChannelOptions={channelOptions}
              />
            );
          }

          return null;
        })
        .exhaustive();
    }, [inputAreaMode, channelOptions, selectedChannelSlug, setSelectedChannelSlug]);

    const headerTrailingContent = useMemo(() => {
      // All cases handled for fixed values
      // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
      return match(inputAreaMode)
        .with(ContactInteractionInputAreaMode.InternalNote, () => {
          return (
            <CloseIcon
              data-lgg-id="disable-internal-note-mode-option"
              onClick={() => {
                setHasFocus(false);
                setInputAreaMode(ContactInteractionInputAreaMode.Reply);
              }}
              type="close"
            />
          );
        })
        .with(ContactInteractionInputAreaMode.Reply, () => {
          return (
            <EnableInternalNoteModeText
              data-lgg-id="enable-internal-note-mode-option"
              onClick={() => {
                setHasFocus(false);
                setInputAreaMode(ContactInteractionInputAreaMode.InternalNote);
              }}
            >
              {t('conversations:messageInput.modes.internalNote.addInternalNote')}
            </EnableInternalNoteModeText>
          );
        })
        .exhaustive();
    }, [inputAreaMode, setHasFocus, setInputAreaMode, t]);

    const handleFetchCreatedItemCompleted = useCallback(
      async ({
        editor,
        where,
      }: {
        editor?: LexicalEditor;
        where: ConversationItemsWhereInput;
      }) => {
        if (conversationId) {
          if (editor) {
            editor.dispatchCommand(RESET_EDITOR_COMMAND, clearMessageInputStates);
          }

          await handleFetchCreatedItem(where);

          publishEvent('CONVERSATION_UPDATED', { id: conversationId });
        }
      },
      [clearMessageInputStates, conversationId, handleFetchCreatedItem],
    );

    const replyEditor = useMemo(() => {
      if (
        fetchingReplyChannels ||
        !hasChannelsAvailability ||
        inputAreaMode !== ContactInteractionInputAreaMode.Reply
      ) {
        return null;
      }

      return match(selectedChannelSlug)
        .with('SMS', () => (
          <SmsEditor
            handleFetchNewMessageCompleted={handleFetchCreatedItemCompleted}
            channelAvailability={channelOptions?.find(
              (channel) => channel.channel.slug === 'SMS',
            )}
          />
        ))
        .with('FACEBOOK_MESSENGER', () => (
          <FacebookMessengerEditor
            handleFetchNewMessageCompleted={handleFetchCreatedItemCompleted}
            channelAvailability={channelOptions?.find(
              (channel) => channel.channel.slug === 'FACEBOOK_MESSENGER',
            )}
            availabilityMessageRef={replyWindowExpirationRef}
          />
        ))
        .with('INSTAGRAM', () => (
          <InstagramEditor
            handleFetchNewMessageCompleted={handleFetchCreatedItemCompleted}
            channelAvailability={channelOptions?.find(
              (channel) => channel.channel.slug === 'INSTAGRAM',
            )}
            availabilityMessageRef={replyWindowExpirationRef}
          />
        ))
        .with('WHATSAPP', () => (
          <WhatsappEditor
            handleFetchNewMessageCompleted={handleFetchCreatedItemCompleted}
            channelAvailability={channelOptions?.find(
              (channel) => channel.channel.slug === 'WHATSAPP',
            )}
            availabilityMessageRef={replyWindowExpirationRef}
          />
        ))
        .otherwise(() => null);
    }, [
      channelOptions,
      fetchingReplyChannels,
      handleFetchCreatedItemCompleted,
      hasChannelsAvailability,
      inputAreaMode,
      selectedChannelSlug,
    ]);
    const internalNoteEditor = useMemo(() => {
      return (
        <InternalNoteEditor
          handleFetchNewMessageCompleted={handleFetchCreatedItemCompleted}
        />
      );
    }, [handleFetchCreatedItemCompleted]);

    const inputAreaContent = useMemo(() => {
      if (contact.isBlocked) {
        return <BlockedContactBanner contact={contact} />;
      }

      if (hideContent) {
        return null;
      }

      if (!hasChannelsAvailability) {
        return <UnavailableChannelsInfoMessage contactId={contact.id} />;
      }

      return replyEditor;
    }, [contact, hasChannelsAvailability, hideContent, replyEditor]);

    if (!channelOptions || !visible) {
      return null;
    }

    return (
      <InputAreaContainer
        backgroundColor={getBackgroundColorBySelectedInputAreaMode(inputAreaMode)}
      >
        <span ref={replyWindowExpirationRef}></span>
        <ContactInteractionInputAreaHeader
          leading={headerLeadingContent}
          trailing={headerTrailingContent}
        />
        <InputAreaContent>
          {isReplyModeEnabled ? inputAreaContent : null}
          {internalNoteEditor}
        </InputAreaContent>
      </InputAreaContainer>
    );
  },
);

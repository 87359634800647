import React, { memo, useMemo } from 'react';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';
import {
  IconButtonV2,
  StyledIconButtonV2,
} from 'src/components/general/button/icon-button';
import {
  DrawerOption,
  OptionsBottomDrawer,
} from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { LggCircleIcon } from 'src/components/general/icon/circle-icon/lgg-circle-icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';

const StyledItemIcon = styled(LggCircleIcon)`
  & {
    height: 36px;
    min-height: 36px;
    min-width: 36px;
    width: 36px;

    svg {
      height: 16px;
      width: 16px;
    }

    ${up('md')} {
      margin-right: 20px;
    }
  }
`;

type ItemIconProps = {
  type: 'task' | 'appointment' | 'note';
};

export const ItemIcon = memo<ItemIconProps>(({ type }) => {
  switch (type) {
    case 'task':
      return (
        <StyledItemIcon
          bgColor="secondaryMint20"
          iconColor="secondaryMintDark"
          icon="task18"
        />
      );
    case 'appointment':
      return (
        <StyledItemIcon
          bgColor="secondaryPeriwinkle20"
          iconColor="secondaryPeriwinkleDark"
          icon="schedule18"
        />
      );
    case 'note':
      return (
        <StyledItemIcon
          bgColor="secondaryGold20"
          iconColor="secondaryGoldDark"
          icon="note"
        />
      );
  }
});

export const ItemMainInfoTextStyle = css`
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 16px;
  text-align: left;

  ${up('md')} {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const ItemSecondaryInfoTextStyle = css`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 14px;
  text-align: left;
`;

export const ItemTitle = styled.span`
  ${ItemMainInfoTextStyle};
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  margin-top: 10px;
`;

export const ItemDescription = styled(ItemTitle)`
  ${ItemMainInfoTextStyle};
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  margin-top: 7px;

  ${up('md')} {
    margin-top: 10px;
  }
`;

export const ItemSecondaryInfoLight = styled.span`
  ${ItemSecondaryInfoTextStyle};
  color: ${({ theme }) => theme.colors.flint};
`;

export const ItemSecondaryInfoStrong = styled.span`
  ${ItemSecondaryInfoTextStyle};
  color: ${({ theme }) => theme.colors.smalt};
  margin-right: 2px;
`;

export const ItemCreationDate = styled(ItemSecondaryInfoLight)`
  margin-bottom: 10px;
  margin-top: 7px;

  ${up('md')} {
    margin-bottom: 20px;
    margin-top: 6px;
  }
`;

export const ItemContainer = styled(FlexColumn)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
  padding: 20px;

  ${up('md')} {
    padding: 30px 20px;
  }
`;

const MoreOptionsIcon = styled(Icon)`
  display: block;
  margin-left: 20px;
  margin-top: 10px;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const DesktopOptionsButtonsContainer = styled(FlexRow)<{ $visible: boolean }>`
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;

  > ${StyledIconButtonV2} {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  > ${StyledIconButtonV2} + ${StyledIconButtonV2} {
    margin-left: 10px;
  }

  ${StyledIconButtonV2}:hover svg path {
    fill: ${({ theme }) => theme.colors.smalt};
  }
`;

export type ItemMoreOptionsOption = DrawerOption & { type: 'edit' | 'delete' | 'link' };

type ItemMoreOptionsProps = {
  itemId: string | number;
  title: string;
  options: ItemMoreOptionsOption[];
  isHovered: boolean;
};

export const ItemMoreOptions = memo<ItemMoreOptionsProps>(
  ({ title, options, isHovered, itemId }) => {
    const breakpointUpMd = useBreakpoint(up('md'));
    const optionsVisibility = useVisible();

    const desktopOptions = useMemo(() => {
      return options.map((option) =>
        // All cases handled for fixed values
        // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
        match(option)
          .with(
            {
              type: 'delete',
            },
            (option) => {
              return (
                <IconButtonV2
                  key={`${itemId}-delete-button`}
                  size="small"
                  data-lgg-id="delete-button"
                  variant="default"
                  icon="deleteBin"
                  onClick={option.onClick}
                />
              );
            },
          )
          .with(
            {
              type: 'edit',
            },
            (option) => {
              return (
                <IconButtonV2
                  key={`${itemId}-edit-button`}
                  size="small"
                  data-lgg-id="edit-button"
                  variant="default"
                  icon="edit"
                  onClick={option.onClick}
                />
              );
            },
          )
          .with(
            {
              type: 'link',
            },
            (option) => {
              return (
                <IconButtonV2
                  key={`${itemId}-view-button`}
                  size="small"
                  data-lgg-id="view-button"
                  variant="default"
                  icon="externalLink"
                  onClick={option.onClick}
                />
              );
            },
          )
          .exhaustive(),
      );
    }, [itemId, options]);

    return !breakpointUpMd ? (
      <>
        <MoreOptionsIcon onClick={optionsVisibility.show} type="moreOptions" />
        <OptionsBottomDrawer
          visible={optionsVisibility.visible}
          title={title}
          onClose={optionsVisibility.close}
          options={options.map(({ type, ...drawerOption }) => drawerOption)}
        />
      </>
    ) : (
      <DesktopOptionsButtonsContainer $visible={isHovered}>
        {desktopOptions.map((option) => option)}
      </DesktopOptionsButtonsContainer>
    );
  },
);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { range } from 'lodash';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { DatePicker } from 'src/components/general/inputs/date-picker';
import { Radio } from 'src/components/general/inputs/radio';
import { Select, SelectOption } from 'src/components/general/inputs/select/select';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  BroadcastWizardStepProps,
  getTimeMinutesEquivalent,
  splitTimeUnits,
} from 'src/components/pages/broadcast/components/broadcast-wizard/broadcast-wizard';
import {
  WizardStepSectionDivider,
  WizardStepSectionTitle,
} from 'src/components/pages/broadcast/components/broadcast-wizard/shared';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';

const SendingTimeRadioOptionsContainer = styled(FlexColumn)`
  margin-bottom: 25px;
  margin-top: 10px;

  & > * {
    &:not(:last-child) {
      margin-bottom: 13px !important;
    }
  }
`;

const SendLaterFormContainer = styled(FlexRow)`
  margin-bottom: 25px;
`;

const SendLaterDatePicker = styled(DatePicker)`
  .date-picker-label {
    margin-bottom: 1px;
  }

  .ant-picker {
    height: 38px;
  }
`;

const SendLaterFormInputContainer = styled.div`
  width: 198px;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const DeliveryTimeFormContainer = styled(FlexColumn)`
  margin-top: 20px;
`;

const DeliveryTimePresetSelect = styled(Select)`
  width: 125px;
  margin-right: 25px;
` as typeof Select;

const DeliveryTimeSectionContainer = styled.div`
  margin-top: 20px;
`;

const CustomDeliveryTimeFormContainer = styled(FlexColumn)`
  margin-top: 5px;
  margin-bottom: 13px;
`;

const StartToEndTimePickersContainer = styled(FlexRow)`
  align-items: center;
  margin-bottom: 12px;
`;

const PresetFormContainer = styled(FlexRow)`
  align-items: center;
  margin-bottom: 15px;

  ${StartToEndTimePickersContainer} {
    margin-bottom: 0;
  }
`;

const PresetFormMessage = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  line-height: 16px;
`;

const StartToEndTimePickersLabel = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  line-height: 16px;
  margin-right: 20px;
  text-align: left;
  width: 130px;
`;

const StartToEndTimePickersToLabel = styled(StartToEndTimePickersLabel)`
  margin: 0 15px;
  width: 15px;
`;

const TimePickerSelect = styled(Select)`
  width: 106px;

  .lgg-select__indicators {
    padding: 0px 12px 0px 5px;
  }
` as typeof Select;

const StyledRadio = styled(Radio)`
  line-height: 16px !important;

  .check-mark-container {
    background-color: ${({ theme }) => theme.colors.porcelain};
  }
`;

const normalizeFormFieldName = (name: string) => name.replace('.', '-');

export const NONE_DELIVERY_TIME_VALUE = 'NONE';

const timeWindowDefaultValues = {
  start: '08:00:00',
  end: '18:00:00',
};

enum SendingTimeRadioOption {
  NOW = 'NOW',
  LATER = 'LATER',
}

enum DeliveryTimeOption {
  EVERYDAY = 'EVERYDAY',
  WEEKDAYS = 'WEEKDAYS',
  WEEKEND = 'WEEKEND',
  CUSTOM = 'CUSTOM',
}

type FormDeliveryTimeRangeKey =
  | 'deliveryTimeCustom.0.start'
  | 'deliveryTimeCustom.1.start'
  | 'deliveryTimeCustom.2.start'
  | 'deliveryTimeCustom.3.start'
  | 'deliveryTimeCustom.4.start'
  | 'deliveryTimeCustom.5.start'
  | 'deliveryTimeCustom.6.start'
  | 'deliveryTimePreset.timeWindow.start'
  | 'deliveryTimeCustom.0.end'
  | 'deliveryTimeCustom.1.end'
  | 'deliveryTimeCustom.2.end'
  | 'deliveryTimeCustom.3.end'
  | 'deliveryTimeCustom.4.end'
  | 'deliveryTimeCustom.5.end'
  | 'deliveryTimeCustom.6.end'
  | 'deliveryTimePreset.timeWindow.end';

type FormDeliveryTimeRangeBaseKey =
  | 'deliveryTimeCustom.0'
  | 'deliveryTimeCustom.1'
  | 'deliveryTimeCustom.2'
  | 'deliveryTimeCustom.3'
  | 'deliveryTimeCustom.4'
  | 'deliveryTimeCustom.5'
  | 'deliveryTimeCustom.6'
  | 'deliveryTimePreset.timeWindow';

export const ScheduleDetailsStep = ({ form }: BroadcastWizardStepProps) => {
  const { t } = useTranslation(['broadcast', 'common']);
  const { startOfToday, startOfDay, parseISO, set, isToday, isPast } = useDateHelpers();
  const { formatSimpleTime, formatDate } = useFormatDate();

  const [sendingTime, setSendingTime] = useState<SendingTimeRadioOption>(
    form.getValues().scheduledStartAt
      ? SendingTimeRadioOption.LATER
      : SendingTimeRadioOption.NOW,
  );

  const [deliveryTime, setDeliveryTime] = useState<DeliveryTimeOption>(
    (form.getValues().deliveryTimePreset?.presetType as DeliveryTimeOption) ??
      DeliveryTimeOption.EVERYDAY,
  );

  const initCustomForm = useCallback(() => {
    form.setValue('deliveryTimeCustom', {
      '0': timeWindowDefaultValues,
      '1': timeWindowDefaultValues,
      '2': timeWindowDefaultValues,
      '3': timeWindowDefaultValues,
      '4': timeWindowDefaultValues,
      '5': timeWindowDefaultValues,
      '6': timeWindowDefaultValues,
    });
  }, [form]);

  const initPresetForm = useCallback(
    (presetType: DeliveryTimeOption) => {
      form.setValue('deliveryTimePreset', {
        presetType: presetType,
        timeWindow: timeWindowDefaultValues,
      });
    },
    [form],
  );

  useEffect(() => {
    const { deliveryTimePreset } = form.getValues();
    const presetType = deliveryTimePreset?.presetType;
    const start = deliveryTimePreset?.timeWindow?.start;
    const end = deliveryTimePreset?.timeWindow?.end;

    if (!presetType) {
      if (!start && !end) {
        initPresetForm(DeliveryTimeOption.EVERYDAY);
      }
    }
  }, [form, initPresetForm]);

  useEffect(() => {
    const formWatchSubscription = form.watch((formValues) => {
      const { deliveryTimePreset, deliveryTimeCustom } = formValues;

      match(deliveryTimePreset?.presetType)
        .with(DeliveryTimeOption.CUSTOM, () => {
          if (form.formState.errors.deliveryTimeCustom) {
            form.clearErrors();
          }

          if (deliveryTimeCustom) {
            const hasInvalidCustomValues = Object.values(
              formValues.deliveryTimeCustom ?? {},
            ).every((value) => value?.start && value.start === NONE_DELIVERY_TIME_VALUE);

            if (hasInvalidCustomValues) {
              range(0, 7).forEach((dayIndex) => {
                form.setError(
                  `deliveryTimeCustom.${dayIndex}.start` as FormDeliveryTimeRangeKey,
                  { type: 'validate' },
                );
              });
            }
          }
        })
        .otherwise(() => {});
    });

    return () => formWatchSubscription.unsubscribe();
  }, [deliveryTime, form, parseISO]);

  useEffect(() => {
    if (
      sendingTime === SendingTimeRadioOption.LATER &&
      !form.getValues().scheduledStartAt?.date
    ) {
      form.setValue('scheduledStartAt', {
        date: startOfToday().toISOString(),
        timeInMinutes: null,
      });
    }
  }, [form, sendingTime, startOfToday]);

  const minutesOptions = useMemo(() => range(0, 96).map((value) => value * 15), []);

  const timeOptions = useMemo(() => {
    const refDate = startOfToday();
    const options: SelectOption<number>[] = minutesOptions.map((minuteOption) => {
      const date = set(refDate, { minutes: minuteOption });

      return {
        label: formatSimpleTime(date),
        value: minuteOption,
      };
    });

    return options;
  }, [formatSimpleTime, minutesOptions, set, startOfToday]);

  const getTimePickers = useCallback(
    ({
      key,
      label,
      testId,
      includeNoneOption = false,
    }: {
      key: FormDeliveryTimeRangeBaseKey;
      testId: string;
      label?: string;
      includeNoneOption: boolean;
    }) => {
      const startKey = `${key}.start` as FormDeliveryTimeRangeKey;
      const endKey = `${key}.end` as FormDeliveryTimeRangeKey;

      const startOptions = [
        ...(includeNoneOption
          ? [
              {
                value: NONE_DELIVERY_TIME_VALUE,
                label: t('common:none'),
              },
            ]
          : []),
        ...timeOptions,
      ];

      return (
        <StartToEndTimePickersContainer key={`picker-${key}`}>
          {label ? (
            <StartToEndTimePickersLabel>{label}</StartToEndTimePickersLabel>
          ) : null}
          <Controller
            control={form.control}
            name={startKey}
            rules={{
              required: true,
              validate: (startTime) => {
                if (startTime === NONE_DELIVERY_TIME_VALUE) {
                  return true;
                }

                const endTime = form.getValues(endKey);

                if (endTime && startTime) {
                  const refDate = startOfToday();

                  return (
                    set(refDate, splitTimeUnits(endTime)).getTime() >
                    set(refDate, splitTimeUnits(startTime)).getTime()
                  );
                }

                return false;
              },
            }}
            key={startKey}
            render={({ field, fieldState }) => {
              return (
                <TimePickerSelect<SelectOption<string | number>>
                  name={normalizeFormFieldName(`${key}-start-time`)}
                  hasError={Boolean(fieldState.error)}
                  value={
                    field.value
                      ? startOptions.find((option) => {
                          if (field.value === NONE_DELIVERY_TIME_VALUE) {
                            return startOptions[0];
                          }

                          return (
                            getTimeMinutesEquivalent(field.value as string) ===
                            Number(option.value)
                          );
                        })
                      : undefined
                  }
                  options={startOptions}
                  isClearable={false}
                  isSearchable={false}
                  forceCaret
                  onChange={(option) => {
                    form.clearErrors(key);

                    if (option?.value === NONE_DELIVERY_TIME_VALUE) {
                      form.setValue(startKey, NONE_DELIVERY_TIME_VALUE);
                    } else if (option?.value !== undefined) {
                      const date = set(startOfToday(), {
                        minutes: Number(option.value),
                      });
                      form.setValue(startKey, formatDate(date, 'HH:mm:ss'));
                    }
                  }}
                />
              );
            }}
          />
          <Controller
            control={form.control}
            name={startKey}
            render={({ field: startField }) => {
              return startField.value !== NONE_DELIVERY_TIME_VALUE ? (
                <Controller
                  control={form.control}
                  name={endKey}
                  rules={{
                    required: true,
                    validate: (endTime) => {
                      const startTime = form.getValues(startKey);

                      if (endTime && startTime) {
                        const refDate = startOfToday();

                        return (
                          set(refDate, splitTimeUnits(endTime)).getTime() >
                          set(refDate, splitTimeUnits(startTime)).getTime()
                        );
                      }

                      return false;
                    },
                  }}
                  key={endKey}
                  render={({ field: endField, fieldState }) => {
                    return (
                      <>
                        <StartToEndTimePickersToLabel>
                          {t(
                            'broadcast:pages.broadcastWizard.steps.scheduleDetails.sections.deliveryTime.to',
                          )}
                        </StartToEndTimePickersToLabel>
                        <TimePickerSelect<SelectOption<number>>
                          name={normalizeFormFieldName(`${key}-end-time`)}
                          value={
                            endField.value
                              ? timeOptions.find(
                                  (option) =>
                                    getTimeMinutesEquivalent(endField.value as string) ===
                                    Number(option.value),
                                )
                              : undefined
                          }
                          hasError={Boolean(fieldState.error)}
                          options={timeOptions}
                          isClearable={false}
                          isSearchable={false}
                          data-lgg-id={`${testId}-end-time`}
                          forceCaret
                          onChange={(option) => {
                            form.clearErrors(key);

                            if (option?.value !== undefined) {
                              const date = set(startOfToday(), {
                                minutes: option.value,
                              });
                              form.setValue(endKey, formatDate(date, 'HH:mm:ss'));
                            }
                          }}
                        />
                      </>
                    );
                  }}
                />
              ) : (
                <></>
              );
            }}
          />
        </StartToEndTimePickersContainer>
      );
    },
    [form, formatDate, set, startOfToday, t, timeOptions],
  );

  const customFormDaysConfig = useMemo(
    () => [
      {
        label: t('common:daysOfTheWeek.monday'),
        index: 1,
      },
      {
        label: t('common:daysOfTheWeek.tuesday'),
        index: 2,
      },
      {
        label: t('common:daysOfTheWeek.wednesday'),
        index: 3,
      },
      {
        label: t('common:daysOfTheWeek.thursday'),
        index: 4,
      },
      {
        label: t('common:daysOfTheWeek.friday'),
        index: 5,
      },
      {
        label: t('common:daysOfTheWeek.saturday'),
        index: 6,
      },
      {
        label: t('common:daysOfTheWeek.sunday'),
        index: 0,
      },
    ],
    [t],
  );

  const deliveryTimeOptions: SelectOption<DeliveryTimeOption>[] = useMemo(
    () => [
      {
        label: t(
          'broadcast:pages.broadcastWizard.steps.scheduleDetails.sections.deliveryTime.options.everyday',
        ),
        value: DeliveryTimeOption.EVERYDAY,
      },
      {
        label: t(
          'broadcast:pages.broadcastWizard.steps.scheduleDetails.sections.deliveryTime.options.weekdays',
        ),
        value: DeliveryTimeOption.WEEKDAYS,
      },
      {
        label: t(
          'broadcast:pages.broadcastWizard.steps.scheduleDetails.sections.deliveryTime.options.weekends',
        ),
        value: DeliveryTimeOption.WEEKEND,
      },
      {
        label: t(
          'broadcast:pages.broadcastWizard.steps.scheduleDetails.sections.deliveryTime.options.custom',
        ),
        value: DeliveryTimeOption.CUSTOM,
      },
    ],
    [t],
  );

  const presetMessage = useMemo(() => {
    if (deliveryTime === DeliveryTimeOption.WEEKDAYS) {
      return (
        <PresetFormMessage data-lgg-id="sending-time-preset-message">
          {t(
            'broadcast:pages.broadcastWizard.steps.scheduleDetails.sections.deliveryTime.weekdaysMessage',
          )}
        </PresetFormMessage>
      );
    }
    return null;
  }, [deliveryTime, t]);

  const customForm = useMemo(() => {
    if (deliveryTime === DeliveryTimeOption.CUSTOM) {
      return (
        <CustomDeliveryTimeFormContainer data-lgg-id="sending-time-custom-form">
          {customFormDaysConfig.map(({ label, index }) =>
            getTimePickers({
              key: `deliveryTimeCustom.${index}` as FormDeliveryTimeRangeBaseKey,
              testId: 'delivery-time-custom',
              label,
              includeNoneOption: true,
            }),
          )}
        </CustomDeliveryTimeFormContainer>
      );
    }
    return null;
  }, [customFormDaysConfig, deliveryTime, getTimePickers]);

  const presetForm = useMemo(() => {
    return match(deliveryTime)
      .with(DeliveryTimeOption.CUSTOM, () => null)
      .otherwise(() =>
        getTimePickers({
          includeNoneOption: false,
          key: 'deliveryTimePreset.timeWindow',
          testId: 'delivery-time-preset',
        }),
      );
  }, [deliveryTime, getTimePickers]);

  return (
    <FlexColumn data-lgg-id="broadcast-wizard-step-schedule-details">
      <WizardStepSectionTitle>
        {t(
          'broadcast:pages.broadcastWizard.steps.scheduleDetails.sections.sendingTime.title',
        )}
      </WizardStepSectionTitle>
      <SendingTimeRadioOptionsContainer>
        <StyledRadio
          checked={sendingTime === SendingTimeRadioOption.NOW}
          name="sending-time-radio-option-send-NOW"
          data-lgg-id="sending-time-radio-option-send-NOW"
          onChange={(value) => {
            setSendingTime(value as SendingTimeRadioOption);
            form.setValue('scheduledStartAt', null);
          }}
          text={t(
            'broadcast:pages.broadcastWizard.steps.scheduleDetails.sections.sendingTime.options.sendNow',
          )}
          value={SendingTimeRadioOption.NOW}
        />
        <StyledRadio
          checked={sendingTime === SendingTimeRadioOption.LATER}
          name="sending-time-radio-option-send-LATER"
          data-lgg-id="sending-time-radio-option-send-LATER"
          onChange={(value) => {
            setSendingTime(value as SendingTimeRadioOption);
          }}
          text={t(
            'broadcast:pages.broadcastWizard.steps.scheduleDetails.sections.sendingTime.options.sendLater',
          )}
          value={SendingTimeRadioOption.LATER}
        />
      </SendingTimeRadioOptionsContainer>
      {sendingTime === SendingTimeRadioOption.LATER ? (
        <SendLaterFormContainer data-lgg-id="broadcast-schedule-sending-time-later-form">
          <SendLaterFormInputContainer>
            <Controller
              control={form.control}
              name="scheduledStartAt.date"
              rules={{
                required: true,
              }}
              key="broadcast-wizard-scheduled-at-date"
              render={({ field, fieldState }) => {
                return (
                  <SendLaterDatePicker
                    hasError={Boolean(fieldState.error)}
                    onChange={(date: Date) => {
                      form.setValue('scheduledStartAt.date', date.toISOString());
                    }}
                    value={field.value ? parseISO(field.value) : new Date()}
                    label={t(
                      'broadcast:pages.broadcastWizard.steps.scheduleDetails.sections.sendingTime.scheduleDate',
                    )}
                    format="DD/MM/YYYY"
                    data-lgg-id="send-later-schedule-date-picker"
                    disabledDate={(date) =>
                      !isToday(date.toDate()) && isPast(date.toDate())
                    }
                  />
                );
              }}
            />
          </SendLaterFormInputContainer>
          <SendLaterFormInputContainer>
            <Controller
              control={form.control}
              name="scheduledStartAt.date"
              rules={{
                required: true,
              }}
              render={({ field: dateField }) => {
                const refDate = dateField.value
                  ? parseISO(dateField.value)
                  : startOfToday();

                const sendingTimeTimeOptions: SelectOption<number>[] = minutesOptions.map(
                  (minute) => {
                    const properDate = set(startOfDay(refDate), {
                      minutes: minute,
                    });

                    return {
                      label: formatSimpleTime(properDate),
                      value: minute,
                      isDisabled: isPast(properDate),
                    };
                  },
                );

                return (
                  <Controller
                    control={form.control}
                    name="scheduledStartAt.timeInMinutes"
                    rules={{
                      required: true,
                    }}
                    key="broadcast-wizard-scheduled-at-time"
                    render={({ field, fieldState }) => {
                      return (
                        <Select<SelectOption<number>>
                          name="send-later-schedule-time-picker"
                          value={sendingTimeTimeOptions.find(
                            (option) => option.value === field.value,
                          )}
                          hasError={Boolean(fieldState.error)}
                          options={sendingTimeTimeOptions}
                          isClearable={false}
                          isSearchable={false}
                          forceCaret
                          label={t(
                            'broadcast:pages.broadcastWizard.steps.scheduleDetails.sections.sendingTime.scheduleTime',
                          )}
                          onChange={(option) => {
                            form.setValue(
                              'scheduledStartAt.timeInMinutes',
                              option?.value,
                            );
                          }}
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </SendLaterFormInputContainer>
        </SendLaterFormContainer>
      ) : null}
      <WizardStepSectionDivider />
      <DeliveryTimeSectionContainer>
        <WizardStepSectionTitle>
          {t(
            'broadcast:pages.broadcastWizard.steps.scheduleDetails.sections.deliveryTime.title',
          )}
        </WizardStepSectionTitle>
        <DeliveryTimeFormContainer>
          <PresetFormContainer>
            <DeliveryTimePresetSelect<SelectOption<DeliveryTimeOption>>
              name="delivery-time-select"
              value={deliveryTimeOptions.find((option) => option.value === deliveryTime)}
              options={deliveryTimeOptions}
              isSearchable={false}
              isClearable={false}
              forceCaret
              label=""
              onChange={(option) => {
                if (option?.value) {
                  setDeliveryTime(option.value);
                  form.setValue('deliveryTimePreset.presetType', option.value);

                  if (option.value === 'CUSTOM') {
                    form.setValue('deliveryTimePreset.timeWindow', null);
                    initCustomForm();
                  } else {
                    form.setValue('deliveryTimeCustom', null);
                    initPresetForm(option.value);
                  }
                }
              }}
            />
            {presetForm}
          </PresetFormContainer>
          {presetMessage}
        </DeliveryTimeFormContainer>
        {customForm}
      </DeliveryTimeSectionContainer>
    </FlexColumn>
  );
};

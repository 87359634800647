import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Maybe } from 'graphql/jsutils/Maybe';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { UserLocale } from '@lgg/isomorphic/i18n/locale-utils';
import {
  Contact,
  ContactInteractionTranscriptionPhrase,
  User,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { getEmotionsResolver } from '@lgg/isomorphic/utils/emotion-resolver';
import { ContactStageIcon } from 'src/components/domain/contacts/contact-stage-icon';
import { PopoverV2 } from 'src/components/general/display/popover';
import { LggUserAvatar } from 'src/components/general/display/user-avatar';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { formatAudioDuration } from 'src/components/pages/conversations/components/contact-interactions/items/helpers';
import { BaseBadge } from 'src/components/pages/conversations/components/contact-interactions/items/phone-call-analysis/components/base-badge';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';

const CallTranscriptionPhraseContainer = styled(FlexRow)`
  border-radius: 6px;
  cursor: default;
  margin-bottom: 5px;
  padding: 10px;

  &:hover {
    background: ${({ theme }) => theme.colors.alabaster};
  }
`;

const PhraseDetailsContainer = styled(FlexColumn)`
  width: calc(100% - 38px);
`;

const PhraseHeader = styled(FlexRow)`
  align-items: center;
  margin-bottom: 5px;
  max-height: 15px;
`;

const PhraseTranscription = styled.p`
  color: ${({ theme }) => theme.colors.flint};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  text-align: left;
`;

const SpeakerLabel = styled.p`
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  line-height: 15px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PhraseSpeakerIcon = styled(Icon)`
  background: ${({ theme }) => theme.colors.alabaster};
  border-radius: 50%;
  border: 1px solid #e6ebf0;
  padding: 6px;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const PhraseStartTime = styled.span`
  color: ${({ theme }) => theme.colors.steel};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 14px;
  margin-right: 5px;
`;

const PraseDotSeparator = styled.span`
  line-height: 15px;
  margin: 0 5px;
`;

const SpeakerAvatarContainer = styled.div`
  margin-right: 10px;
`;

const PhraseSentimentBadgeLabel = styled.span`
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 9px;
  line-height: 10px;
`;

const PhraseSentimentBadge = styled(BaseBadge)<{ $type: 'positive' | 'negative' }>`
  margin: 0;
  max-height: 18px;
  padding: 3px 4px;

  .badge-icon {
    margin-right: 2px;

    svg {
      height: 12px;
      width: 12px;
    }
  }

  ${({ $type, theme }) =>
    $type === 'positive' &&
    `
    background-color: rgba(213, 246, 228, 0.25);
    border: solid 1px rgba(192, 242, 215, 0.5);

    .badge-icon {
      svg path {
        fill: #7ec69f;
      }
    }

    ${PhraseSentimentBadgeLabel} {
      color: ${theme.colors.secondaryMintDark};
    }
  `};

  ${({ $type, theme }) =>
    $type === 'negative' &&
    `
    background-color: rgba(254, 223, 225, 0.25);
    border: solid 1px rgba(254, 208, 210, 0.5);

    .badge-icon {
      svg path {
        fill: ${theme.colors.secondaryCoralDark};
      }
    }

    ${PhraseSentimentBadgeLabel} {
      color: ${theme.colors.secondaryCoralDark};
    }
  `};
`;

const EmotionsBadge = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 12px;
  border: solid 1px rgba(152, 169, 188, 0.26);
  cursor: pointer;
  display: flex;
  margin-left: 5px;
  max-height: 18px;
  padding: 2px 3px;
`;

const ExtraEmojisCount = styled.span`
  color: ${({ theme }) => theme.colors.steel};
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 9px;
  letter-spacing: 0.09px;
  line-height: 10px;
  margin-left: 3px;
  margin-right: 2px;
  width: 9px;
`;

const EmojiContainer = styled.span`
  font-size: 12px;
  letter-spacing: -1px;
  line-height: 14px;
  white-space: nowrap;
`;

const StyledContactStageIcon = styled(ContactStageIcon)`
  margin-right: 0;
`;

const EmotionListItemContainer = styled(FlexRow)`
  align-items: center;
  height: 30px;
  padding: 3px 10px 5px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  ${up('md')} {
    height: unset;
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 7px;
    }
  }
`;

const EmotionListItemEmoji = styled.span`
  margin-right: 12px;

  ${up('md')} {
    font-size: 18px;
    line-height: 22px;
    margin-right: 10px;
  }
`;

const EmotionListItemDescription = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  line-height: 17px;

  ${up('md')} {
    font-size: 13px;
    line-height: 15px;
  }
}

`;

const EmotionsList = styled.div`
  padding: 15px 10px 20px;

  ${up('md')} {
    min-width: 181px;
    padding: 15px;
  }
`;

const EmotionListItem = ({
  icon,
  description,
  ...rest
}: {
  icon: string;
  description: string;
}) => {
  return (
    <EmotionListItemContainer {...rest}>
      <EmotionListItemEmoji>{icon}</EmotionListItemEmoji>
      <EmotionListItemDescription>{description}</EmotionListItemDescription>
    </EmotionListItemContainer>
  );
};

type CallTranscriptionPhraseProps = {
  details: ContactInteractionTranscriptionPhrase & {
    user: Maybe<User>;
    contact: Maybe<Contact>;
  };
  onClick: VoidFunction;
};

export const CallTranscriptionPhrase = ({
  details,
  onClick,
}: CallTranscriptionPhraseProps) => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const emotionsDetailsVisibilityHandler = useVisible();
  const {
    i18n: { language },
  } = useTranslation();
  const locale = (language.startsWith('en') ? 'en' : 'es') as unknown as UserLocale;
  const { getEmotionEmoji, getEmotionLabel } = getEmotionsResolver(locale);
  const { t } = useTranslation(['common']);
  const { speakerType, phrase, sentiment, emotions, id, start, user, contact } = details;
  const { avatar, label } = useMemo(() => {
    try {
      return match(speakerType)
        .with('AGENT', () => {
          return user
            ? {
                avatar: <LggUserAvatar user={user} data-lgg-id="agent-speaker-icon" />,
                label: user.fullName,
              }
            : {
                avatar: (
                  <PhraseSpeakerIcon
                    type="agentSpeaker"
                    lggTestId="unknown-agent-speaker-icon"
                  />
                ),
                label: t('common:agent'),
              };
        })
        .with('CONTACT', () => {
          return contact
            ? {
                avatar: (
                  <StyledContactStageIcon
                    slug={contact.stage.slug}
                    name={contact.status.name}
                    data-lgg-id="contact-speaker-icon"
                  />
                ),
                label: contact.label,
              }
            : {
                avatar: (
                  <PhraseSpeakerIcon
                    type="unknownSpeaker"
                    lggTestId="unknown-contact-speaker-icon"
                  />
                ),
                label: t('common:contact'),
              };
        })
        .with('IVR', () => {
          return {
            avatar: <PhraseSpeakerIcon type="ivrSpeaker" lggTestId="ivr-speaker-icon" />,
            label: t('common:ivr'),
          };
        })
        .with('UNKNOWN', () => {
          return {
            avatar: (
              <PhraseSpeakerIcon type="unknownSpeaker" lggTestId="unknown-speaker-icon" />
            ),
            label: t('common:unknown'),
          };
        })
        .exhaustive();
    } catch (_error) {
      // Fallback
      return {
        avatar: (
          <PhraseSpeakerIcon type="unknownSpeaker" lggTestId="unknown-speaker-icon" />
        ),
        label: t('common:unknown'),
      };
    }
  }, [contact, speakerType, t, user]);

  const sentimentComponents = useMemo(() => {
    if (!sentiment) return null;

    // All cases handled; no fallback
    // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
    const sentimentBadge = match(sentiment)
      .with('POSITIVE', () => (
        <PhraseSentimentBadge
          icon="happyEmoji"
          badgeContent={
            <PhraseSentimentBadgeLabel>{t('common:positive')}</PhraseSentimentBadgeLabel>
          }
          data-lgg-id="positive-sentiment-badge"
          $type="positive"
        />
      ))
      .with('NEGATIVE', () => (
        <PhraseSentimentBadge
          icon="sadEmoji"
          badgeContent={
            <PhraseSentimentBadgeLabel>{t('common:negative')}</PhraseSentimentBadgeLabel>
          }
          data-lgg-id="negative-sentiment-badge"
          $type="negative"
        />
      ))
      .exhaustive();

    const resolvedEmotions = emotions
      ? emotions.map((emotion) => ({
          emoji: getEmotionEmoji(emotion),
          label: getEmotionLabel(emotion),
        }))
      : [];
    const extraEmojisCount =
      resolvedEmotions.length > 2 ? resolvedEmotions.length - 2 : 0;

    const emotionsBadge = (
      <EmotionsBadge
        onClick={emotionsDetailsVisibilityHandler.show}
        data-lgg-id="phrase-emotions-badge"
      >
        <EmojiContainer>
          {resolvedEmotions
            .slice(0, 2)
            .map((resolvedEmotion) => resolvedEmotion.emoji)
            .join('')}
        </EmojiContainer>
        {extraEmojisCount ? (
          <ExtraEmojisCount>{`+${extraEmojisCount}`}</ExtraEmojisCount>
        ) : null}
      </EmotionsBadge>
    );

    const emotionList = (
      <EmotionsList data-lgg-id="phrase-emoji-list">
        {resolvedEmotions.map(({ emoji, label }, index) => (
          <EmotionListItem
            key={label}
            icon={emoji}
            description={label}
            data-lgg-id={`emoji-list-item-${index}`}
          />
        ))}
      </EmotionsList>
    );

    return (
      <>
        {sentimentBadge}
        {breakpointUpMd ? (
          <PopoverV2
            visible={emotionsDetailsVisibilityHandler.visible}
            getPopupContainer={(a) => a}
            onVisibleChange={(visible) =>
              emotionsDetailsVisibilityHandler.setVisible(visible)
            }
            hideArrow
            placement="bottomRight"
            align={{
              offset: [0, -6],
            }}
            content={emotionList}
            trigger={['click']}
          >
            {emotionsBadge}
          </PopoverV2>
        ) : (
          <>
            {emotionsBadge}
            <BottomDrawer
              title="Emotions"
              visible={emotionsDetailsVisibilityHandler.visible}
              onClose={emotionsDetailsVisibilityHandler.close}
            >
              {emotionList}
            </BottomDrawer>
          </>
        )}
      </>
    );
  }, [
    breakpointUpMd,
    emotions,
    emotionsDetailsVisibilityHandler,
    getEmotionEmoji,
    getEmotionLabel,
    sentiment,
    t,
  ]);

  const phraseStartTime = useMemo(() => formatAudioDuration(start), [start]);

  return (
    <CallTranscriptionPhraseContainer data-lgg-id={`transcription-phrase-${id}`}>
      <SpeakerAvatarContainer>{avatar}</SpeakerAvatarContainer>
      <PhraseDetailsContainer>
        <PhraseHeader>
          <SpeakerLabel data-lgg-id="phrase-speaker-label">{label}</SpeakerLabel>
          <PraseDotSeparator>·</PraseDotSeparator>
          <PhraseStartTime data-lgg-id="phrase-start-time" onClick={onClick}>
            {phraseStartTime}
          </PhraseStartTime>
          {sentimentComponents}
        </PhraseHeader>
        <PhraseTranscription data-lgg-id="phrase-transcription" onClick={onClick}>
          {phrase}
        </PhraseTranscription>
      </PhraseDetailsContainer>
    </CallTranscriptionPhraseContainer>
  );
};

import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { ColorPaletteItem } from '@lgg/isomorphic';
import { Schedule } from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { MobileCalendarViewContext } from 'src/components/pages/calendar/components/mobile/mobile-calendar-view-provider';
import {
  ScheduleEventDetails,
  TaskEventDetails,
} from 'src/components/pages/calendar/components/shared/event-details';
import {
  useEventDateFormatter,
  useHandleScheduleActions,
  useHandleTaskActions,
} from 'src/components/pages/calendar/components/shared/hooks';
import { CustomTitleBottomDrawer } from 'src/components/pages/conversations/components/drawer/bottom-drawer';
import { VisibilityHandler } from 'src/hooks/use-visible';
import { TaskEvent } from '../shared/shared';

const EventBottomDrawerHeader = styled(FlexRow)`
  align-items: center;
  width: 100%;

  .info-col {
    flex: 1;
    min-width: 0;
  }

  .event-title {
    color: ${({ theme }) => theme.colors.carbon};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -0.4px;
    line-height: 24px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .event-date {
    color: ${({ theme }) => theme.colors.flint};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 15px;
    text-align: left;
  }
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 20px;

  path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.porcelain};
  margin: 15px 0 10px;
`;

const EventBottomDrawerOptionContainer = styled(FlexRow)<{
  $iconColor?: ColorPaletteItem;
}>`
  align-items: center;
  cursor: pointer;
  padding: 0 20px 0;
  height: 38px;

  .option-icon svg {
    width: 18px;
    height: 18px;
    margin-right: 20px;

    path {
      fill: ${({ theme, $iconColor }) =>
        $iconColor ? theme.colors[$iconColor] : theme.colors.smalt};
    }
  }

  .option-label {
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: left;
    color: ${({ theme }) => theme.colors.flint};
  }

  & + & {
    margin-top: 10px;
  }

  &:last-child {
    margin-bottom: 20px;
  }
`;

const EventBottomDrawerOption = memo<{
  icon: string;
  label: string;
  onClick: VoidFunction;
  iconColor?: ColorPaletteItem;
  visibilityHandler: VisibilityHandler;
}>(({ icon, label, onClick, iconColor, visibilityHandler }) => {
  return (
    <EventBottomDrawerOptionContainer
      onClick={() => {
        onClick();
        visibilityHandler.close();
      }}
      $iconColor={iconColor}
    >
      <Icon className="option-icon" type={icon} />
      <span className="option-label">{label}</span>
    </EventBottomDrawerOptionContainer>
  );
});

export const EventBottomDrawer = (props: {
  visibilityHandler: VisibilityHandler;
  event: TaskEvent | Schedule;
}) => {
  const { visibilityHandler, event } = props;
  const eventDateFormatter = useEventDateFormatter();
  const scheduleActions = useHandleScheduleActions();
  const taskActions = useHandleTaskActions();
  const { onEventDeleted } = useContext(MobileCalendarViewContext);
  const { t } = useTranslation(['tasks', 'appointments']);

  return (
    <CustomTitleBottomDrawer
      visible={visibilityHandler.visible}
      onClose={visibilityHandler.close}
      title={
        <EventBottomDrawerHeader>
          <FlexColumn className="info-col">
            <span className="event-title">{event.title}</span>
            <span className="event-date">
              {
                // All cases handled for fixed values
                // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
                match(event)
                  .with({ __typename: 'Schedule' }, (schedule) =>
                    eventDateFormatter.appointment(schedule),
                  )
                  .with({ __typename: 'Task' }, (task) => eventDateFormatter.task(task))
                  .exhaustive()
              }
            </span>
          </FlexColumn>
          <CloseIcon type="close" onClick={visibilityHandler.close} />
        </EventBottomDrawerHeader>
      }
    >
      {
        // All cases handled; no fallback
        // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
        match(event)
          .with({ __typename: 'Schedule' }, (schedule) => (
            <>
              <ScheduleEventDetails schedule={schedule} />
              <Divider />
              <EventBottomDrawerOption
                icon="edit"
                label={t('appointments:actions.edit')}
                visibilityHandler={visibilityHandler}
                onClick={() => scheduleActions.handleEdit(schedule)}
              />
              <EventBottomDrawerOption
                icon="deleteBin"
                label={t('appointments:actions.delete')}
                iconColor="secondaryCoral"
                visibilityHandler={visibilityHandler}
                onClick={() =>
                  scheduleActions.handleDelete(schedule, () => {
                    onEventDeleted(schedule);
                  })
                }
              />
            </>
          ))
          .with({ __typename: 'Task' }, (task) => (
            <>
              <TaskEventDetails task={task} />
              <Divider />
              <EventBottomDrawerOption
                icon="edit"
                label={t('tasks:actions.edit')}
                visibilityHandler={visibilityHandler}
                onClick={() => taskActions.handleEdit(task)}
              />
              <EventBottomDrawerOption
                icon="deleteBin"
                label={t('tasks:actions.delete')}
                iconColor="secondaryCoral"
                visibilityHandler={visibilityHandler}
                onClick={() =>
                  taskActions.handleDelete(task, () => {
                    onEventDeleted(task);
                  })
                }
              />
            </>
          ))
          .exhaustive()
      }
    </CustomTitleBottomDrawer>
  );
};

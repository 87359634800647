import { match, P } from 'ts-pattern';
import { BroadcastStatus } from '@lgg/isomorphic/types/__generated__/graphql';

export const isBroadcastInProgress = (status: BroadcastStatus) =>
  match(status)
    .with(P.union('IN_PROGRESS_PREPARING', 'IN_PROGRESS_SENDING'), () => true)
    .otherwise(() => false);

export const canBroadcastBeEdited = (status?: BroadcastStatus) =>
  match(status)
    .with(P.union('DRAFT', 'SCHEDULED'), () => true)
    .otherwise(() => false);

import React, { memo } from 'react';
import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';

const StyledRadio = styled.input`
  cursor: pointer;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
`;

const StyledIcon = styled(Icon)`
  opacity: 0;
  transition: opacity 150ms;

  svg path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const FakeRadio = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 205px;
  border: solid 1px #98a9bc66;
  display: flex;
  height: 20px;
  justify-content: center;
  transition: background-color 150ms, border 150ms;
  width: 20px;

  ${StyledRadio}:checked + & {
    background-color: ${({ theme }) => theme.colors.cosmo};
    border: solid 1px ${({ theme }) => theme.colors.cosmo};

    > ${StyledIcon} {
      opacity: 1;
    }
  }
`;

const StyledLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  display: flex;
  margin-bottom: 0px !important;
  position: relative;
`;

const Text = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.31;
  margin-left: 10px;
  text-align: left;
`;

type RadioProps = {
  checked: boolean;
  name: string;
  onChange: ValueChanged<string>;
  text: string;
  value: string;
  testId?: string;
};

export const Radio = memo<RadioProps>(
  ({ text, testId, checked, name, value, onChange, ...props }) => {
    return (
      <StyledLabel {...props}>
        <StyledRadio
          type="radio"
          checked={checked}
          name={name}
          data-lgg-id={testId ?? `radio-${value}`}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        <FakeRadio className="check-mark-container">
          <StyledIcon type="checkSave" />
        </FakeRadio>
        <Text>{text}</Text>
      </StyledLabel>
    );
  },
);

import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { getDatesDifferenceInUnits } from '@lgg/isomorphic/i18n/date-utils';
import { ContactInteractionChannelSlug } from '@lgg/isomorphic/types/__generated__/graphql';
import { FlexRow } from 'src/components/layout/flex-row';
import { useFormatDate } from 'src/hooks/use-format-date';

const ReplyWindowBarContainer = styled(FlexRow)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryMint10};
  border-top: 1px solid ${({ theme }) => theme.colors.secondaryMint60};
  color: ${({ theme }) => theme.colors.secondaryMintDark};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 11px;
  height: 25px;
  justify-content: center;
  line-height: 13px;
  padding: 0 20px;
  white-space: pre-wrap;

  strong {
    font-family: ${({ theme }) => theme.font.medium};
  }
`;

export const ReplyWindowBar = ({
  expirationDate,
  type,
}: {
  expirationDate?: Date;
  type: ContactInteractionChannelSlug;
}) => {
  const { formatDuration } = useFormatDate();

  const windowAvailabilityMessage = useMemo(() => {
    const duration = formatDuration(
      getDatesDifferenceInUnits(new Date(), expirationDate),
    );

    return match(type)
      .with('WHATSAPP', () => (
        <Trans
          i18nKey="conversations:messageInput.modes.reply.messages.whatsapp.messageWindowAvailability"
          components={{ strong: <strong /> }}
          values={{ timeAvailability: duration }}
        />
      ))
      .with('INSTAGRAM', () => (
        <Trans
          i18nKey="conversations:messageInput.modes.reply.messages.instagram.messageWindowAvailability"
          components={{ strong: <strong /> }}
          values={{ timeAvailability: duration }}
        />
      ))
      .with('FACEBOOK_MESSENGER', () => (
        <Trans
          i18nKey="conversations:messageInput.modes.reply.messages.facebook.messageWindowAvailability"
          components={{ strong: <strong /> }}
          values={{ timeAvailability: duration }}
        />
      ))
      .otherwise(() => null);
  }, [expirationDate, formatDuration, type]);

  return windowAvailabilityMessage ? (
    <ReplyWindowBarContainer data-lgg-id="message-window-expiration-message">
      {windowAvailabilityMessage}
    </ReplyWindowBarContainer>
  ) : null;
};

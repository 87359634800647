import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useServiceSelector } from 'react-service-locator';
import { groupBy } from 'lodash';
import { match } from 'ts-pattern';
import { Maybe, Query, Schedule } from '@lgg/isomorphic/types/__generated__/graphql';
import {
  useCalendarFilters,
  useGetCalendarEvents,
} from 'src/components/pages/calendar/components/shared/hooks';
import { TaskEvent } from 'src/components/pages/calendar/components/shared/shared';
import { useRefreshAppointment } from 'src/hooks/gql/use-refresh-appointment';
import { useRefreshTask } from 'src/hooks/gql/use-refresh-task';
import { UseRefreshProps } from 'src/hooks/gql/use-refresh.shared';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';
import { CalendarService } from 'src/services/calendar.service';

export const dateFormat = 'MM/DD/YY';

export const useMobileCalendarView = () => {
  const [getCalendarEvents] = useGetCalendarEvents();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [firstItemIndex, setFirstItemIndex] = React.useState<number>(-1);
  const [initialTopMostItemIndex, setInitialTopMostItemIndex] =
    React.useState<number>(-1);
  const [loadingMore, setLoadingMore] = React.useState<'BOTTOM' | 'TOP' | false>(false);
  const { formatDate } = useFormatDate();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const { filters, computeFiltersVariables } = useCalendarFilters();
  const [events, setEvents] = React.useState<{
    [date: string]: (Schedule | TaskEvent)[];
  }>({});
  const [selectedDay] = useServiceSelector(
    CalendarService,
    (s) => [s.state.selectedDay] as const,
  );
  const today = useMemo(() => formatDate(new Date(), dateFormat), [formatDate]);
  const [dates, setDates] = useState<string[]>([]);
  const {
    addMonths,
    eachDayOfInterval,
    endOfDay,
    endOfMonth,
    lastDayOfMonth,
    startOfDay,
    startOfMonth,
    subMonths,
  } = useDateHelpers();

  const groups = useMemo(
    () =>
      groupBy(dates, (date) => {
        const [month, , year] = date.split('/');

        return `${month}/${year}`;
      }),
    [dates],
  );

  const handleOnFetchCompleted = useCallback(
    (data: Pick<Query, 'calendarEvents'> | undefined) => {
      if (!data?.calendarEvents.nodes) {
        return;
      }

      // Get all events and group them by date
      const groupedEvents = groupBy(
        data.calendarEvents.nodes as (Schedule | TaskEvent)[],
        (event) => {
          if (event.__typename === 'Schedule') {
            return formatDate(event.startAt, dateFormat);
          } else {
            return formatDate(event.dueAt, dateFormat);
          }
        },
      );

      // Merge new events with previous ones
      setEvents((prevEvents) => ({
        ...prevEvents,
        ...groupedEvents,
      }));
    },
    [formatDate],
  );

  const handleLoadMoreDates = useCallback(
    async (direction: 'TOP' | 'BOTTOM') => {
      setLoadingMore(direction);
      // All cases handled for fixed values
      // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
      const newMonth = match(direction)
        .with('TOP', () => {
          return subMonths(new Date(dates[0]), 1);
        })
        .with('BOTTOM', () => {
          return addMonths(new Date(dates[dates.length - 1]), 1);
        })
        .exhaustive();

      const start = startOfDay(startOfMonth(newMonth));
      const end = endOfDay(lastDayOfMonth(newMonth));

      const { data } = await getCalendarEvents({
        variables: computeFiltersVariables({ start, end, filters }),
      });

      void handleOnFetchCompleted(data);

      const newDates = eachDayOfInterval({
        start: startOfMonth(start),
        end: endOfMonth(end),
      }).map((date) => {
        return formatDate(date, dateFormat);
      });

      setDates((dates) =>
        direction === 'TOP' ? [...newDates, ...dates] : [...dates, ...newDates],
      );

      if (direction === 'TOP') {
        setFirstItemIndex((firstItemIndex) => firstItemIndex - newDates.length);
      }

      setLoadingMore(false);
    },
    [
      addMonths,
      computeFiltersVariables,
      dates,
      eachDayOfInterval,
      endOfDay,
      endOfMonth,
      filters,
      formatDate,
      getCalendarEvents,
      handleOnFetchCompleted,
      lastDayOfMonth,
      startOfDay,
      startOfMonth,
      subMonths,
    ],
  );

  const groupCounts = useMemo(
    () => Object.keys(groups).map((key) => groups[key].length),
    [groups],
  );

  const groupIndexes = useMemo(() => Object.keys(groups), [groups]);

  const initialFetch = useCallback(
    async (input: { initialDate: Date }) => {
      setLoading(true);
      const { initialDate } = input;
      const prevMonth = subMonths(initialDate, 1);
      const nextMonth = addMonths(initialDate, 1);

      const params: { start: Date; end: Date }[] = [];

      for (const month of [prevMonth, initialDate, nextMonth]) {
        const start = startOfDay(startOfMonth(month));
        const end = endOfDay(lastDayOfMonth(month));

        params.push({ start, end });
      }

      await Promise.all(
        params.map(async ({ start, end }) => {
          const { data } = await getCalendarEvents({
            variables: computeFiltersVariables({ start, end, filters }),
          });

          void handleOnFetchCompleted(data);
        }),
      );

      const dates = eachDayOfInterval({
        start: startOfMonth(prevMonth),
        end: endOfMonth(nextMonth),
      }).map((date) => {
        return formatDate(date, dateFormat);
      });

      setDates(dates);

      const dateIndex = dates.findIndex(
        (date) => date === formatDate(initialDate, dateFormat),
      );

      setInitialTopMostItemIndex(dateIndex);
      setFirstItemIndex(Math.round(Number.MAX_SAFE_INTEGER / 2) - dateIndex);
      setLoading(false);
    },
    [
      addMonths,
      computeFiltersVariables,
      eachDayOfInterval,
      endOfDay,
      endOfMonth,
      filters,
      formatDate,
      getCalendarEvents,
      handleOnFetchCompleted,
      lastDayOfMonth,
      startOfDay,
      startOfMonth,
      subMonths,
    ],
  );

  useEffect(() => {
    if (!selectedDay) {
      return;
    }

    void initialFetch({ initialDate: selectedDay });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    void initialFetch({ initialDate: currentMonth });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefreshEvents = useCallback(
    (event: Maybe<TaskEvent | Schedule>) => {
      setLoadingMore(false);

      if (!event) {
        return;
      }

      setEvents((prevEvents) => {
        const eventDate =
          event.__typename === 'Schedule'
            ? formatDate(event.startAt, dateFormat)
            : formatDate(event.dueAt, dateFormat);

        const eventsOnDate = prevEvents[eventDate] || [];
        const existingEvent = eventsOnDate.some((e) => e.id === event.id);

        return {
          ...prevEvents,
          [eventDate]: existingEvent
            ? eventsOnDate.map((e) => {
                if (e.id === event.id) {
                  return event;
                }

                return e;
              })
            : [...eventsOnDate, event],
        };
      });
    },
    [formatDate],
  );

  const onRefreshEventHandler = useMemo<UseRefreshProps>(() => {
    return {
      onRefresh: async (params) => {
        const { id } = params;

        setLoadingMore('BOTTOM');
        const { data } = await getCalendarEvents({
          variables: computeFiltersVariables({ scheduleId: id, filters }),
        });

        const event = data?.calendarEvents.nodes[0] as Maybe<Schedule | TaskEvent>;

        handleRefreshEvents(event);
      },
    };
  }, [computeFiltersVariables, filters, getCalendarEvents, handleRefreshEvents]);

  useRefreshAppointment(onRefreshEventHandler);
  useRefreshTask(onRefreshEventHandler);

  const onEventDeleted = useCallback(
    (event: Schedule | TaskEvent) => {
      setEvents((prevEvents) => {
        const eventDate =
          event.__typename === 'Schedule'
            ? formatDate(event.startAt, dateFormat)
            : formatDate(event.dueAt, dateFormat);

        const eventsOnDate = prevEvents[eventDate] || [];

        return {
          ...prevEvents,
          [eventDate]: eventsOnDate.filter((e) => e.id !== event.id),
        };
      });
    },
    [formatDate],
  );

  return {
    loading,
    loadingMore,
    firstItemIndex,
    dates,
    groupCounts,
    events,
    today,
    groupIndexes,
    onEventDeleted,
    handleLoadMoreDates,
    currentMonth,
    initialTopMostItemIndex,
    initialFetch,
    setCurrentMonth,
  };
};

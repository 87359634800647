import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import 'antd/es/calendar/style';
import { Dropdown } from 'antd';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import * as zod from 'zod';
import { z } from 'zod';
import {
  InputDateType,
  InputTimeUnit,
  matchAdvancedDateInputTypeExhaustive,
  matchInputDateTypeExhaustive,
  matchInputDateUnitExhaustive,
} from '@lgg/isomorphic/utils/match-advanced-date-input';
import { DateFilter } from 'src/components/filters/react-filters';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import {
  CUSTOM_DATE_PICKER_LABEL_DATE_FORMAT,
  CustomDatePickerInputPlaceholder,
  CustomDatePickerPopoverFooter,
  CustomDatePickerSelect,
  CustomPickerDatePreview,
  InputContainer,
} from 'src/components/general/inputs/custom-date-pickers/shared';
import { GenericInput } from 'src/components/general/inputs/generic-input';
import { SelectOption } from 'src/components/general/inputs/select/select';
import { TextInput } from 'src/components/general/inputs/text-input';
import { FlexColumn } from 'src/components/layout/flex-column';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useVisible } from 'src/hooks/use-visible';

const StyledForm = styled.form`
  width: 100%;

  ${up('md')} {
    width: 389px;
  }
`;

const BodyContainer = styled(FlexColumn)`
  min-height: 165px;
  padding: 20px;
  width: 100%;

  ${up('md')} {
    min-height: unset;
    padding: 15px 10px;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 38px;

  ${up('md')} {
    height: 34px;
    max-width: 56px;
  }
`;

const FormContent = styled(FlexColumn)`
  position: relative;
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const CustomDatePickerDateFilter = styled(DateFilter)`
  .ant-picker {
    height: 38px;
    padding: 10px 8px 9px 10px;

    ${up('md')} {
      height: 34px;
      max-width: 120px;
    }
  }

  .ant-picker-suffix {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

type AdvancedDateFilterTypeWithUnit =
  | '_isLastXDays'
  | '_isLastXWeeks'
  | '_isLastXMonths'
  | '_isLastXYears'
  | '_isOverXDays'
  | '_isOverXWeeks'
  | '_isOverXMonths'
  | '_isOverXYears';

type AdvancedDateFilterBooleanType =
  | '_isToday'
  | '_isThisWeek'
  | '_isThisMonth'
  | '_isThisYear'
  | '_isYesterday'
  | '_isLastWeek'
  | '_isLastMonth'
  | '_isLastYear'
  | '_notSet';

export type AdvancedDateFilterType =
  | '_isBetween'
  | '_isExactDate'
  | AdvancedDateFilterBooleanType
  | AdvancedDateFilterTypeWithUnit;

const advancedDateFilterResolver: Record<AdvancedDateFilterType, InputDateType> = {
  _isToday: 'TODAY',
  _isThisWeek: 'THIS_WEEK',
  _isThisMonth: 'THIS_MONTH',
  _isThisYear: 'THIS_YEAR',
  _isYesterday: 'YESTERDAY',
  _isLastWeek: 'LAST_WEEK',
  _isLastMonth: 'LAST_MONTH',
  _isLastYear: 'LAST_YEAR',
  _notSet: 'NO_DATE',
  _isLastXDays: 'IN_THE_LAST',
  _isLastXWeeks: 'IN_THE_LAST',
  _isLastXMonths: 'IN_THE_LAST',
  _isLastXYears: 'IN_THE_LAST',
  _isOverXDays: 'OVER',
  _isOverXWeeks: 'OVER',
  _isOverXMonths: 'OVER',
  _isOverXYears: 'OVER',
  _isBetween: 'DATE_RANGE',
  _isExactDate: 'EXACT_DATE',
};

type AdvancedDatePickerValues = {
  type: AdvancedDateFilterType | null;
  unit: number | null;
  dateFrom: Date | null;
  dateTo: Date | null;
};

const innerFormDefaultValues = {
  type: null,
  timeUnitValue: 1,
  timeUnit: 'DAY' as InputTimeUnit,
  startDate: null,
  endDate: null,
};

type Props = {
  onChange: (
    props:
      | {
          type: '_isExactDate';
          dateFrom: Date | null;
        }
      | {
          type: '_isBetween';
          dateFrom: Date | null;
          dateTo: Date | null;
        }
      | {
          type: AdvancedDateFilterTypeWithUnit;
          unit: number;
        }
      | {
          type: AdvancedDateFilterBooleanType;
        }
      | {
          type: null;
        },
  ) => void;
  values: AdvancedDatePickerValues;
  label: string;
  emptyValueDescriptionOverride?: string;
  resetInnerForm?: boolean;
  'data-lgg-id'?: string;
};

export const AdvancedDatePicker = ({
  onChange,
  values,
  label,
  resetInnerForm = false,
  emptyValueDescriptionOverride,
  'data-lgg-id': testId,
}: Props) => {
  const { type, dateTo, dateFrom, unit } = values;
  const { visible: isPanelVisible, close: closePanel, show: showPanel } = useVisible();
  const { t } = useTranslation(['common']);
  const breakpointUpMd = useBreakpoint(up('md'));
  const { formatDate } = useFormatDate();
  const {
    subDays,
    subMonths,
    subWeeks,
    subYears,
    parseISO,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear,
    endOfMonth,
    endOfWeek,
    endOfYear,
  } = useDateHelpers();

  const getDefaultUnitValue = useCallback(() => {
    return match(type)
      .with(P.union('_isLastXDays', '_isOverXDays'), () => 'DAY' as InputTimeUnit)
      .with(P.union('_isLastXWeeks', '_isOverXWeeks'), () => 'WEEK' as InputTimeUnit)
      .with(P.union('_isLastXMonths', '_isOverXMonths'), () => 'MONTH' as InputTimeUnit)
      .with(P.union('_isLastXYears', '_isOverXYears'), () => 'YEAR' as InputTimeUnit)
      .otherwise(() => null);
  }, [type]);

  const [pickerType, setPickerType] = useState<InputDateType | null>(
    type ? advancedDateFilterResolver[type] : null,
  );
  const [pickerTimeUnitValue, setPickerTimeUnitValue] = useState<number>(unit ?? 1);
  const [pickerTimeUnit, setPickerTimeUnit] = useState<InputTimeUnit>(
    getDefaultUnitValue() ?? 'DAY',
  );
  const [pickerStartDate, setPickerStartDate] = useState<Date | null>(dateFrom ?? null);
  const [pickerEndDate, setPickerEndDate] = useState<Date | null>(dateTo ?? null);

  const formatInputDate = useCallback(
    (date: Date) => formatDate(date, CUSTOM_DATE_PICKER_LABEL_DATE_FORMAT, false),
    [formatDate],
  );

  const schema = useMemo(() => {
    return zod
      .object({
        type: zod
          .union([
            zod.literal('TODAY'),
            zod.literal('THIS_WEEK'),
            zod.literal('THIS_MONTH'),
            zod.literal('THIS_YEAR'),
            zod.literal('YESTERDAY'),
            zod.literal('LAST_WEEK'),
            zod.literal('LAST_MONTH'),
            zod.literal('LAST_YEAR'),
            zod.literal('NO_DATE'),
            zod.literal('IN_THE_LAST'),
            zod.literal('OVER'),
            zod.literal('DATE_RANGE'),
            zod.literal('EXACT_DATE'),
          ])
          .nullish(),
        timeUnitValue: zod.number().positive(),
        timeUnit: z.union([
          z.literal('DAY'),
          z.literal('WEEK'),
          z.literal('MONTH'),
          z.literal('YEAR'),
        ]),
        startDate: zod.string().nullish(),
        endDate: zod.string().nullish(),
      })
      .required();
  }, []);

  type FormValues = zod.infer<typeof schema>;

  const formInitialValues = useMemo(() => {
    return {
      type: type ? advancedDateFilterResolver[type] : null,
      timeUnitValue: unit ?? 1,
      timeUnit: getDefaultUnitValue() ?? 'DAY',
      startDate: dateFrom ? dateFrom.toISOString() : null,
      endDate: dateTo ? dateTo.toISOString() : null,
    };
  }, [dateFrom, dateTo, getDefaultUnitValue, type, unit]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentDate = useMemo(() => new Date(), []);

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: innerFormDefaultValues,
    values: formInitialValues,
    shouldFocusError: true,
  });

  const [innerFormValues, setInnerFormValues] = useState<FormValues>(formInitialValues);

  useEffect(() => {
    if (resetInnerForm) {
      form.reset();
      setPickerType(type ? advancedDateFilterResolver[type] : null);
    }
  }, [form, resetInnerForm, type]);

  const getParsedFormValues = useCallback(() => {
    const { startDate, endDate, timeUnit, timeUnitValue, type } = innerFormValues;

    return {
      formType: type,
      formStartDate: startDate ? parseISO(startDate) : null,
      formEndDate: endDate ? parseISO(endDate) : null,
      formTimeUnit: timeUnit,
      formTimeUnitValue: timeUnitValue,
    };
  }, [innerFormValues, parseISO]);

  useEffect(() => {
    const innerFormSubscription = form.watch((values) => {
      setInnerFormValues(values as FormValues);
    });

    return () => innerFormSubscription.unsubscribe();
  }, [form]);

  const resultPreview = useMemo(() => {
    const { formType, formTimeUnit, formTimeUnitValue } = getParsedFormValues();

    if (!formType) return null;

    const getRangePreview = (startDate: Date, endDate: Date) =>
      `${formatInputDate(startDate)} - ${formatInputDate(endDate)}`;

    const previewText = matchInputDateTypeExhaustive(formType, {
      dateRange: () => null,
      exactDate: () => null,
      inTheLast: () => {
        if (!formTimeUnit || !formTimeUnitValue) return null;

        // All cases handled for fixed values
        // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
        return match(formTimeUnit)
          .with('DAY', () => {
            return getRangePreview(
              subDays(currentDate, (formTimeUnitValue ?? 1) - 1),
              currentDate,
            );
          })
          .with('WEEK', () => {
            return getRangePreview(
              startOfWeek(subWeeks(currentDate, formTimeUnitValue ?? 1)),
              startOfDay(endOfWeek(currentDate)),
            );
          })
          .with('MONTH', () => {
            return getRangePreview(
              startOfMonth(subMonths(currentDate, formTimeUnitValue ?? 1)),
              startOfDay(endOfMonth(subMonths(currentDate, 1))),
            );
          })
          .with('YEAR', () => {
            return getRangePreview(
              startOfYear(subYears(currentDate, formTimeUnitValue ?? 1)),
              startOfDay(endOfYear(subYears(currentDate, 1))),
            );
          })
          .exhaustive();
      },
      over: () => {
        if (!formTimeUnit || !formTimeUnitValue) return null;

        // All cases handled; no fallback
        // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
        const refDate = match(formTimeUnit)
          .with('DAY', () => {
            return subDays(currentDate, formTimeUnitValue ?? 1);
          })
          .with('WEEK', () => {
            return subWeeks(currentDate, formTimeUnitValue ?? 1);
          })
          .with('MONTH', () => {
            return subMonths(currentDate, formTimeUnitValue ?? 1);
          })
          .with('YEAR', () => {
            return subYears(currentDate, formTimeUnitValue ?? 1);
          })
          .exhaustive();

        return t('common:advancedDateFilter.preview.over', {
          value: formatInputDate(refDate),
        });
      },
      noDate: () => null,
      lastWeek: () => {
        const refDate = startOfWeek(subWeeks(currentDate, 1));

        return getRangePreview(refDate, startOfDay(endOfWeek(refDate)));
      },
      lastMonth: () => {
        const refDate = startOfMonth(subMonths(currentDate, 1));

        return getRangePreview(refDate, startOfDay(endOfMonth(refDate)));
      },
      lastYear: () => {
        const refDate = startOfYear(subYears(currentDate, 1));

        return getRangePreview(refDate, startOfDay(endOfYear(refDate)));
      },
      today: () => {
        return null;
      },
      yesterday: () => {
        return formatInputDate(subDays(currentDate, 1));
      },
      thisWeek: () => {
        return getRangePreview(startOfWeek(currentDate), currentDate);
      },
      thisMonth: () => {
        return getRangePreview(startOfMonth(currentDate), currentDate);
      },
      thisYear: () => {
        return getRangePreview(startOfYear(currentDate), currentDate);
      },
    });

    return previewText ? (
      <CustomPickerDatePreview
        data-lgg-id="advanced-date-picker-preview"
        previewText={previewText}
      />
    ) : null;
  }, [
    getParsedFormValues,
    formatInputDate,
    subDays,
    currentDate,
    startOfWeek,
    subWeeks,
    startOfDay,
    endOfWeek,
    startOfMonth,
    subMonths,
    endOfMonth,
    startOfYear,
    subYears,
    endOfYear,
    t,
  ]);

  const inputValue = useMemo(() => {
    if (!pickerType) {
      return (
        <CustomDatePickerInputPlaceholder>
          {t('common:advancedDateFilter.placeholder')}
        </CustomDatePickerInputPlaceholder>
      );
    }

    return matchInputDateTypeExhaustive<React.ReactElement>(pickerType, {
      dateRange: () =>
        pickerStartDate && pickerEndDate ? (
          <Trans
            i18nKey="common:advancedDateFilter.values.dateRange"
            values={{
              startDate: formatInputDate(pickerStartDate),
              endDate: formatInputDate(pickerEndDate),
            }}
          />
        ) : (
          <></>
        ),
      inTheLast: () => {
        const unitTranslation = matchInputDateUnitExhaustive(pickerTimeUnit, {
          day: () => 'common:timeUnits.day' as const,
          week: () => 'common:timeUnits.week' as const,
          month: () => 'common:timeUnits.month' as const,
          year: () => 'common:timeUnits.year' as const,
        });

        return (
          <Trans
            i18nKey="common:advancedDateFilter.values.inTheLast"
            values={{
              value: t(unitTranslation, { count: pickerTimeUnitValue }),
            }}
          />
        );
      },
      over: () => {
        const unitTranslation = matchInputDateUnitExhaustive(pickerTimeUnit, {
          day: () => 'common:agoTimeUnits.day' as const,
          week: () => 'common:agoTimeUnits.week' as const,
          month: () => 'common:agoTimeUnits.month' as const,
          year: () => 'common:agoTimeUnits.year' as const,
        });

        return (
          <Trans
            i18nKey="common:advancedDateFilter.values.over"
            values={{ value: t(unitTranslation, { count: pickerTimeUnitValue }) }}
          />
        );
      },
      noDate: () =>
        emptyValueDescriptionOverride ? (
          <>{emptyValueDescriptionOverride}</>
        ) : (
          <Trans i18nKey="common:advancedDateFilter.values.noDate" />
        ),
      lastWeek: () => <Trans i18nKey="common:advancedDateFilter.values.lastWeek" />,
      lastMonth: () => <Trans i18nKey="common:advancedDateFilter.values.lastMonth" />,
      lastYear: () => <Trans i18nKey="common:advancedDateFilter.values.lastYear" />,
      today: () => <Trans i18nKey="common:advancedDateFilter.values.today" />,
      yesterday: () => <Trans i18nKey="common:advancedDateFilter.values.yesterday" />,
      thisWeek: () => <Trans i18nKey="common:advancedDateFilter.values.thisWeek" />,
      thisMonth: () => <Trans i18nKey="common:advancedDateFilter.values.thisMonth" />,
      thisYear: () => <Trans i18nKey="common:advancedDateFilter.values.thisYear" />,
      exactDate: () => {
        return pickerStartDate ? (
          <Trans
            i18nKey="common:advancedDateFilter.values.exactDate"
            values={{
              value: formatInputDate(pickerStartDate),
            }}
          />
        ) : (
          <></>
        );
      },
    });
  }, [
    pickerType,
    t,
    pickerStartDate,
    pickerEndDate,
    formatInputDate,
    pickerTimeUnit,
    pickerTimeUnitValue,
    emptyValueDescriptionOverride,
  ]);

  const getCurrentFormValue = useCallback(() => {
    const { formType, formTimeUnit } = getParsedFormValues();

    if (!formType) return null;

    return matchInputDateTypeExhaustive<AdvancedDateFilterType>(formType, {
      dateRange: () => '_isBetween',
      inTheLast: () => {
        return matchInputDateUnitExhaustive<AdvancedDateFilterType>(formTimeUnit, {
          day: () => '_isLastXDays',
          week: () => '_isLastXWeeks',
          month: () => '_isLastXMonths',
          year: () => '_isLastXYears',
        });
      },
      over: () => {
        return matchInputDateUnitExhaustive<AdvancedDateFilterType>(formTimeUnit, {
          day: () => '_isOverXDays',
          week: () => '_isOverXWeeks',
          month: () => '_isOverXMonths',
          year: () => '_isOverXYears',
        });
      },
      noDate: () => '_notSet',
      lastWeek: () => '_isLastWeek',
      lastMonth: () => '_isLastMonth',
      lastYear: () => '_isLastYear',
      today: () => '_isToday',
      yesterday: () => '_isYesterday',
      thisWeek: () => '_isThisWeek',
      thisMonth: () => '_isThisMonth',
      thisYear: () => '_isThisYear',
      exactDate: () => '_isExactDate',
    });
  }, [getParsedFormValues]);

  const onSubmit = useCallback(() => {
    const parsedFormValues = getParsedFormValues();
    const { formEndDate, formStartDate, formTimeUnit, formTimeUnitValue, formType } =
      parsedFormValues;
    let hasErrors = false;

    matchAdvancedDateInputTypeExhaustive(getCurrentFormValue(), {
      exact: () => {
        if (!formStartDate) {
          hasErrors = true;
          form.setError('startDate', { type: 'required' });
          return;
        }

        setPickerType('EXACT_DATE');
        setPickerStartDate(formStartDate);
        onChange({
          type: '_isExactDate',
          dateFrom: formStartDate,
        });
      },
      range: () => {
        if (formStartDate && formEndDate) {
          if (formStartDate.getTime() > formEndDate.getTime()) {
            hasErrors = true;
            form.setError('endDate', { type: 'validate' });
          }
        } else {
          if (!formStartDate) {
            hasErrors = true;
            form.setError('startDate', { type: 'required' });
          }

          if (!formEndDate) {
            hasErrors = true;
            form.setError('endDate', { type: 'required' });
          }
        }

        if (hasErrors) {
          return;
        }

        setPickerType('DATE_RANGE');
        setPickerStartDate(formStartDate);
        setPickerEndDate(formEndDate);

        onChange({
          type: '_isBetween',
          dateFrom: formStartDate,
          dateTo: formEndDate,
        });
      },
      relativeWithUnit: (type) => {
        setPickerType(advancedDateFilterResolver[type]);
        setPickerTimeUnitValue(formTimeUnitValue);
        setPickerTimeUnit(formTimeUnit);

        onChange({
          type,
          unit: formTimeUnitValue,
        });
      },
      relative: (type) => {
        setPickerType(advancedDateFilterResolver[type]);
        onChange({ type });
      },
      empty: () => {
        setPickerType(null);
        onChange({ type: null });
      },
    });

    if (!hasErrors) {
      setPickerType(formType);
      closePanel();
      form.clearErrors();
    }
  }, [getParsedFormValues, getCurrentFormValue, onChange, form, closePanel]);

  const options: {
    label: string;
    value: InputDateType;
  }[] = useMemo(
    () => [
      { label: t('common:advancedDateFilter.options.inTheLast'), value: 'IN_THE_LAST' },
      { label: t('common:advancedDateFilter.options.over'), value: 'OVER' },
      {
        label:
          emptyValueDescriptionOverride ?? t('common:advancedDateFilter.options.noDate'),
        value: 'NO_DATE',
      },
      { label: t('common:advancedDateFilter.options.lastWeek'), value: 'LAST_WEEK' },
      { label: t('common:advancedDateFilter.options.lastMonth'), value: 'LAST_MONTH' },
      { label: t('common:advancedDateFilter.options.lastYear'), value: 'LAST_YEAR' },
      { label: t('common:advancedDateFilter.options.today'), value: 'TODAY' },
      { label: t('common:advancedDateFilter.options.yesterday'), value: 'YESTERDAY' },
      { label: t('common:advancedDateFilter.options.thisWeek'), value: 'THIS_WEEK' },
      { label: t('common:advancedDateFilter.options.thisMonth'), value: 'THIS_MONTH' },
      { label: t('common:advancedDateFilter.options.thisYear'), value: 'THIS_YEAR' },
      { label: t('common:advancedDateFilter.options.exactDate'), value: 'EXACT_DATE' },
      { label: t('common:advancedDateFilter.options.dateRange'), value: 'DATE_RANGE' },
    ],
    [emptyValueDescriptionOverride, t],
  );

  const timeUnitOptions: SelectOption<InputTimeUnit>[] = useMemo(() => {
    const isOverSelected = pickerType === 'OVER';
    const translationPayload = { count: pickerTimeUnitValue };

    return [
      {
        label: isOverSelected
          ? t('common:advancedDateFilter.agoUnitOptions.day', translationPayload)
          : t('common:advancedDateFilter.unitOptions.day', translationPayload),
        value: 'DAY' as InputTimeUnit,
      },
      {
        label: isOverSelected
          ? t('common:advancedDateFilter.agoUnitOptions.week', translationPayload)
          : t('common:advancedDateFilter.unitOptions.week', translationPayload),
        value: 'WEEK' as InputTimeUnit,
      },
      {
        label: isOverSelected
          ? t('common:advancedDateFilter.agoUnitOptions.month', translationPayload)
          : t('common:advancedDateFilter.unitOptions.month', translationPayload),
        value: 'MONTH' as InputTimeUnit,
      },
      {
        label: isOverSelected
          ? t('common:advancedDateFilter.agoUnitOptions.year', translationPayload)
          : t('common:advancedDateFilter.unitOptions.year', translationPayload),
        value: 'YEAR' as InputTimeUnit,
      },
    ];
  }, [pickerType, t, pickerTimeUnitValue]);

  const timeUnitFormController = useMemo(
    () => (
      <Controller
        control={form.control}
        name="timeUnit"
        rules={{
          required: true,
        }}
        render={({ field }) => {
          const selectedOption =
            timeUnitOptions.find(
              ({ value: optionValue }) => optionValue === field.value,
            ) ?? null;

          return (
            <CustomDatePickerSelect
              options={timeUnitOptions}
              isSearchable={false}
              name="advanced-date-time-unit-select"
              isClearable={false}
              isMulti={false}
              mobileLabel={t('common:filters.selectOption')}
              forceCaret
              onChange={(option) => {
                if (option?.value) {
                  form.setValue('timeUnit', option.value);
                }
              }}
              value={selectedOption}
            />
          );
        }}
      />
    ),
    [form, t, timeUnitOptions],
  );

  const timeUnitValueFormController = useMemo(
    () => (
      <Controller
        control={form.control}
        name="timeUnitValue"
        rules={{
          required: true,
          min: 1,
        }}
        render={({ field, fieldState }) => {
          return (
            <StyledTextInput
              {...field}
              data-lgg-id="advanced-date-time-unit-value-input"
              type="number"
              min={1}
              error={Boolean(fieldState.error)}
              label=""
              reserveErrorArea={false}
              onChange={(e) => {
                form.setValue(field.name, Number(e.target.value));
              }}
            />
          );
        }}
      />
    ),
    [form],
  );

  const inputTypeContent = useMemo(() => {
    const { formType, formStartDate } = getParsedFormValues();

    if (!formType) return null;

    return matchInputDateTypeExhaustive(formType, {
      dateRange: () => {
        return (
          <>
            <CustomDatePickerDateFilter
              format={CUSTOM_DATE_PICKER_LABEL_DATE_FORMAT}
              name="startDate"
              data-lgg-id="advanced-date-filter-date-from"
              placeholder={t('common:advancedDateFilter.inputPlaceholder.fromDate')}
              label=""
              updateOnPageChange={false}
              required
            />
            <CustomDatePickerDateFilter
              format={CUSTOM_DATE_PICKER_LABEL_DATE_FORMAT}
              name="endDate"
              data-lgg-id="advanced-date-filter-date-to"
              placeholder={t('common:advancedDateFilter.inputPlaceholder.toDate')}
              label=""
              required
              updateOnPageChange={false}
              disabledDate={(date) =>
                (formStartDate && date.toDate().getTime() < formStartDate?.getTime()) ??
                false
              }
            />
          </>
        );
      },
      inTheLast: () => {
        return (
          <>
            {timeUnitValueFormController}
            {timeUnitFormController}
          </>
        );
      },
      over: () => {
        return (
          <>
            {timeUnitValueFormController}
            {timeUnitFormController}
          </>
        );
      },
      exactDate: () => {
        return (
          <CustomDatePickerDateFilter
            format={CUSTOM_DATE_PICKER_LABEL_DATE_FORMAT}
            name="startDate"
            data-lgg-id="advanced-date-filter-exact-date"
            label=""
            updateOnPageChange={false}
            required
          />
        );
      },
      noDate: () => null,
      lastWeek: () => null,
      lastMonth: () => null,
      lastYear: () => null,
      today: () => null,
      yesterday: () => null,
      thisWeek: () => null,
      thisMonth: () => null,
      thisYear: () => null,
    });
  }, [getParsedFormValues, t, timeUnitValueFormController, timeUnitFormController]);

  const formContent = useMemo(() => {
    const { formType } = getParsedFormValues();
    const selectedValue = options.find((value) => value.value === formType);

    return (
      <FormProvider {...form}>
        <StyledForm onSubmit={form.handleSubmit(onSubmit)}>
          <FormContent data-lgg-id="advance-date-picker-form">
            <BodyContainer>
              <InputContainer>
                <CustomDatePickerSelect
                  name="advanced-date-type-select"
                  isLoading={false}
                  value={selectedValue ?? null}
                  placeholder={t('common:filters.selectOption')}
                  mobileLabel={t('common:filters.selectOption')}
                  isSearchable={false}
                  isClearable={false}
                  forceCaret
                  onChange={(option) => {
                    form.reset();

                    if (option?.value) {
                      form.setValue('type', option.value);

                      if (['DATE_RANGE', 'EXACT_DATE'].includes(option.value)) {
                        form.setValue('startDate', null);
                        form.setValue('endDate', null);
                      }

                      if (option.value === 'DATE_RANGE') {
                        form.setValue('startDate', null);
                        form.setValue('endDate', null);
                      }

                      if (['IN_THE_LAST', 'OVER'].includes(option.value)) {
                        form.setValue('timeUnit', 'DAY');
                        form.setValue('timeUnitValue', 1);
                      }
                    }
                  }}
                  options={options}
                />
                {inputTypeContent}
              </InputContainer>
              {!breakpointUpMd && resultPreview}
            </BodyContainer>
            <CustomDatePickerPopoverFooter
              topContent={breakpointUpMd ? resultPreview : null}
              onClear={() => {
                onChange({
                  type: null,
                });
                setInnerFormValues(innerFormDefaultValues);
                form.clearErrors();
                setPickerType(null);
                closePanel();
              }}
            />
          </FormContent>
        </StyledForm>
      </FormProvider>
    );
  }, [
    getParsedFormValues,
    options,
    form,
    onSubmit,
    t,
    inputTypeContent,
    breakpointUpMd,
    resultPreview,
    onChange,
    closePanel,
  ]);

  const input = useMemo(
    () => (
      <GenericInput
        active={isPanelVisible}
        label={label}
        onClick={showPanel}
        value={inputValue}
        data-lgg-id={`${testId}-input`}
      />
    ),
    [inputValue, isPanelVisible, label, showPanel, testId],
  );

  return breakpointUpMd ? (
    <DropdownContainer data-lgg-id={testId}>
      <Dropdown
        overlayClassName="context-menu"
        getPopupContainer={(triggerNode) => triggerNode}
        visible={isPanelVisible}
        trigger={['click']}
        onVisibleChange={(isVisible) => {
          if (!isVisible) {
            closePanel();
          }
        }}
        placement="bottomRight"
        overlay={formContent}
      >
        {input}
      </Dropdown>
    </DropdownContainer>
  ) : (
    <>
      {input}
      <BottomDrawer
        visible={isPanelVisible}
        title={label}
        onClose={closePanel}
        push={false}
      >
        {formContent}
      </BottomDrawer>
    </>
  );
};

import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useServiceSelector } from 'react-service-locator';
import { useQuery } from '@apollo/client';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Dropdown } from 'antd';
import c from 'classnames';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import {
  Query,
  QueryCalendarEventsArgs,
  Schedule,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { LggSelectableOptionsDropdownButton } from 'src/components/general/button/dropdown-button';
import { FiltersButton } from 'src/components/general/button/filters-button';
import { IconButtonV2 } from 'src/components/general/button/icon-button';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import {
  AddNewContextMenu,
  AddNewOptionIcon,
} from 'src/components/general/display/add-new-context-menu';
import { DrawerOption } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { tableLayoutViewButtonStyles } from 'src/components/general/table-helpers';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { CalendarStyleOverride } from 'src/components/pages/calendar/components/calendar-style-override';
import { CalendarEvent } from 'src/components/pages/calendar/components/desktop/calendar-event';
import { CalendarFilters } from 'src/components/pages/calendar/components/shared/calendar-filters';
import { useCalendarFilters } from 'src/components/pages/calendar/components/shared/hooks';
import {
  TaskEvent,
  CALENDAR_EVENTS_QUERY,
} from 'src/components/pages/calendar/components/shared/shared';
import { useRefreshAppointment } from 'src/hooks/gql/use-refresh-appointment';
import { useRefreshTask } from 'src/hooks/gql/use-refresh-task';
import { UseRefreshProps } from 'src/hooks/gql/use-refresh.shared';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useUrls } from 'src/hooks/use-urls';
import { useVisible } from 'src/hooks/use-visible';
import { CalendarService } from 'src/services/calendar.service';

const ColumnHeaderWeekDay = styled.div`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  letter-spacing: 0.33px;
  line-height: 13px;
  margin: 11px 0;
  text-transform: uppercase;
`;

const ColumnHeaderDate = styled.span`
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  height: 24px;
  padding: 4px;
  width: 24px;

  &.today {
    background: ${({ theme }) => theme.colors.gogo};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const ColumnHeaderContainer = styled(FlexColumn)`
  align-items: center;

  ${ColumnHeaderWeekDay} {
    margin: 0;
  }
`;

const SlotRowLabel = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 10px;
  letter-spacing: 0.3px;
  line-height: 12px;
`;

const CalendarWrapper = styled.div`
  height: 100%;
  width: 100%;

  &.dayGridMonth {
    height: unset;
    padding-bottom: 10px;
  }
`;

const CalendarHeader = styled(FlexRow)`
  justify-content: space-between;
  margin-bottom: 30px;
`;

const CalendarBody = styled.div`
  overflow: hidden;
  height: calc(100% - 68px);
  position: relative;
`;

const StyledIconButton = styled(IconButtonV2)`
  height: 38px;
  margin-right: 5px;
  width: 38px;

  &,
  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.colors.koala};
  }

  svg {
    height: 15px;
    width: 15px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const CalendarHeaderDate = styled(FlexRow)`
  align-items: center;
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 20px;
  line-height: 24px;
  margin-left: 15px;
`;

const TodayButton = styled(ButtonV2)`
  margin-right: 10px;
`;

const MoreEventsLabel = styled.span`
  background: transparent;
  color: ${({ theme }) => theme.colors.steel};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 14px;
`;

const ViewTypeButton = styled(LggSelectableOptionsDropdownButton)`
  ${tableLayoutViewButtonStyles};
  margin-left: 20px;
  width: 102px;
`;

const AddNewContextMenuTitle = styled.p`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  line-height: 17px;
  margin: 10px;
`;

const CalendarCellDate = styled.span`
  cursor: default;
`;

const SlotLaneContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export type FullCalendarViewType = 'dayGridMonth' | 'timeGridWeek' | 'timeGridDay';

export const DesktopCalendarView = () => {
  const calendarRef = useRef<FullCalendar>(null);
  const { t } = useTranslation(['calendar', 'common']);
  const { formatDate } = useFormatDate();
  const viewTypeDropdownVisibilityHandler = useVisible();
  const filtersVisibilityHandler = useVisible();
  const [, setSelectedDay] = useServiceSelector(
    CalendarService,
    (s) => [s.state.selectedDay, s.setSelectedDay] as const,
  );
  const { filters, computeFiltersVariables, hasActiveFilters } = useCalendarFilters();
  const { getFeatureFlag } = useAuthorization();
  const { getLegacyAddTaskUrlWithDate, getLegacyAddScheduleUrlWithDate } = useUrls();
  const { pathname: currentPathname, search: currentSearchParams } = useLocation();
  const addEventVisibilityHandler = useVisible();

  const [view, setView] = useState<FullCalendarViewType>('dayGridMonth');
  const [contextMenuDate, setContextMenuDate] = useState<Date | null>(null);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const {
    addDays,
    addMinutes,
    addMonths,
    addWeeks,
    endOfMonth,
    endOfWeek,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subDays,
    subMonths,
    subWeeks,
    parseISO,
  } = useDateHelpers();

  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [contextMenuOffset, setContextMenuOffset] = useState<[number, number]>([0, 0]);

  const getCalendarDate = useCallback(
    (date: Date) => {
      return parseISO(formatDate(date, 'YYYY-MM-DD[T00:00:00.000]', false));
    },
    [formatDate, parseISO],
  );

  const isMonthView = useMemo(() => view === 'dayGridMonth', [view]);
  const { eventDateStart, eventDateEnd } = useMemo(() => {
    // All cases handled; no fallback
    // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
    return match(view)
      .with('dayGridMonth', () => {
        return {
          eventDateStart: getCalendarDate(subDays(startOfMonth(currentDate), 6)),
          eventDateEnd: getCalendarDate(addDays(endOfMonth(currentDate), 6)),
        };
      })
      .with('timeGridDay', () => {
        return {
          eventDateStart: getCalendarDate(currentDate),
          eventDateEnd: getCalendarDate(currentDate),
        };
      })
      .with('timeGridWeek', () => {
        return {
          eventDateStart: getCalendarDate(startOfWeek(currentDate)),
          eventDateEnd: getCalendarDate(endOfWeek(currentDate)),
        };
      })
      .exhaustive();
  }, [
    addDays,
    currentDate,
    endOfMonth,
    endOfWeek,
    getCalendarDate,
    startOfMonth,
    startOfWeek,
    subDays,
    view,
  ]);

  const changeCalendarDate = useCallback(
    (newDate: Date, newView?: FullCalendarViewType) => {
      setSelectedDay(getCalendarDate(newDate));
      setCurrentDate(newDate);
      calendarRef.current?.getApi().changeView(newView ?? view, newDate);
    },
    [getCalendarDate, setSelectedDay, view],
  );

  const getViewTypeLabel = useCallback(
    (view: FullCalendarViewType) => {
      // All cases handled for fixed values
      // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
      return match(view)
        .with('dayGridMonth', () => t('calendar:views.month'))
        .with('timeGridDay', () => t('calendar:views.day'))
        .with('timeGridWeek', () => t('calendar:views.week'))
        .exhaustive();
    },
    [t],
  );

  const getAddNewContextMenu = useCallback(
    (targetDate?: Date) => {
      if (!targetDate) return null;
      const targetDateString = targetDate.toISOString();

      const calendarAddNewOptions: DrawerOption[] = [];
      const baseUrl = `${currentPathname}${currentSearchParams}#`;

      if (getFeatureFlag('crm')) {
        calendarAddNewOptions.push({
          'data-lgg-id': 'calendar-add-new-task',
          label: t('calendar:addNewMenu.options.task'),
          icon: (
            <AddNewOptionIcon
              backgroundColor="secondaryMint20"
              iconColor="secondaryMintDark"
              icon="task18"
            />
          ),
          to: `${baseUrl}${getLegacyAddTaskUrlWithDate(targetDateString)}`,
        });

        calendarAddNewOptions.push({
          'data-lgg-id': 'calendar-add-new-schedule',
          label: t('calendar:addNewMenu.options.schedule'),
          icon: (
            <AddNewOptionIcon
              backgroundColor="secondaryPeriwinkle20"
              iconColor="secondaryPeriwinkleDark"
              icon="schedule18"
            />
          ),
          to: `${baseUrl}${getLegacyAddScheduleUrlWithDate(targetDateString)}`,
        });
      }

      return (
        <>
          {targetElement &&
            calendarAddNewOptions.length &&
            contextMenuDate?.toISOString() === targetDate.toISOString() && (
              <Dropdown
                overlayClassName="context-menu"
                overlay={
                  <AddNewContextMenu
                    header={
                      <AddNewContextMenuTitle data-lgg-id="calendar-add-new-context-menu">
                        {t('common:actions.addNew')}:
                      </AddNewContextMenuTitle>
                    }
                    groups={[
                      calendarAddNewOptions.map((option) => {
                        const testId = option['data-lgg-id'] ?? '';

                        return {
                          ...option,
                          testId,
                          key: testId,
                          onClick: (e: React.MouseEvent<Element, MouseEvent>) => {
                            option.onClick?.(e);
                            addEventVisibilityHandler.close();
                          },
                        };
                      }),
                    ]}
                  />
                }
                placement="bottomRight"
                align={{
                  offset: contextMenuOffset,
                }}
                onVisibleChange={(visible) => {
                  addEventVisibilityHandler.setVisible(visible);

                  if (!visible) {
                    setTargetElement(null);
                  }
                }}
                trigger={['click']}
                visible={addEventVisibilityHandler.visible}
                children={<span></span>}
              />
            )}
        </>
      );
    },
    [
      currentPathname,
      currentSearchParams,
      getFeatureFlag,
      targetElement,
      contextMenuDate,
      t,
      contextMenuOffset,
      addEventVisibilityHandler,
      getLegacyAddTaskUrlWithDate,
      getLegacyAddScheduleUrlWithDate,
    ],
  );

  const updateCalendarView = useCallback(
    (newView: FullCalendarViewType) => {
      setView(newView);
      changeCalendarDate(currentDate, newView);
    },
    [changeCalendarDate, currentDate],
  );

  const viewOptions = [
    {
      label: getViewTypeLabel('dayGridMonth'),
      'data-lgg-id': 'calendar-page-view-option-dayGridMonth',
      value: 'dayGridMonth',
      onClick: () => {
        updateCalendarView('dayGridMonth');
      },
    },
    {
      label: getViewTypeLabel('timeGridWeek'),
      'data-lgg-id': 'calendar-page-view-option-timeGridWeek',
      value: 'timeGridWeek',
      onClick: () => {
        updateCalendarView('timeGridWeek');
      },
    },
    {
      label: getViewTypeLabel('timeGridDay'),
      'data-lgg-id': 'calendar-page-view-option-timeGridDay',
      value: 'timeGridDay',
      onClick: () => {
        updateCalendarView('timeGridDay');
      },
    },
  ];

  const { data, refetch } = useQuery<
    Pick<Query, 'calendarEvents'>,
    Partial<QueryCalendarEventsArgs>
  >(CALENDAR_EVENTS_QUERY, {
    variables: computeFiltersVariables({
      end: eventDateEnd,
      start: eventDateStart,
      filters,
    }),
  });

  const onRefreshHandler = useMemo<UseRefreshProps>(() => {
    return {
      onRefresh: async () => {
        await refetch();
      },
    };
  }, [refetch]);

  useRefreshTask(onRefreshHandler);
  useRefreshAppointment(onRefreshHandler);

  const eventList = useMemo(() => {
    return (data?.calendarEvents.nodes ?? []).map((event) => {
      // Keep in mind that CalendarEventsUnion may change
      // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
      return match(event)
        .with(
          {
            __typename: 'Schedule',
          },
          (appointment) => {
            const startDate = new Date(appointment.startAt);

            return {
              date: startDate,
              title: appointment.title,
              start: startDate,
              end: appointment.endAt ? new Date(appointment.endAt) : undefined,
              allDay: appointment.isAllDay || !appointment.endAt,
              extendedProps: {
                event: appointment,
              },
            };
          },
        )
        .with({ __typename: 'Task' }, (task) => {
          const dueAt = new Date(task.dueAt);

          return {
            date: dueAt,
            title: task.title,
            end: addMinutes(dueAt, 15),
            start: dueAt,
            allDay: false,
            extendedProps: {
              event: task,
            },
          };
        })
        .exhaustive();
    });
  }, [addMinutes, data?.calendarEvents.nodes]);

  return (
    <CalendarWrapper
      data-lgg-id="calendar-page-container"
      className={c({ [view]: true })}
    >
      <CalendarStyleOverride />
      <CalendarHeader>
        <FlexRow>
          <TodayButton
            icon="today"
            data-lgg-id="calendar-today-button"
            size="regular"
            children={t('calendar:today')}
            onClick={() => {
              changeCalendarDate(new Date());
            }}
            variant="defaultWhite"
          />
          <StyledIconButton
            icon="arrowBackNoPadding"
            data-lgg-id="calendar-prev-button"
            size="small"
            variant="default"
            onClick={() => {
              // All cases handled for fixed values
              // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
              const newDate = match(view)
                .with('dayGridMonth', () => startOfMonth(subMonths(currentDate, 1)))
                .with('timeGridDay', () => startOfDay(subDays(currentDate, 1)))
                .with('timeGridWeek', () => startOfWeek(subWeeks(currentDate, 1)))
                .exhaustive();

              changeCalendarDate(newDate);
            }}
          />
          <StyledIconButton
            icon="arrowNextNoPadding"
            data-lgg-id="calendar-next-button"
            size="small"
            variant="default"
            onClick={() => {
              // All cases handled for fixed values
              // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
              const newDate = match(view)
                .with('dayGridMonth', () => startOfMonth(addMonths(currentDate, 1)))
                .with('timeGridDay', () => startOfDay(addDays(currentDate, 1)))
                .with('timeGridWeek', () => startOfWeek(addWeeks(currentDate, 1)))
                .exhaustive();

              changeCalendarDate(newDate);
            }}
          />
          <CalendarHeaderDate data-lgg-id="calendar-header-title">
            {formatDate(currentDate, 'MMMM, YYYY', false)}
          </CalendarHeaderDate>
        </FlexRow>
        <FlexRow>
          <FiltersButton
            data-lgg-id="calendar-filters-button"
            filtersVisibilityHandler={filtersVisibilityHandler}
            hasActiveFilters={hasActiveFilters}
          />
          <ViewTypeButton
            visibilityHandler={viewTypeDropdownVisibilityHandler}
            data-lgg-id="calendar-page-view-button"
            selectedValue={view}
            customDropdownProps={{
              overlayStyle: {
                minWidth: '141px',
              },
            }}
            options={viewOptions}
            variant="defaultWhite"
            size="regular"
          >
            {getViewTypeLabel(view)}
          </ViewTypeButton>
        </FlexRow>
      </CalendarHeader>
      <CalendarBody data-lgg-id={`calendar-view-${view}`} id="calendar-container">
        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
          initialView={view}
          contentHeight={isMonthView ? '1026px' : undefined}
          now={new Date()}
          headerToolbar={false}
          selectable={false}
          allDayContent={<SlotRowLabel>{t('calendar:allDay')}</SlotRowLabel>}
          slotLabelContent={({ date }) => (
            <SlotRowLabel>{formatDate(date, 'h A', false)}</SlotRowLabel>
          )}
          eventMinHeight={1}
          dayCellContent={({ dayNumberText, date }) => {
            return (
              <CalendarCellDate
                data-lgg-id="calendar-day-cell"
                data-date={formatDate(date, 'DD-MM-YYYY', false)}
              >
                {dayNumberText}
                {isMonthView && getAddNewContextMenu(date)}
              </CalendarCellDate>
            );
          }}
          slotLaneContent={({ date }) => {
            const contextMenuDateWithTime = new Date(
              (contextMenuDate ?? date ?? new Date()).getTime(),
            );

            // We need to do this since this props just returns the time. ex: Thu Jan 01 1970 23:30:00 GMT-0430 (Atlantic Daylight Time)
            // We grab the time and set it to the clicked date to get the actual date + time that this slot represents.
            contextMenuDateWithTime.setHours(date?.getHours() ?? 0);
            contextMenuDateWithTime.setMinutes(date?.getMinutes() ?? 0);

            return (
              <SlotLaneContainer
                data-lgg-id="calendar-slot-lane"
                data-time={formatDate(
                  contextMenuDateWithTime ?? new Date(),
                  'H:mm:ss',
                  false,
                )}
              >
                {getAddNewContextMenu(contextMenuDateWithTime)}
              </SlotLaneContainer>
            );
          }}
          dateClick={({ date, dayEl, jsEvent, allDay }) => {
            if (!isMonthView && allDay) return;

            addEventVisibilityHandler.show();
            setContextMenuDate(date);

            if (dayEl.firstElementChild) {
              setTargetElement(dayEl.firstElementChild as HTMLElement);
            }

            if (isMonthView) {
              setContextMenuOffset([jsEvent.offsetX - 80, jsEvent.offsetY]);
            } else {
              setContextMenuOffset([jsEvent.offsetX + 130, 30]);
            }
          }}
          dayPopoverFormat={({ date }) =>
            formatDate(new Date(date.year, date.month, date.day), 'dddd D', false)
          }
          moreLinkContent={({ num }) => {
            return (
              <MoreEventsLabel data-lgg-id="calendar-more-events-popover-trigger">
                {t('calendar:moreEvents', { count: num })}
              </MoreEventsLabel>
            );
          }}
          dayHeaderContent={({ text, date, isToday }) => {
            if (isMonthView) {
              return <ColumnHeaderWeekDay>{text}</ColumnHeaderWeekDay>;
            } else {
              return (
                <ColumnHeaderContainer>
                  <ColumnHeaderWeekDay>
                    {formatDate(date, 'ddd', false)}
                  </ColumnHeaderWeekDay>
                  <ColumnHeaderDate className={c({ today: isToday })}>
                    {formatDate(date, 'D', false)}
                  </ColumnHeaderDate>
                </ColumnHeaderContainer>
              );
            }
          }}
          eventContent={({ event, view }) => {
            const eventData = event.extendedProps.event as Schedule | TaskEvent;

            return (
              <CalendarEvent event={eventData} view={view.type as FullCalendarViewType} />
            );
          }}
          dayMaxEvents={isMonthView ? 4 : false}
          themeSystem="custom"
          events={eventList}
        />
      </CalendarBody>
      <CalendarFilters
        visible={filtersVisibilityHandler.visible}
        onClose={filtersVisibilityHandler.close}
        filters={filters ?? { eventType: [], owner: [] }}
      />
    </CalendarWrapper>
  );
};

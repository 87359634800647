import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import { BroadcastStatus } from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useFormatDate } from 'src/hooks/use-format-date';

const BroadcastSummaryRow = styled(FlexRow)`
  background: ${({ theme }) => theme.colors.porcelain};
  border-radius: 6px;
  height: 72px;
  padding: 10px 20px;
  width: 100%;
`;

const BroadcastSummaryContainer = styled(FlexColumn)`
  ${BroadcastSummaryRow} + ${BroadcastSummaryRow} {
    margin-top: 20px;
  }
`;

const BroadcastSummaryItemContainer = styled(FlexRow)`
  align-items: center;
  flex: 1;
`;

const BroadcastSummaryItemIcon = styled(Icon)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  box-shadow: 0px 12px 20px 0px rgba(138, 199, 234, 0.149);
  height: 38px;
  margin-right: 15px;
  padding: 11px;
  width: 38px;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

const BroadcastSummaryItemTitle = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 16px;
  line-height: 20px;
`;

const BroadcastSummaryItemBoldTitle = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 2px;
`;

const BroadcastSummaryItemValue = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 15px;
  text-align: left;
`;

const BroadcastSummaryItemDivider = styled.div`
  height: 52px;
  width: 1px;
  background: ${({ theme }) => theme.colors.flint};
  opacity: 0.5;
  margin: 0 20px;
`;

type BroadcastSummaryItemProps = {
  icon: string;
  value: string | React.ReactElement;
  title: string;
  name: string;
};

const BroadcastSummaryItem = ({
  icon,
  title,
  value,
  name,
}: BroadcastSummaryItemProps) => {
  return (
    <BroadcastSummaryItemContainer data-lgg-id={`overview-summary-item-${name}`}>
      <BroadcastSummaryItemIcon type={icon} />
      <FlexColumn>
        <BroadcastSummaryItemTitle data-lgg-id="title">{value}</BroadcastSummaryItemTitle>
        <BroadcastSummaryItemValue data-lgg-id="subtitle">
          {title}
        </BroadcastSummaryItemValue>
      </FlexColumn>
    </BroadcastSummaryItemContainer>
  );
};

export type BroadcastSummaryProps = {
  uniqueContacts?: number | null;
  sender?: string | null;
  messages: number | null;
  date: Date | null;
  status: BroadcastStatus;
  variant: 'BROADCAST-WIZARD' | 'BROADCAST-DETAILS';
};

export const BroadcastSummary = ({
  uniqueContacts,
  sender,
  messages,
  date,
  status,
  variant,
}: BroadcastSummaryProps) => {
  const { t } = useTranslation(['broadcast', 'common']);
  const { formatEventDate, formatSimpleTime } = useFormatDate();
  const isBroadcastWizard = useMemo(() => variant === 'BROADCAST-WIZARD', [variant]);

  const formatScheduleAtDate = useCallback(
    (date: Date) => {
      return `${formatEventDate(date)} ${t(
        'common:dateTimeCombinators.at',
      )} ${formatSimpleTime(date)}`;
    },
    [formatEventDate, formatSimpleTime, t],
  );

  const scheduledDateLabel: string = useMemo(
    () => (date ? formatScheduleAtDate(date) : t('common:now')),
    [date, formatScheduleAtDate, t],
  );

  const dateLabel = useMemo(
    () =>
      isBroadcastWizard
        ? match(status)
            .with(P.union('SCHEDULED'), () =>
              t(
                'broadcast:pages.broadcastWizard.steps.overview.summary.items.completionDate',
              ),
            )
            .otherwise(() =>
              t('broadcast:pages.broadcastWizard.steps.overview.summary.items.startDate'),
            )
        : t('broadcast:pages.broadcastWizard.steps.overview.summary.items.startDate'),
    [isBroadcastWizard, status, t],
  );

  const messagesLabel = useMemo(
    () =>
      isBroadcastWizard
        ? t('broadcast:pages.broadcastWizard.steps.overview.summary.items.messages')
        : t('broadcast:pages.broadcastWizard.steps.overview.summary.items.messagesSent'),
    [t, isBroadcastWizard],
  );

  return (
    <BroadcastSummaryContainer>
      <BroadcastSummaryRow>
        <BroadcastSummaryItem
          name="unique-contacts"
          icon="contactSetting"
          value={uniqueContacts ? uniqueContacts.toLocaleString() : '—'}
          title={t(
            'broadcast:pages.broadcastWizard.steps.overview.summary.items.uniqueContacts',
          )}
        />
        <BroadcastSummaryItemDivider />
        <BroadcastSummaryItem
          name="messages"
          icon="sms"
          value={messages ? messages.toLocaleString() : '—'}
          title={messagesLabel}
        />
      </BroadcastSummaryRow>
      <BroadcastSummaryRow>
        <BroadcastSummaryItem
          name="sender"
          icon="phoneNumber"
          title={sender ?? '—'}
          value={
            <BroadcastSummaryItemBoldTitle>
              {t('broadcast:pages.broadcastWizard.steps.overview.summary.items.sender')}:
            </BroadcastSummaryItemBoldTitle>
          }
        />
        <BroadcastSummaryItemDivider />
        <BroadcastSummaryItem
          name="date"
          icon="calendarNoPadding"
          title={scheduledDateLabel}
          value={
            <BroadcastSummaryItemBoldTitle>{dateLabel}:</BroadcastSummaryItemBoldTitle>
          }
        />
      </BroadcastSummaryRow>
    </BroadcastSummaryContainer>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import c from 'classnames';
import { $getSelection } from 'lexical';
import { Contact } from '@lgg/isomorphic/types/__generated__/graphql';
import { LggOptionsDropdownButtonWithCustomTrigger } from 'src/components/general/button/dropdown-button';
import { PluginTriggerIcon } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/shared';
import { useVisible } from 'src/hooks/use-visible';

const previewContact: Pick<Contact, 'firstName' | 'lastName' | 'fullName' | 'interest'> =
  {
    firstName: 'Pedro',
    lastName: 'Ortiz',
    fullName: 'Pedro Ortiz',
    interest: 'Honda Civic 2025',
  };

enum ContactPlaceholderKeys {
  firstName = '{contact.first_name}',
  lastName = '{contact.last_name}',
  fullName = '{contact.full_name}',
  interest = '{contact.interest}',
}

const placeholderKeyValueMap = {
  [ContactPlaceholderKeys.firstName]: previewContact.firstName,
  [ContactPlaceholderKeys.lastName]: previewContact.lastName,
  [ContactPlaceholderKeys.fullName]: previewContact.fullName,
  [ContactPlaceholderKeys.interest]: previewContact.interest,
};

export const resolvePreviewWithContactPlaceholders = (message: string): string => {
  if (!message) return '';

  let previewMessage = message;

  Object.entries(placeholderKeyValueMap).forEach(([key, value]) => {
    previewMessage = previewMessage.replaceAll(key, value ?? '');
  });

  return previewMessage;
};

export const InsertContactPlaceholderPlugin = () => {
  const { t } = useTranslation(['common']);
  const [editor] = useLexicalComposerContext();
  const insertContactPlaceholderVisibilityHandler = useVisible();

  const insertPlaceholder = (placeholder: ContactPlaceholderKeys) => {
    editor.focus();

    editor.update(() => {
      const selection = $getSelection();

      if (selection) {
        selection.insertText(placeholder);
      }
    });
  };

  return (
    <LggOptionsDropdownButtonWithCustomTrigger
      customTrigger={
        <PluginTriggerIcon
          type="placeholder"
          lggTestId="insert-contact-placeholder-plugin-trigger"
          className={c({ active: insertContactPlaceholderVisibilityHandler.visible })}
          onClick={() => {
            insertContactPlaceholderVisibilityHandler.setVisible(
              !insertContactPlaceholderVisibilityHandler.visible,
            );
          }}
        />
      }
      customDropdownProps={{
        placement: 'topLeft',
        overlayStyle: {
          width: '209px',
          height: '147px',
        },
      }}
      options={[
        {
          label: t('common:firstName'),
          onClick: () => insertPlaceholder(ContactPlaceholderKeys.firstName),
          'data-lgg-id': 'placeholder-option-first-name',
        },
        {
          label: t('common:lastName'),
          onClick: () => insertPlaceholder(ContactPlaceholderKeys.lastName),
          'data-lgg-id': 'placeholder-option-last-name',
        },
        {
          label: t('common:fullName'),
          onClick: () => insertPlaceholder(ContactPlaceholderKeys.fullName),
          'data-lgg-id': 'placeholder-option-full-name',
        },
        {
          label: t('common:interest'),
          onClick: () => insertPlaceholder(ContactPlaceholderKeys.interest),
          'data-lgg-id': 'placeholder-option-interest',
        },
      ]}
      visibilityHandler={insertContactPlaceholderVisibilityHandler}
    />
  );
};

import { match } from 'ts-pattern';
import { Schedule } from '@lgg/isomorphic/types/__generated__/graphql';

export const getAppointmentStatusTransKey = (status: Schedule['status']) => {
  // Keep in mind that ScheduleStatus may change
  // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
  return match(status)
    .with('CANCELLED', () => 'appointments:filters.status.options.cancelled' as const)
    .with('COMPLETED', () => 'appointments:filters.status.options.completed' as const)
    .with('RESCHEDULED', () => 'appointments:filters.status.options.rescheduled' as const)
    .with('SCHEDULED', () => 'appointments:filters.status.options.scheduled' as const)
    .with('NO_SHOW', () => 'appointments:filters.status.options.noShow' as const)
    .exhaustive();
};

import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import {
  Contact,
  ContactRelatedNoteUnion,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  ItemContainer,
  ItemCreationDate,
  ItemDescription,
  ItemIcon,
  ItemMoreOptions,
  ItemMoreOptionsOption,
} from 'src/components/domain/contacts/contact-modal/tabs/contact-modal-item.shared';
import { useShowConfirmationModal } from 'src/components/general/feedback/hooks/use-show-confirmation-modal';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { LexicalViewer } from 'src/components/pages/conversations/components/general/lexical-editor/lexical-viewer';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useUrls } from 'src/hooks/use-urls';

const ContentColumn = styled(FlexColumn)`
  align-items: flex-start;
  flex: 1;
`;

const NoteDescription = styled(ItemDescription)`
  margin-top: 6px;
`;

const NoteCreationDate = styled(ItemCreationDate)`
  margin-bottom: 0;

  ${up('md')} {
    margin-bottom: 0;
  }
`;

const NoteTypeLabel = styled.span`
  color: ${({ theme }) => theme.colors.secondaryGoldDark};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 14px;
  margin-right: 5px;
`;

const OriginatorLabel = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 14px;
`;

type NoteItemProps = {
  note: ContactRelatedNoteUnion;
  contactId: Contact['id'];
  onNoteDelete: (params: { noteId: number; contactId: number }) => void;
};

export const NoteItem = memo<NoteItemProps>(({ note, onNoteDelete, contactId }) => {
  const { formatLogDate } = useFormatDate();
  const { getLegacyContactEditNoteUrl, getContactModalUrl } = useUrls();
  const [isHovered, setHovered] = useState(false);
  const showConfirmationModal = useShowConfirmationModal();
  const { t } = useTranslation(['notes', 'common', 'contacts']);
  const history = useHistory();

  const handleEdit = useCallback(() => {
    openRemoteModalWrapper(getLegacyContactEditNoteUrl(contactId, note.id));
  }, [contactId, getLegacyContactEditNoteUrl, note.id]);

  const { getFeatureFlag } = useAuthorization();
  const isMentionsFeatureEnabled = getFeatureFlag(
    'temp_lggdev-933_agregar-feature-flag-para-los-mention-en-internal-notes-y-los-attachments',
  );

  const handleDelete = useCallback(() => {
    showConfirmationModal({
      title: t('notes:deleteModal.title'),
      message: t('notes:deleteModal.body'),
      confirmButtonText: t('notes:deleteModal.okButton'),
      testId: 'notifications-delete-note-confirmation-modal',
      onConfirm: async () => {
        await onNoteDelete({ noteId: Number(note.id), contactId });
      },
    });
  }, [showConfirmationModal, t, onNoteDelete, note.id, contactId]);

  const { label, originator, noteOptions, noteContent } = useMemo(() => {
    // Keep in mind that ContactRelatedNoteUnion may change
    // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
    return match(note)
      .with(
        {
          __typename: 'ContactNote',
        },
        ({ text, user }) => ({
          label: t('contacts:detailsModal.tabs.notes.items.type.contactNote'),
          originator: user?.fullName,
          noteOptions: [
            {
              type: 'edit',
              icon: 'edit',
              'data-lgg-id': 'note-item-edit-option',
              label: t('common:edit'),
              onClick: handleEdit,
            } as ItemMoreOptionsOption,
            {
              type: 'delete',
              icon: 'deleteBin',
              'data-lgg-id': 'note-item-delete-option',
              label: t('common:delete'),
              onClick: handleDelete,
            } as ItemMoreOptionsOption,
          ],
          noteContent: text,
        }),
      )
      .with(
        {
          __typename: 'ConversationNote',
        },
        ({ createdBy, rawNote, text, conversation, id }) => ({
          label: t('contacts:detailsModal.tabs.notes.items.type.conversationNote'),
          originator: createdBy.fullName,
          noteOptions: [
            {
              type: 'link',
              icon: 'externalLink',
              'data-lgg-id': 'view-note-option',
              label: t('contacts:detailsModal.tabs.notes.items.viewNoteInConversations'),
              onClick: () => {
                history.replace(
                  getContactModalUrl(contactId, {
                    contactInteractionId: `note-${id}`,
                    conversationId: conversation.id,
                  }),
                );
              },
            } as ItemMoreOptionsOption,
          ],
          noteContent:
            isMentionsFeatureEnabled && rawNote ? (
              <LexicalViewer jsonString={rawNote} />
            ) : (
              text
            ),
        }),
      )
      .exhaustive();
  }, [
    contactId,
    getContactModalUrl,
    handleDelete,
    handleEdit,
    history,
    isMentionsFeatureEnabled,
    note,
    t,
  ]);

  return (
    <ItemContainer
      data-lgg-id="contact-modal-note-item"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <FlexRow>
        <ItemIcon type="note" />
        <ContentColumn>
          <FlexRow>
            <NoteTypeLabel data-lgg-id="note-type-label">{label}</NoteTypeLabel>
            {originator ? (
              <OriginatorLabel data-lgg-id="note-originator">
                {t('contacts:detailsModal.tabs.notes.items.byOriginator', {
                  originator,
                })}
              </OriginatorLabel>
            ) : null}
          </FlexRow>
          <NoteDescription data-lgg-id="note-description">{noteContent}</NoteDescription>
          <NoteCreationDate data-lgg-id="creation-date">
            {formatLogDate(note.createdAt)}
          </NoteCreationDate>
        </ContentColumn>
        <ItemMoreOptions
          itemId={note.id}
          title={
            note.__typename === 'ContactNote'
              ? t('notes:noteOptions.title.contactNote')
              : t('notes:noteOptions.title.internalNote')
          }
          options={noteOptions}
          isHovered={isHovered}
        />
      </FlexRow>
    </ItemContainer>
  );
});

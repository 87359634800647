import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import {
  ContactInteractionAttachmentContacts,
  ContactInteractionDirection,
  ContactInteractionAttachmentContact,
  LeadHistoryLogStatus,
  ContactInteractionAttachmentContactsAddress,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
import { Center } from 'src/components/layout/center';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  AttachmentDateAndStatus,
  BubbleTypeAttachmentContainer,
} from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/shared';
import { RepliedMessageRenderer } from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import { ContactInteractionsWithAttachmentUnion } from 'src/components/pages/conversations/components/contact-interactions/items/text-item';
import { CustomTitleBottomDrawer } from 'src/components/pages/conversations/components/drawer/bottom-drawer';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';

type ContactsAttachmentProps = {
  attachment: ContactInteractionAttachmentContacts;
  attachmentDate: string;
  direction: ContactInteractionDirection;
  onDetailsVisibilityChange: (isVisible: boolean) => void;
  showDeliveryStatus: boolean;
  contactInteraction: ContactInteractionsWithAttachmentUnion;
};

const ContactCard = styled(FlexRow)`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  cursor: default;
  height: 50px;
  padding: 10px 15px;
  width: 230px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:last-child {
    margin-bottom: 5px;
  }

  ${up('md')} {
    background: ${({ theme }) => theme.colors.porcelain};
  }
`;

export const ContactAvatar = styled(Icon)`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.casper};
  display: flex;
  height: 30px;
  justify-content: center;
  margin-right: 10px;
  min-width: 30px;
  width: 30px;

  svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const ContactLabel = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  letter-spacing: -0.36px;
  line-height: 15px;
  margin: 0;
  max-width: 140px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ContactDetailsArrow = styled(Icon)`
  align-items: center;
  display: flex;
  height: 12px;
  transform: rotate(90deg);
  transition: transform 0.1s linear;
  width: 12px;

  svg {
    height: 12px;
    width: 12px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }

  &.active {
    transform: rotate(0deg);
  }
`;

const ContactInfoContainer = styled(FlexRow)`
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
`;

const ContactDetailsPopoverContent = styled(FlexColumn)`
  min-width: 330px;
`;

const ContactDetailsHeader = styled(FlexRow)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
  padding: 10px 15px;

  ${ContactLabel} {
    color: ${({ theme }) => theme.colors.carbon};
    font-size: 14px;
    line-height: 17px;
    max-width: 250px;
  }
`;

const ContactDetailItem = styled.div`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  line-height: 15px;
  padding: 8px 10px 7px;
  white-space: pre-wrap;

  .title {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.medium};
    margin-right: 2px;
    padding: 0;
    text-transform: capitalize;
  }

  ${up('md')} {
    font-size: 12px;
    line-height: 15px;
    padding: 0;
  }
`;

const ContactCardDetailsBody = styled(FlexColumn)`
  padding: 10px 15px 20px;

  ${up('md')} {
    padding: 15px;
  }

  ${ContactDetailItem} + ${ContactDetailItem} {
    margin-top: 8px;
  }
`;

const MobileDrawerCloseIcon = styled(Icon)`
  svg {
    height: 20px;
    width: 20px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const MobileDrawerTitle = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${ContactAvatar} {
    height: 40px;
    width: 40px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  ${ContactLabel} {
    color: ${({ theme }) => theme.colors.carbon};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 18px;
    letter-spacing: -0.36px;
    line-height: 22px;
  }
`;

const ContactLabelContainer = styled(Center)`
  justify-content: flex-start;
  width: 100%;
`;

type ContactDetailType = 'phone' | 'email' | 'address' | 'url' | 'org' | 'birthday';

type ContactDetailProps = {
  type: ContactDetailType;
  value: string;
};

const ContactDetail = ({ type, value }: ContactDetailProps) => {
  const { t } = useTranslation(['common']);
  // All cases handled for fixed values
  // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
  const detailTitle = match(type)
    .with('phone', () => t('common:phone'))
    .with('email', () => t('common:email'))
    .with('address', () => t('common:address'))
    .with('url', () => t('common:url'))
    .with('org', () => t('common:organization'))
    .with('birthday', () => t('common:birthday'))
    .exhaustive();

  return (
    <ContactDetailItem data-lgg-id={`contact-card-detail-${type}`}>
      <span className="title">{`${detailTitle}: `}</span>
      {value}
    </ContactDetailItem>
  );
};

export const ContactCardDetails = ({
  contact,
}: {
  contact: ContactInteractionAttachmentContact;
}) => {
  const { addresses, emails, phones, org, urls, birthday } = contact;

  const formatAddress = (targetAddress: ContactInteractionAttachmentContactsAddress) => {
    const {
      street = null,
      city = null,
      state = null,
      zip = null,
      country = null,
    } = targetAddress;

    const mergeAddressElements = (values: (string | null)[], separator: string) => {
      const result = values.filter((value) => Boolean(value));

      return result.length ? [result.join(separator)] : [];
    };

    const addressComponents = [
      ...(street ? [street] : []),
      ...mergeAddressElements([city, state], ' '),
      ...mergeAddressElements([zip, country], ', '),
    ];

    return addressComponents.join(', ');
  };

  const contactDetails = useMemo(() => {
    return [
      ...(birthday
        ? [
            {
              type: 'birthday' as ContactDetailType,
              value: birthday,
            },
          ]
        : []),
      ...(org?.company
        ? [
            {
              type: 'org' as ContactDetailType,
              value: org.company,
            },
          ]
        : []),
      ...(phones || []).map(({ phone }) => ({
        type: 'phone' as ContactDetailType,
        value: phone,
      })),
      ...(emails || []).map(({ email }) => ({
        type: 'email' as ContactDetailType,
        value: email,
      })),
      ...(addresses || []).map((address) => ({
        type: 'address' as ContactDetailType,
        value: formatAddress(address),
      })),
      ...(urls || []).map(({ url }) => ({
        type: 'url' as ContactDetailType,
        value: url,
      })),
    ];
  }, [addresses, birthday, emails, org?.company, phones, urls]);

  return (
    <ContactCardDetailsBody data-lgg-id="contact-card-details">
      {contactDetails.map(({ type, value }) => (
        <ContactDetail
          type={type}
          value={value}
          key={`contact-card-detail-${type}-${value.replace(/[^\w]/g, '')}`}
        />
      ))}
    </ContactCardDetailsBody>
  );
};

type ContactAttachmentProps = Omit<ContactsAttachmentProps, 'attachment'> & {
  contact: ContactInteractionAttachmentContact;
  status: LeadHistoryLogStatus;
};

const ContactAttachmentItem = memo<
  Omit<
    ContactAttachmentProps,
    'contactInteraction' | 'attachmentDate' | 'showDeliveryStatus' | 'status'
  >
>(({ contact, direction, onDetailsVisibilityChange }) => {
  const contactDetailsVisibilityHandler = useVisible();
  const breakpointUpMd = useBreakpoint(up('md'));

  const { name } = contact;

  const contactLabel = useMemo(
    () => (
      <ContactLabelContainer>
        <ContactAvatar type="contact" />
        <ContactLabel data-lgg-id="contact-card-label">{name.formattedName}</ContactLabel>
      </ContactLabelContainer>
    ),
    [name.formattedName],
  );

  const contactDetailsArrow = useMemo(
    () => (
      <ContactDetailsArrow
        type="arrowup"
        lggTestId="contact-card-details-popover-trigger"
        className={c({
          active: contactDetailsVisibilityHandler.visible,
        })}
      />
    ),
    [contactDetailsVisibilityHandler.visible],
  );

  const contactCardContent = useMemo(
    () => (
      <ContactInfoContainer
        onClick={() => {
          if (!breakpointUpMd) {
            contactDetailsVisibilityHandler.show();
          }
        }}
      >
        {contactLabel}
        {contactDetailsArrow}
      </ContactInfoContainer>
    ),
    [breakpointUpMd, contactDetailsArrow, contactDetailsVisibilityHandler, contactLabel],
  );

  const contactCardDetailsBody = useMemo(
    () => <ContactCardDetails contact={contact} />,
    [contact],
  );

  return (
    <>
      <ContactCard data-lgg-id="contact-card" data-title={name.formattedName}>
        {breakpointUpMd ? (
          <Dropdown
            getPopupContainer={(element) => element.parentElement ?? document.body}
            onVisibleChange={(visible) => {
              contactDetailsVisibilityHandler.setVisible(visible);

              onDetailsVisibilityChange(visible);
            }}
            overlayClassName="contact-interaction-details-dropdown"
            overlay={
              <ContactDetailsPopoverContent data-lgg-id="contact-card-details-popover">
                <ContactDetailsHeader>{contactLabel}</ContactDetailsHeader>
                {contactCardDetailsBody}
              </ContactDetailsPopoverContent>
            }
            placement={direction === 'INBOUND' ? 'bottomLeft' : 'bottomRight'}
            trigger={['click']}
            align={{
              offset: [170, 0],
            }}
          >
            {contactCardContent}
          </Dropdown>
        ) : (
          contactCardContent
        )}
      </ContactCard>
      {!breakpointUpMd && (
        <CustomTitleBottomDrawer
          contentPadding={0}
          destroyOnClose
          visible={contactDetailsVisibilityHandler.visible}
          onClose={contactDetailsVisibilityHandler.close}
          closable
          title={
            <MobileDrawerTitle>
              {contactLabel}
              <MobileDrawerCloseIcon
                type="close"
                onClick={contactDetailsVisibilityHandler.close}
              />
            </MobileDrawerTitle>
          }
        >
          {contactCardDetailsBody}
        </CustomTitleBottomDrawer>
      )}
    </>
  );
});

export const ContactsAttachmentItem = memo<ContactsAttachmentProps>(
  ({
    attachment,
    attachmentDate,
    direction,
    onDetailsVisibilityChange,
    showDeliveryStatus,
    contactInteraction,
  }) => {
    const { status, contacts } = attachment;
    const baseProps = {
      attachmentDate,
      direction,
      onDetailsVisibilityChange,
      showDeliveryStatus,
      status,
    };

    return (
      <BubbleTypeAttachmentContainer direction={direction}>
        <RepliedMessageRenderer contactInteraction={contactInteraction} />
        <FlexColumn>
          {contacts.map((contact, index) => (
            <ContactAttachmentItem
              {...baseProps}
              contact={contact}
              key={`contact-attachment-item-${index}`}
            />
          ))}
        </FlexColumn>
        <AttachmentDateAndStatus
          showStatus={showDeliveryStatus}
          attachmentStatus={status}
          attachmentDate={attachmentDate}
        />
      </BubbleTypeAttachmentContainer>
    );
  },
);
